import { MedicineUnit } from '../definition'

export enum TypeKey {
    SET_UNIT_LIST = 'SET_UNIT_LIST'
}

export interface SetUnitListAction {
    type: TypeKey.SET_UNIT_LIST
    result: Array<MedicineUnit>
}

export type UnitActionTypes = SetUnitListAction

export const setUnitList = (result: Array<MedicineUnit>): SetUnitListAction => ({
    type: TypeKey.SET_UNIT_LIST,
    result
})

export const UnitActions = {
    setUnitList
}