/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { FormType, IntakeRecommendationType, FormValuesState, AppState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage } from 'react-intl'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import { makeStyles, Theme, Button, Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@material-ui/core'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useLocation } from 'react-router-dom'

const useStyles =  makeStyles((theme: Theme) => ({ 
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    radioIcon: {
        color: theme.colors.purpleLight5,
        '&.Mui-checked': {
            color: theme.colors.purple 
        }
    }
}))

const intakeRecommendationForm: Form = {
    formType: FormType.INTAKE_RECOMMENDATION_FORM,
    title: () => <FormattedMessage id='create.intakeInfo.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)

        let defaultIntakeRecommendation = IntakeRecommendationType.BEFORE_MEAL
        if (formValuesState && !!formValuesState.intakeRecommendation) {
            defaultIntakeRecommendation = formValuesState.intakeRecommendation
        }
        const [intakeRecommendation, setIntakeRecommendation] = useState(defaultIntakeRecommendation)
        
        const handleIntakeRecommendationSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIntakeRecommendation(event.target.value as IntakeRecommendationType)
        }  

        const handleForwardAction = () => { 
            if (formValuesState && (!formValuesState.intakeRecommendation || formValuesState.intakeRecommendation !== intakeRecommendation)) {
                dispatch(FormValuesActions.setIntakeRecommendation(intakeRecommendation)) 
            }
            
            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        const handleSkipAction = () => {
            dispatch(FormValuesActions.setIntakeRecommendation(IntakeRecommendationType.NONE)) 
            
            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <FormControl 
                        component="fieldset"
                        style={{ 
                            boxShadow: '0px 5px 22px -1px rgba(96, 167, 195, 0.52)'
                        }}
                    >
                        <Box style={{ padding: '5px 15px' }}>
                            <FormLabel 
                                component="h6"
                                style={{ margin: '10px 0px' }}
                            >
                                <FormattedMessage id={`create.intakeInfo.label.intakeOptions.title`} /> 
                            </FormLabel>
                            <RadioGroup
                                name="intake-recommendatio"
                                value={intakeRecommendation}
                                onChange={handleIntakeRecommendationSelect}
                            >
                                <FormControlLabel
                                    value={IntakeRecommendationType.BEFORE_MEAL} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    }
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.intakeInfo.label.intakeOptions.preMeal' />
                                        </Typography>
                                    } 
                                />
                                <FormControlLabel 
                                    value={IntakeRecommendationType.DURING_MEAL} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    } 
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.intakeInfo.label.intakeOptions.meal' />
                                        </Typography>                                    
                                    } 
                                />
                                <FormControlLabel 
                                    value={IntakeRecommendationType.AFTER_MEAL} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    } 
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.intakeInfo.label.intakeOptions.postMeal' /> 
                                        </Typography>
                                    } 
                                />
                                <FormControlLabel 
                                    value={IntakeRecommendationType.NONE} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    } 
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.intakeInfo.label.intakeOptions.none' />
                                        </Typography>
                                    } 
                                />
                            </RadioGroup>
                        </Box>
                    </FormControl> 
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!intakeRecommendation}
                        onClick = {handleForwardAction}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.intakeInfo.button.finish' />
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick = {handleSkipAction}
                    >
                        <Typography variant="h5">
                            <FormattedMessage id='general.label.skip' />
                        </Typography>                   
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default intakeRecommendationForm