import { AppState } from './definition'

const defaultState: AppState = {
    router: {
        location: {
            pathname: '/',
            search: '',
            hash: '',
            state: undefined
        },
        action: 'POP'
    },
    formValues: {
        article: null,
        reminderType: null,
        idCounter: 1,
        dateTimeQuantityUnits: [],
        dayInteval: null,
        weekdays: null,
        therapyDuration: {        
            durationType: null,
            startDate: null,
            endDate: null,
            durationInDays: null,
        },
        amount: null,
        intakeRecommendation: null,
        note: null,
        qrCodeValues: undefined
    },
    search: {
        query: '',
        result: {
            articles: [],
            currentPage: 0,
            totalElements: 0,
            totalPages: 0  
        }
    },
    unit: {
        unitList: null
    },
    common: {
        loading: false,
        messages: {}
    },
}

export default defaultState
