import React, { useEffect, useState, useRef, FunctionComponent } from 'react'
import { makeStyles } from "@material-ui/styles"
import { green, red, grey} from '@material-ui/core/colors'
import { FormattedMessage } from "react-intl"
import { useSelector, shallowEqual } from "react-redux"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { AppState, Message } from '../../state/definition'
import { Snackbar, Slide, Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    success: {
      backgroundColor: green[600],
      color: '#ffffff',
      textAlign: 'center'
    },
    warning: {
        backgroundColor: red[600],
        color: '#ffffff',
        textAlign: 'center'

    },
    info: {
        backgroundColor: grey[300],
        color: '#000000',
        textAlign: 'center'
    }
}))

const SlideTransition = (props: TransitionProps) => {
    return <Slide {...props} direction="down" />
}

const Alert: FunctionComponent = () => {
    const classes = useStyles({})
    const message: Message = useSelector((state: AppState) => state.common.messages['ALERT'], shallowEqual)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const timer = useRef(null)
    useEffect(() => {
        if (!!message) {
            setIsOpen(true)
            clearTimeout(timer.current)
            message.timeout && (timer.current = setTimeout(() => {
                setIsOpen(false)
            }, message.timeout - 300))
        } else {
            setIsOpen(false)
        }
        return () => {
            clearTimeout(timer.current)
        }
    }, [message])
    return (
        <Box marginTop={3}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal : 'center'}}
                open={isOpen && !!message}
                TransitionComponent={SlideTransition}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    className: message && message.data && message.data.type && classes[message.data.type]
                }}
                message={
                    <span id="message-id">
                        {!!message && (
                            <>
                                <Box textAlign="left" fontWeight="fontWeightBold">
                                    <FormattedMessage id={message.title} />
                                </Box>
                                {message.description && (
                                    <Box textAlign="left" fontWeight="fontWeightLight">
                                        <FormattedMessage id={message.description} />
                                    </Box>
                                )}
                            </>
                        )}
                    </span>
                }
            />
        </Box>
    )
}

export default Alert