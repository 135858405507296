import { TypeKey, CommonActionTypes } from '../actions/common'
import { CommonState } from '../definition'

const common = (state: CommonState | null = null, action: CommonActionTypes) => {
    switch (action.type) {
        case TypeKey.SET_COMMON_LOADING: {
            const { loading } = action
            return { ...state, loading }
        }
        case TypeKey.SET_COMMON_MESSAGE: {
            const { id, title, description, timeout, data } = action
            return { ...state, messages: { ...state.messages, [id]: { id, title, description, data, timestamp: Date.now(), timeout } } }
        }
        case TypeKey.UNSET_COMMON_MESSAGE: {
            const { id } = action
            const messages = state.messages
            delete messages[id]
            return { ...state, messages }
        }
        default: return state
    }
}

export default common
