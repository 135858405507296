import { AppState, FormValuesState, UnitState, MedicineArticle, ReminderType, Weekday, IntakeRecommendationType } from '../definition'
import { BaseSaga } from './baseSaga'
import { all, takeEvery, put, select } from 'redux-saga/effects'
import { TypeKey as FormValuesTypeKey, AddReminderTimeAction, UpdateReminderTimeAction, ConfirmFormValueAction, FormValuesActions } from '../actions/formValues'
import { ReminderFormWizard } from '../../shared/reminderFormWizard'
import { formatISO, getHours, getMinutes, startOfDay, endOfDay } from 'date-fns'
import { getUnitState } from './unit'
import { FormWizardActions } from '../actions'
import pako from 'pako'

export const getFormValuesState = (state: AppState): FormValuesState => state.formValues

class FormValuesSaga extends BaseSaga {
    formWizard: ReminderFormWizard
    
    constructor() {
        super()
        this.formWizard = ReminderFormWizard.getInstance()
    }

    public *entrySaga(): IterableIterator<any> {
        yield all([
            takeEvery([FormValuesTypeKey.ADD_REMINDER_TIME], this.onAddDateTimeQuantityUnit),
            takeEvery([FormValuesTypeKey.CONFIRM], this.onConfirmFormValues),
        ])
    }

    private *onAddDateTimeQuantityUnit(action: AddReminderTimeAction | UpdateReminderTimeAction) { 
        // const formValuesState: FormValuesState = yield select(getFormValuesState)
        
    }

    private *onConfirmFormValues(action: ConfirmFormValueAction) {
        const formValuesState: FormValuesState = yield select(getFormValuesState)
        const unitState: UnitState = yield select(getUnitState)

        if (!action.confirm) {
            this.formWizard.activeReminderType = undefined
            this.formWizard.activeMainFormStepIndex = 0
            this.formWizard.activeReminderFormStepIndex = 0
        } else {
            yield put(FormWizardActions.routeForward())

            let articleNumber: string
            const article = formValuesState.article as MedicineArticle
            if (article instanceof Object) {
                articleNumber = article.articleNumber
            } 

            const weekdays = {
                [Weekday.SUNDAY]: 0,
                [Weekday.MONDAY]: 1,
                [Weekday.TUESDAY]: 2,
                [Weekday.WEDNESDAY]: 3,
                [Weekday.THURSDAY]: 4,
                [Weekday.FRIDAY]: 5,
                [Weekday.SATURDAY]: 6
            }

            const selectedWeekdays = formValuesState.weekdays && formValuesState.weekdays.length > 0 && 
                formValuesState.weekdays.reduce((acc: number[], currVal) => {
                    if (Object.keys(weekdays).some(key => key as Weekday === currVal)) {
                        acc.push(weekdays[`${currVal}`])
                    }
                    return acc
                }, [])


            const hourIntervalReminderTimes = formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 &&
                formValuesState.dateTimeQuantityUnits.reduce((acc, reminder) => {
                    const reminderValues = {
                        beginHour: getHours(reminder.timeOfDayStart),
                        beginMinute: getMinutes(reminder.timeOfDayStart),
                        endHour: getHours(reminder.timeOfDayEnd),
                        endMinute: getMinutes(reminder.timeOfDayEnd),
                        everyMinute: reminder.hourInterval * 60,
                        unit: {
                            id: reminder.unit && unitState && unitState.unitList.length > 0 && unitState.unitList.find(unit => unit.unitType === reminder.unit).id
                        },
                        quantity: reminder.quantity
                    }
                    acc.push(reminderValues)
                    return acc
                }, [])

            const reminderTimes = formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 && 
                formValuesState.dateTimeQuantityUnits.reduce((acc, reminder) => {
                    const reminderValues = {
                        hours: [
                            getHours(reminder.timeOfDayStart)
                        ],
                        minutes: [
                            getMinutes(reminder.timeOfDayStart)
                        ],
                        unit: {
                            id: reminder.unit && unitState && unitState.unitList.length > 0 && unitState.unitList.find(unit => unit.unitType === reminder.unit).id
                        },
                        quantity: reminder.quantity
                    }
                    acc.push(reminderValues)
                    return acc
                }, [])
        
            const values = {
                medicineCabinetArticle: {
                    article: {
                        articleNumber: articleNumber ?? null
                    },
                    name: !articleNumber ? formValuesState.article : null,
                    unit: {
                        id: formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 &&
                            unitState && unitState.unitList.length > 0 && unitState.unitList.find(unit => unit.unitType === formValuesState.dateTimeQuantityUnits[0].unit).id
                    },
                    stock: formValuesState.amount,
                    notes: formValuesState.note,
                },
                medicineReminder: {
                    reminderType: formValuesState.reminderType,
                    mealInfo: formValuesState.intakeRecommendation !== IntakeRecommendationType.NONE ? formValuesState.intakeRecommendation : null,
                    periods: [{
                        everyDay: formValuesState.reminderType === ReminderType.DAILY ? 1 :
                            (formValuesState.reminderType === ReminderType.DAILY_INTERVAL ? formValuesState.dayInteval : null),
                        everyDayOfWeek: formValuesState.reminderType === ReminderType.WEEKDAY ? 
                            selectedWeekdays : 
                            null,
                        periodBegin: (formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.reminderType === ReminderType.ONCE) ? 
                            formatISO(startOfDay(formValuesState.dateTimeQuantityUnits[0].date)) : 
                            (formValuesState.therapyDuration.startDate && formatISO(startOfDay(formValuesState.therapyDuration.startDate))),
                        periodEnd: (formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.reminderType === ReminderType.ONCE) ? 
                            formatISO(endOfDay(formValuesState.dateTimeQuantityUnits[0].date)) : 
                            (formValuesState.therapyDuration.endDate ? 
                                formatISO(endOfDay(formValuesState.therapyDuration.endDate)) :
                                null
                            ),
                        times: formValuesState.reminderType === ReminderType.HOUR_INTERVAL ? hourIntervalReminderTimes : reminderTimes
                    }]
                }
            }

            try {
                const replaceUndefinedOrNull = (key, value) => {
                    if (value === null || value === undefined) {
                        return undefined
                    }                
                    return value
                }

                const qrCodeValues = btoa(pako.deflate(JSON.stringify(values, replaceUndefinedOrNull), { to: 'string' }))
                yield put(FormValuesActions.setQRValues(`tomqrmav1` + qrCodeValues))
            } catch(e) {
                console.log(e)
            }            
        }
    }
}

export default new FormValuesSaga().entrySaga