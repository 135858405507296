const de = {
    "general.label.ok": "OK",
    "general.label.yes": "Ja",
    "general.label.no": "Nein",
    "general.label.startOver": "Neu starten",
    "general.label.startOver.title": "Willst du neu starten?",
    "general.label.startOver.desc": "Dadurch werden deine Angaben gelöscht und von vorne begonnen.",
    "general.label.confirm": "Übernehmen",
    "general.label.skip": "Überspringen",
    "general.button.close": "Schließen",
    "general.label.cancel": "Abbrechen",
    "general.label.success": "Erfolgreich",
    "general.label.notSpecified": "Keine Angabe",
    "general.error.label.title": "Fehler",
    "general.error.label.desc": "Ohje, es ist ein Fehler aufgetreten, bitte versuche es später erneut.",
    "general.error.button.reload": "Neu laden",
    "general.error.session.desc": "Session abgelaufen",
    "general.label.timePostfix": "{amount} Uhr",
    "general.error.noQuantity": "Keine Vorratsmengen verfügbar",
    "tabBar.label.today": "Heute",
    "tabBar.label.cabinet": "Schrank",
    "tabBar.label.more": "Mehr",
    "appInfo.label.title": "Stressfreies Medikamentenmanagement",
    "appInfo.button.go": "Los geht's",
    "appInfo.button.recovery": "Daten wiederherstellen",
    "appInfo.label.dsgvo": "Indem Du fortfährst akzeptierst Du unsere <a href=\"{amount}\">Nutzungsbedingungen</a> und <a href=\"{total}\">Datenschutzerklärung</a>.",
    "acceptTerms.label.title": "Es stehen neue Geschäftsbedingungen zur Verfügung. Zum fortfahren bitte akzeptieren.",
    "acceptTerms.label.dsgvo": "Indem Du fortfährst akzeptierst Du unsere <a href=\"{amount}\">Nutzungsbedingungen</a> und <a href=\"{total}\">Datenschutzerklärung</a>.",
    "acceptTerms.button.accept": "Los geht's",
    "migrate.label.title": "Willkommen! Wir haben die App komplett neu überarbeitet. Ich wünsche Dir gute Gesundheit und weiterhin alles Gute. TOM.",
    "migrate.button.go": "Los geht's",
    "migrate.label.dsgvo": "Indem Du fortfährst akzeptierst Du unsere <a href=\"{amount}\">Nutzungsbedingungen</a> und <a href=\"{total}\">Datenschutzerklärung</a>.",
    "welcome.label.navBarTitle": "Willkommen",
    "welcome.label.textSegment.i0": "Hallo! Ich bin TOM.",
    "welcome.label.textSegment.i1": "Ich unterstütze Dich bei der Einnahme von Medikamenten.",
    "welcome.label.textSegment.i2": "Lass uns loslegen!",
    "welcome.web.label.textSegment.i1": "Ich helfe dir beim Anlegen einer Erinnerung für dein Medikament",
    "welcome.web.label.textSegment.i2": "Gib zuerst den Namen deines Medikamentes an.",
    "firstMed.label.navBarTitle": "Willkommen",
    "firstMed.label.title": "Dein Medikamenten-\nschrank ist noch leer.\nFüge jetzt Dein erstes Medikament hinzu.",
    "firstMed.button.add": "Medikament hinzufügen",
    "firstMed.button.later": "Jetzt nicht, später",
    "create.label.navBarTitle.medicament.create": "Medikament hinzufügen",
    "create.label.navBarTitle.medicament.edit": "Medikament ändern",
    "create.label.navBarTitle.reminder.create": "Erinnerung hinzufügen",
    "create.dialog.quit.label.title": "Prozess abbrechen",
    "create.dialog.quit.label.medicamentDesc": "Dein Medikament wurde noch nicht gespeichert. Möchtest Du wirklich abbrechen?",
    "create.dialog.quit.label.reminderDesc": "Deine Erinnerung wurde noch nicht gespeichert. Möchtest Du wirklich abbrechen?",
    "create.dialog.quit.button.continue": "Nein, weiter",
    "create.dialog.quit.button.cancel": "Ja, abbrechen",
    "create.selection.label.title": "Du kannst jetzt den Barcode der Verpackung scannen oder den Namen des Medikaments suchen.",
    "create.selection.button.camera": "Code Scannen",
    "create.selection.button.search": "Manuell Suchen",
    "create.scan.label.title": "Richte nun Dein Smartphone auf den Barcode oder QR Code.",
    "create.scan.button.cameraNotAvailable": "Das Scannen über die Kamera ist im Moment nicht verfügbar. Überprüfe Deine Einstellungen oder wechsle in den Modus der manuellen Eingabe!",
    "create.scan.alert.label.fail.title": "Scannen fehlgeschlagen",
    "create.scan.alert.label.fail.text": "Bitte beachte folgende Punkte: \r\n- Stelle ausreichend Helligkeit sicher. \r\n- Vermeide Schattenwurf",
    "create.scan.alert.button.fail.search": "Manuell Suchen",
    "create.scan.alert.button.fail.ok": "OK",
    "create.scan.error.label.title": "Fehler beim Scannen",
    "create.scan.error.label.notFound": "Das gewünschte Medikament konnte nicht gefunden werden",
    "create.scan.error.button.search": "Manuell Suchen",
    "create.scan.error.button.retry": "Erneut versuchen",
    "create.search.label.text": "Gib bitte den Namen Deines Medikaments ein:",
    "create.search.label.desc": "Hier kannst du ganz einfach dein Medikament mit Erinnerung erfassen und am Ende in die App übertragen. Der Prozess ist simpel einfach. Zuerst suchst du in unserer Datenbank nach Deinem Medikament oder gibst es selber ein.",
    "create.search.label.placeholder.normal": "Medikamentenname eingeben",
    "create.search.label.placeholder.active": "Medikamentenname",
    "create.search.label.yourInput": "Deine Eingabe",
    "create.search.button.switchDatabase": "Land wechseln",
    "create.search.label.searchResults": "Suchergebnisse:",
    "create.alreadyInCabinet.label.title": "Medikamentenschrank",
    "create.alreadyInCabinet.label.desc": "Das Medikament \"{amount}\" befindet sich schon in Deinem Medikamentenschrank, willst Du es trotzdem erneut hinzufügen?",
    "create.alreadyInCabinet.button.accept": "Hinzufügen",
    "create.alreadyInCabinet.button.decline": "Abbrechen",
    "create.reminderQuery.label.text": "Möchtest Du eine Erinnerung anlegen oder es nur bei Bedarf nehmen und in den Schrank legen?",
    "create.reminderQuery.button.reminder": "Ja, Erinnerung anlegen",
    "create.reminderQuery.button.dashboard": "Nein, in den Schrank",
    "create.push.label.text": "Ich benötige noch Deine Erlaubnis, damit ich Dich an die Einnahmen erinnern kann.",
    "create.push.button.next": "Weiter",
    "create.medInfo.label.title": "Soll ich mir noch das Ablaufdatum merken, damit ich Dich rechtzeitig daran erinnern kann?",
    "create.medInfo.label.expirationDate": "Ablaufdatum",
    "create.medInfo.label.expirationDateNotificationTime": "Erinnerung vor Ablauf",
    "create.medInfo.label.expirationDateNotificationChangeInfo": "Du kannst das später jederzeit in den Einstellungen ändern",
    "create.medInfo.button.finish": "Weiter",
    "create.medInfo.button.skipInfo": "Ohne Ablaufdatum abschließen",
    "create.medInfo.dialog.exparationDate.label.title": "Ablaufdatum angeben",
    "create.intervalSelection.label.title": "In welchen Abständen soll ich Dich an die Einnahme erinnern?",
    "create.intervalSelection.label.daily.title": "Täglich",
    "create.intervalSelection.label.daily.desc": "Eine Einnahme pro Tag",
    "create.intervalSelection.label.weekday.title": "An bestimmten Wochentagen",
    "create.intervalSelection.label.weekday.desc": "Einnahme mehrmals pro Woche",
    "create.intervalSelection.label.days.title": "Alle x-Tage",
    "create.intervalSelection.label.days.desc": "Im Tages-Rythmus",
    "create.intervalSelection.label.hours.title": "Alle x-Stunden",
    "create.intervalSelection.label.hours.desc": "Im Stunden-Rythmus",
    "create.intervalSelection.label.once.title": "Einmalig",
    "create.intervalSelection.label.once.desc": "Einmalige Einnahme bei Bedarf",
    "create.dailyReminder.label.createFirst.title": "Um welche Uhrzeit soll ich Dich erinnern?",
    "create.dailyReminder.label.createAdditional.title": "Um welche weitere Uhrzeit soll ich Dich erinnern?",
    "create.dailyReminder.label.edit.title": "Wie möchtest Du Deinen Reminder abändern?",
    "create.dailyReminder.button.next": "Weiter",
    "create.dailyReminder.button.cancel": "Abbrechen",
    "create.dailyReminder.dialog.time.title": "Uhrzeit Festlegen",
    "create.dailyReminder.dialog.unit.title": "Einheit ändern",
    "create.dailyReminder.dialog.quantitiy.title": "Menge Festlegen",
    "create.dailyReminder.dialog.sameTime.title": "Reminder Zeiten sind identisch",
    "create.dailyReminder.dialog.sameTime.desc": "Du kannst nicht zwei Reminder zur gleichen Uhrzeit beim selben Medikament anlegen. Bitte ändere Deine gewählte Uhrzeit.",
    "create.dailyReminder.time.title": "Uhrzeit",
    "create.dailyReminder.unit.title": "Einheit",
    "create.dailyReminder.quantitiy.title": "Menge",
    "create.dailyReminderList.label.createFirst.title": "Du hast Deine erste Uhrzeit erfasst. Möchtest Du noch eine hinzufügen?",
    "create.dailyReminderList.label.createAdditional.title": "Möchtest Du eine weitere Uhrzeit hinzufügen?",
    "create.dailyReminderList.label.addTime": "Weitere Uhrzeit hinzufügen",
    "create.dailyReminderList.button.next": "Weiter",
    "create.dailyReminderList.dialog.delete.label.title": "Uhrzeit löschen?",
    "create.dailyReminderList.dialog.delete.label.desc": "Möchtest Du die Einnahme um {amount} wirklich löschen?",
    "create.dailyReminderList.dialog.delete.button.delete": "Ja, löschen",
    "create.dailyReminderList.dialog.delete.button.cancel": "Abbrechen",
    "create.dailyReminderList.dialog.differentUnit.label.title": "Einheiten verschieden",
    "create.dailyReminderList.dialog.differentUnit.label.desc": "Achtung, Du hast für Deine Einnahmen unterschiedliche Einheiten ausgewählt. Damit können wir Deinen Vorrat nicht berechnen/überwachen. Willst du trotzdem fortfahren?",
    "create.dailyReminderList.dialog.stockUnit.label.title": "Hinweis",
    "create.dailyReminderList.dialog.stockUnit.label.desc": "Du hast die Einheit geändert. Dadurch musst Du bitte auch den Vorrat nochmals neu eingeben.",
    "create.dailyReminderList.dialog.stockUnit.button.change": "Weiter zum Vorrat",
    "create.dailyReminder.label.time.postfix": "Uhr",
    "create.dailyReminderList.label.reminder.postfix": "Erinnerung",
    "create.weekdayReminder.label.title": "An welchen Wochentagen soll ich Dich daran erinnern?",
    "create.weekdayReminder.label.monday.full": "Montag",
    "create.weekdayReminder.label.monday.short": "Mo.",
    "create.weekdayReminder.label.tuesday.full": "Dienstag",
    "create.weekdayReminder.label.tuesday.short": "Di.",
    "create.weekdayReminder.label.wednesday.full": "Mittwoch",
    "create.weekdayReminder.label.wednesday.short": "Mi.",
    "create.weekdayReminder.label.thursday.full": "Donnerstag",
    "create.weekdayReminder.label.thursday.short": "Do.",
    "create.weekdayReminder.label.friday.full": "Freitag",
    "create.weekdayReminder.label.friday.short": "Fr.",
    "create.weekdayReminder.label.saturday.full": "Samstag",
    "create.weekdayReminder.label.saturday.short": "Sa.",
    "create.weekdayReminder.label.sunday.full": "Sonntag",
    "create.weekdayReminder.label.sunday.short": "So.",
    "create.weekdayReminder.button.next": "Weiter",
    "create.daysReminder.label.title": "In welchem Tages-Rhythmus soll ich Dich daran erinnern?",
    "create.daysReminder.button.days.title": "Tages-Rhythmus",
    "create.daysReminder.button.days.msg": "Alle {amount} Tage",
    "create.daysReminder.button.next": "Weiter",
    "create.daysReminder.dialog.days.title": "Tages-Rhythmus wählen",
    "create.hoursReminder.label.title": "In welchem Stunden-Rhythmus soll ich Dich daran erinnern?",
    "create.hoursReminder.dialog.hours.title": "Stunden-Rhythmus wählen",
    "create.hoursReminder.button.hours.title": "Stunden-Rhythmus",
    "create.hoursReminder.button.hours.msg": "Alle {amount} Stunden",
    "create.hoursReminder.button.timeStart.title": "Intervall-Anfang",
    "create.hoursReminder.button.timeEnd.title": "Intervall-Ende",
    "create.hoursReminder.dialog.timeStart.title": "Intervall-Anfang wählen",
    "create.hoursReminder.dialog.timeEnd.title": "Intervall-Ende wählen",
    "create.hoursReminder.button.unit.title": "Einheit",
    "create.hoursReminder.button.quantitiy.title": "Menge",
    "create.hoursReminder.label.time.postfix": "Uhr",
    "create.hoursReminder.dialog.unit.title": "Einheit ändern",
    "create.hoursReminder.dialog.quantitiy.title": "Menge Festlegen",
    "create.hoursReminder.errorDialog.endDateInvalid.title": "Invalides Intervall-Ende",
    "create.hoursReminder.errorDialog.endDateInvalid.desc": "Das Intervall-Ende kann nicht vor dem Intervall-Start liegen.",
    "create.hoursReminder.errorDialog.endDateInvalid.interval.desc": "Das Intervall-Ende kann nicht vor dem ersten Einnahmezeitpunkt liegen.",
    "create.hoursReminder.errorDialog.startDateInvalid.title": "Invalider Intervall-Start",
    "create.hoursReminder.errorDialog.startDateInvalid.desc": "Der Intervall-Start kann nicht nach dem Intervall-Ende liegen.",
    "create.hoursReminder.button.next": "Weiter",
    "create.onceReminder.label.title": "Wann soll ich Dich einmalig an Deine Einnahme erinnern?",
    "create.onceReminder.dialog.date.title": "Datum wählen",
    "create.onceReminder.button.date.title": "Datum",
    "create.onceReminder.button.time.title": "Uhrzeit",
    "create.onceReminder.button.unit.title": "Einheit",
    "create.onceReminder.button.quantitiy.title": "Menge",
    "create.onceReminder.dialog.time.title": "Uhrzeit Festlegen",
    "create.onceReminder.dialog.unit.title": "Einheit ändern",
    "create.onceReminder.dialog.quantitiy.title": "Menge Festlegen",
    "create.onceReminder.label.time.postfix": "Uhr",
    "create.onceReminder.button.next": "Weiter",
    "create.period.label.title": "Wie lange soll Deine Therapie dauern?",
    "create.period.label.startTime": "Therapie-Start",
    "create.period.label.periodType": "Therapie-Dauer",
    "create.period.label.periodType.continuous": "Fortlaufend",
    "create.period.label.periodType.endDate": "bis Enddatum",
    "create.period.label.endDate": "Therapie-Ende",
    "create.period.dialog.startDate.title": "Therapie-Start wählen",
    "create.period.dialog.endDate.title": "Therapie-Ende wählen",
    "create.period.label.days": "Anzahl Tage",
    "create.period.dialog.days.title": "Anzahl Tage wählen",
    "create.period.button.next": "Weiter",
    "create.stock.label.title": "Behalte den Überblick über Deinen Vorrat!",
    "create.stock.label.hint.stockInactive": "Aktuelle Vorratsmenge angeben",
    "create.stock.label.hint.stockActive": "Vorratsmenge",
    "create.stock.label.hint.unit": "Einheit",
    "create.stock.label.expirationDateNotificationTime": "Vorratskontrolle",
    "create.stock.label.expirationDateNotificationChangeInfo": "TOM aktualisiert bei jeder Einnahme die Anzahl Tabletten in Deinem Vorrat und informiert Dich, wenn Du nachbestellen solltest.",
    "create.stock.button.next": "Weiter",
    "create.stock.dialog.label.stock.title": "Aktuelle Vorratsmenge",
    "create.stock.dialog.label.unit.title": "Einheit ändern",
    "create.intakeInfo.label.title": "Wie soll das Medikament eingenommen werden?",
    "create.intakeInfo.label.intakeOptions.title": "Anmerkung zur Einnahme",
    "create.intakeInfo.label.intakeOptions.preMeal": "Vor dem Essen",
    "create.intakeInfo.label.intakeOptions.meal": "Zum Essen",
    "create.intakeInfo.label.intakeOptions.postMeal": "Nach dem Essen",
    "create.intakeInfo.label.intakeOptions.none": "Keine Angabe",
    "create.intakeInfo.label.changeInfo": "Du kannst diese Angaben später jederzeit ändern",
    "create.intakeInfo.button.finish": "Weiter",
    "create.summary.label.title": "Sind alle Angaben für diesen Eintrag richtig?",
    "create.summary.label.interval": "Intervall",
    "create.summary.label.reminder": "{amount}. Erinnerung",
    "create.summary.label.emptyReminder": "Erinnerung",
    "create.summary.label.therapyPeriod.title": "Therapie-Dauer",
    "create.summary.label.therapyPeriod.continuous.desc": "ab {amount}",
    "create.summary.label.stock": "Vorratsmenge",
    "create.summary.label.expiryDate": "Ablaufdatum",
    "create.summary.label.intakeInfo": "Anmerkung zur Einnahme",
    "create.summary.label.notes": "Notiz",
    "create.summary.dialog.notes.label.title": "Notiz hinzufügen",
    "create.summary.dialog.notes.placeholder.active": "Notiz",
    "create.summary.dialog.notes.placeholder.inactive": "Zweck, Besonderheiten, Ärtzliche Hinweise, …",
    "create.summary.label.reminder.weekdays": "Wochentage",
    "create.summary.label.reminder.dayInterval.title": "Tagesintervall",
    "create.summary.label.reminder.dayInterval.desc": "alle {amount} Tage",
    "create.summary.label.reminder.hourInterval.title": "Stundenintervall",
    "create.summary.label.reminder.hourInterval.desc": "alle {amount} Stunden",
    "create.summary.label.reminder.hourPeriod.title": "Interval-Zeitraum",
    "create.summary.label.reminder.hourPeriod.desc": "von {amount} bis {total}",
    "create.summary.label.intervalOnce.date": "Datum",
    "create.summary.label.intervalOnce.time": "Uhrzeit",
    "create.summary.label.dosage": "Menge",
    "create.summary.button.ok": "Ja, alles richtig",
    "create.short.summary.label.title": "Mochtest du folgendes Medikament deinem Schrank hinzufügen?",
    "create.short.summary.button.add": "Ja, hinzufügen",
    "create.web.summary.label.title": "So einfach geht das in TOM.", 
    "create.web.summary.label.desc": "Wenn Du willst übertrage ich deine Therapie direkt in die kostenlose App.",
    "create.web.summary.button.add": "Ja, gerne",
    "create.web.summary.button.cancel": "Nein, nicht nötig",
    "qr.code.title": "Mit diesem QR Code kannst du dein angelegtes Medikament direkt in der App verwalten. Scanne dazu mit der TOM mobile App den Code: ",
    "qr.code.desc": "Hast du noch keine App? Dann installiere diese kostenlos über die folgenden Links, danach kannst du in der App den Code scannen:",
    "cabinet.label.navBarTitle": "Medikamenten-Schrank",
    "cabinet.label.stock": "Vorrat",
    "cabinet.label.expiry": "Ablauf",
    "cabinet.label.notSpecified": "k. A.",
    "cabinet.filter.label.title": "Filter",
    "cabinet.filter.label.all": "Alle anzeigen",
    "cabinet.filter.label.withReminder": "Mit Erinnerung",
    "cabinet.filter.label.withoutReminder": "Ohne Erinnerung",
    "cabinet.filter.label.withPrescription": "Rezeptpflichtig",
    "cabinet.filter.label.withoutPrescription": "Nicht rezeptpflichtig",
    "detail.label.navBarTitle": "Medikamenten Übersicht",
    "detail.label.title": "Deine Angaben:",
    "detail.label.editTitle": "Du kannst den Eintrag nun beliebig bearbeiten:",
    "detail.button.save": "Speichern",
    "detail.button.addReminder": "Erinnerung hinzufügen",
    "detail.dialog.deleteSelection.label.title": "Löschen",
    "detail.dialog.deleteSelection.label.desc": "Willst Du das ganze Medikament löschen oder nur die Erinnerung?",
    "detail.dialog.deleteSelection.button.med": "Medikament",
    "detail.dialog.deleteSelection.button.reminder": "Erinnerung",
    "detail.dialog.deleteConfirm.label.title": "Wirklich löschen?",
    "detail.dialog.deleteConfirm.label.reminderDesc": "Willst Du die Erinnerung wirklich löschen?",
    "detail.dialog.deleteConfirm.label.medDesc": "Willst Du das Medikament wirklich löschen?",
    "detail.dialog.deleteConfirm.button.yes": "Ja, löschen.",
    "detail.dialog.deleteConfirm.button.no": "Nein, abbrechen.",
    "detail.success.label.text": "Die Eingaben wurden erfolgreich aktualisiert.",
    "today.label.next.title": "Nächste\nEinnahme in",
    "today.label.next.at": "um",
    "today.label.taken.title": "Erfolgreich\neingenommen",
    "today.label.taken.at": "am {amount} um",
    "today.label.skipped.title": "Nicht\neingenommen",
    "today.label.skipped.at": "am {amount} um",
    "today.label.overdue.title": "Einnahme\nüberfällig seit",
    "today.label.overdue.at": "Heute um",
    "today.label.planned.title": "Geplante\nEinnahme in",
    "today.label.planned.day": "1 Tag",
    "today.label.planned.days": "{amount} Tage",
    "today.label.planned.at": "am {amount} um",
    "today.button.take": "Jetzt einnehmen",
    "today.button.later": "Verschieben",
    "today.button.skip": "Auslassen",
    "today.emptyState.label.text": "Heute musst Du keine Medikamente einnehmen",
    "today.label.clock": "{amount} Uhr",
    "today.postponeDialog.label.title": "Wie weit möchtest Du die Einnahme verschieben?",
    "today.postponeDialog.label.content": "{amount} Minuten",
    "today.postponeDialog.button.later": "Verschieben",
    "today.postponeConfirmDialog.label.title": "Einnahme verschieben",
    "today.postponeConfirmDialog.label.desc": "Bist Du sicher, dass Du die Einnahme um {amount} Minuten verschieben willst?",
    "today.postponeConfirmDialog.button.later": "Verschieben",
    "today.label.selectDay": "Tag wählen",
    "today.intakeInfo.label.confirmed": "Eingenommen um {amount} Uhr",
    "today.intakeInfo.label.postponed": "Verschoben auf {amount} Uhr",
    "today.intakeInfo.label.overdue": "Überfällig seit {amount} Uhr",
    "today.intakeInfo.label.skipped": "Ausgelassen.\n{amount}",
    "today.intakeInfo.label.skippedNoReason": "Ausgelassen.",
    "today.actionSheet.label.takeNow": "Jetzt einnehmen",
    "today.actionSheet.label.takeAllNow": "Alle jetzt einnehmen",
    "today.actionSheet.label.takeOnPoint": "Pünktlich um {amount} Uhr eingenommen",
    "today.actionSheet.label.takeAllOnPoint": "Alle pünktlich um {amount} Uhr eingenommen",
    "today.actionSheet.label.takeOtherTime": "Um andere Uhrzeit eingenommen",
    "today.actionSheet.label.takeAllOtherTime": "Alle um andere Uhrzeit eingenommen",
    "today.actionSheet.label.skip": "Auslassen",
    "today.actionSheet.label.skipAll": "Alle auslassen",
    "today.actionSheet.label.postpone": "Verschieben",
    "today.actionSheet.label.postponeAll": "Alle verschieben",
    "today.actionSheet.label.titleAll": "Alle Medikamente um {amount} Uhr",
    "today.actionSheet.label.reset": "Einnahmestatus zurücksetzen",
    "today.timePicker.label.title": "Uhrzeit Festlegen",
    "today.listBottomButtons.button.takeAllNow": "Alle jetzt einnehmen",
    "today.listBottomButtons.button.takeAllOnPoint": "Alle pünktlich eingenommen",
    "today.listBottomButtons.button.skipAll": "Alle auslassen",
    "today.listBottomButtons.button.takeAllOnOtherPoint": "Alle um andere Uhrzeit eingenommen",
    "today.error.batchIntakes": "Es konnten leider nicht alle Einnahmen aktualisiert werden, bitte versuchen Sie es später erneut.",
    "actionSheet.label.new": "Neu",
    "addEntry.label.title": "Eintrag hinzufügen",
    "addEntry.label.med": "Medikament hinzufügen",
    "addEntry.label.mediCheck": "Medi-Check",
    "invalidCredentials.label.title": "Beim Authentifizierungsprozess ist etwas schiefgegangen.",
    "invalidCredentials.label.text": "Bitte versuche es erneut. Oder schreibe uns an support(at)innovation6.ch und wir versuchen zu helfen!",
    "invalidCredentials.button.retry": "Erneut versuchen",
    "invalidCredentials.button.logout": "Logout",
    "invalidCredentials.logoutConfirm.label.title": "Logout",
    "invalidCredentials.logoutConfirm.label.text": "Mit dieser Aktion löschst Du Deine Daten auf diesem Gerät.",
    "invalidCredentials.logoutConfirm.button.accept": "Fortfahren",
    "invalidCredentials.logoutConfirm.button.cancel": "Abbrechen",
    "loadingCurrentUserFailed.label.title": "Ohje! Beim laden Deiner Daten ist leider etwas schief gelaufen.",
    "loadingCurrentUserFailed.label.text": "Bitte versuche es erneut. Oder schreibe uns an support(at)innovation6.ch und wir versuchen zu helfen!",
    "loadingCurrentUserFailed.button.retry": "Erneut versuchen",
    "loadingGTCFailed.label.title": "Ohje! Beim laden der Allgemeinen Geschäftsbedingugen ist etwas schief gelaufen.",
    "loadingGTCFailed.label.text": "Bitte versuche es erneut. Oder schreibe uns an support(at)innovation6.ch und wir versuchen zu helfen!",
    "loadingGTCFailed.button.retry": "Erneut versuchen",
    "defaultError.label.title": "Irgendetwas ist schief gegangen.",
    "defaultError.label.text": "Bitte versuche es erneut. Oder schreibe uns an support(at)innovation6.ch und wir versuchen zu helfen!",
    "defaultError.button.retry": "Erneut versuchen",
    "confetti.label.title": "Gratuliere!\nDu hast Dein erstes Medikament erfolgreich angelegt.",
    "confetti.button.next": "Weiter",
    "more.label.navBarTitle": "Mehr",
    "more.profile.label.title": "Persönliche Daten",
    "more.profile.label.allergies": "Allergien",
    "more.medicheck.label.title": "Medi-Check",
    "more.medicheck.label.desc": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.",
    "more.legal.label.title": "Rechtliches",
    "more.legal.label.agb": "Nutzungsbedingungen",
    "more.legal.label.dsg": "Datenschutzerklärung",
    "more.legal.label.delete": "Daten löschen",
    "more.legal.label.imprint": "Impressum",
    "more.label.messageSupport": "Nachricht an Support",
    "more.label.updateNews": "Vorschau 2020",
    "more.label.version": "Versionshinweis {amount}",
    "imprint.label.navBarTitle": "Impressum",
    "imprint.label.cooperation": "Innovation 6 AG",
    "imprint.label.address": "Pfäffikerstrasse 109,\n8615 Wermatswil,\nSchweiz",
    "imprint.label.uid": "UID-Nr.: CHE-263.807.653",
    "imprint.label.ceo": "Geschäftsführer: Sven Beichler",
    "imprint.label.mail": "Mail: support@innovation6.ch",
    "imprint.label.web": "Web: www.tommedications.com",
    "revokeData.label.navBarTitle": "Daten löschen",
    "revokeData.label.title": "Bist du sicher, dass Du alle Deine Daten löschen möchtest? Dieser Vorgang kann nicht rückgängig gemacht werden!",
    "revokeData.button.revoke": "Ja, Daten löschen",
    "revokeData.button.keep": "Nein, doch nicht",
    "revokeData.confirmDialog.label.title": "Daten definitiv löschen?",
    "revokeData.confirmDialog.label.desc": "Bist Du ganz sicher, dass Du Deine Daten jetzt löschen möchstest?",
    "revokeData.confirmDialog.button.delete": "Ja, definitiv löschen",
    "revokeData.closeDialog.label.title": "Daten gelöscht",
    "revokeData.closeDialog.label.desc": "Deine Daten wurden unwiderruflich gelöscht. Wir hoffen wir sehen Dich bald wieder!",
    "revokeData.closeDialog.button.bye": "Auf Wiedersehen!",
    "ommitReason.label.navBarTitle": "Einnahme auslassen",
    "ommitReason.label.title": "Gibt es einen bestimmten Grund, warum Du das Medikament auslässt?",
    "ommitReason.button.noReason": "Nichts hinzufügen",
    "ommitReason.label.storageHint": "Deine Angabe zu einem ausgelassenen Medikament werden in Deinen Statistiken vermerkt und können Deinem Arzt wichtige Hinweise liefern.",
    "ommitReason.label.reasonHint": "Grund auswählen",
    "updateDialog.label.title": "Update erhältlich",
    "updateDialog.label.message": "Es ist eine neue Version von {amount} erhältlich.",
    "updateDialog.button.update": "Update",
    "mediCheck.teaser.screen1.label.title": "Der Medi-Check",
    "mediCheck.teaser.screen1.label.desc": "Der Medi-Check prüft bequem und sicher von zu Hause aus deine Medikamente auf Wechselwirkungen und vieles mehr.",
    "mediCheck.teaser.screen1.label.hint": "Persönlich durchgeführt von der Offizinapothekerin FPH Anja Bauert der toppharm Apotheke Witikon/ZH",
    "mediCheck.teaser.screen1.button.close": "Schliessen",
    "mediCheck.teaser.screen1.button.learnMore": "Weiter",
    "mediCheck.teaser.screen2.label.title": "Medikamenten-Analyse",
    "mediCheck.teaser.screen2.label.desc.full": "Der Medi-Check umfasst:\n\n- Wechselwirkungen\n- Doppelmedikation\n- Einnahmehinweise\n- Hinweise bei Allergien\n\nSende direkt aus TOM heraus Deine Medikamente zur Prüfung!",
    "mediCheck.teaser.screen2.label.desc.highlighted": "- Wechselwirkungen\n- Doppelmedikation\n- Einnahmehinweise\n- Hinweise bei Allergien",
    "mediCheck.teaser.screen2.button.close": "Schliessen",
    "mediCheck.teaser.screen2.button.continue": "Jetzt erstellen",
    "mediCheckList.label.navBarTitle": "Bisherige Medi-Checks",
    "mediCheckList.switch.label.open": "Offen",
    "mediCheckList.openSection.label.paymentNotCompleted": "Bezahlung nicht abgeschlossen",
    "mediCheckList.openSection.label.inProgress": "In Bearbeitung",
    "mediCheckList.openSection.label.inquiry": "Request",
    "mediCheckList.switch.label.finished": "Erledigt",
    "mediCheckList.button.startMediCheck": "Neuer Medi-Check starten",
    "mediCheckList.empty.label.open": "Aktuell hast du keine offenen Medi-Checks",
    "mediCheckList.empty.label.finished": "Es wurde noch keiner deiner Medi-Checks abgeschlossen. Bitte gedulde dich noch ein bisschen.",
    "mediCheckList.empty.label.general": "Du hast noch keinen Medi-Check angelegt. Mit einem Button-Click kanns schon losgehen.",
    "mediCheckList.label.transmitted": "Übermittelt am {amount}",
    "mediCheckList.label.recieved": "Erhalten am {amount}",
    "mediCheckCreation.label.navBarTitle": "Medi-Check",
    "mediCheckCreation.medSelection.label.title": "Das sind deine Medikamente im Schrank. Wähle max 6 davon aus oder füge neue für den Medi-Check Medikamente hinzu.",
    "mediCheckCreation.medSelection.label.hintCabinet": "Medikamente aus Deinem Schrank",
    "mediCheckCreation.medSelection.label.addMed": "Medikament hinzufügen",
    "mediCheckCreation.medSelection.button.next": "Weiter",
    "mediCheckCreation.medSelection.error.limitReached": "Du hast bereits die maximal mögliche Anzahl an Medikamenten selektiert.",
    "mediCheckCreation.medSelection.error.selectMore.label.title": "Zu wenig Medikamente Ausgewählt",
    "mediCheckCreation.medSelection.error.selectMore.label.desc": "Du musst mindestens 2 Medikamente auswählen",
    "mediCheckCreation.dialogQuit.label.title": "Medi-Check abbrechen",
    "mediCheckCreation.dialogQuit.label.desc": "Bist du dir sicher, dass du keinen Medi-Check durchführen lassen willst?",
    "mediCheckCreation.dialogQuit.button.continue": "Nein, weiter",
    "mediCheckCreation.dialogQuit.button.cancel": "Ja, abbrechen",
    "mediCheckCreation.allergieSelection.label.title": "Hast Du irgendwelche Allergien?",
    "mediCheckCreation.allergieSelection.label.allergyType.food": "Lebensmittel-Allergie",
    "mediCheckCreation.allergieSelection.label.allergyType.medication": "Medikamenten-Allergie",
    "mediCheckCreation.allergieSelection.label.allergyType.other": "Andere Allergie",
    "mediCheckCreation.allergieSelection.label.addAllergie": "Weitere Allergie hinzufügen",
    "mediCheckCreation.allergieSelection.label.noAllergiesSoFar": "Du hast noch keine Allergien angegeben. Diese Angabe ist wichtig, damit diese beim Medi-Check berücksichtigt werden kann.",
    "mediCheckCreation.allergieSelection.button.next": "Weiter",
    "mediCheckCreation.allergieSelection.button.nextWithoutAllergies": "Weiter ohne Allergien",
    "mediCheckCreation.allergieSelection.error.limitReached": "Du hast bereits die maximal mögliche Anzahl an Allergien selektiert.",
    "mediCheckCreation.summary.label.title": "Zusammenfassung deiner gewählten Elemente für deinen Medi-Check",
    "mediCheckCreation.summary.label.allergyType.food": "Ausgewählte Lebensmittel-Allergien",
    "mediCheckCreation.summary.label.allergyType.medication": "Ausgewählte Medikamenten-Allergien",
    "mediCheckCreation.summary.label.allergyType.other": "Ausgewählte andere Allergien",
    "mediCheckCreation.summary.label.meds": "Ausgewählte Medikamente",
    "mediCheckCreation.summary.label.editMeds": "Medikamente bearbeiten",
    "mediCheckCreation.summary.label.editAllergies": "Allergien bearbeiten",
    "mediCheckCreation.summary.label.legalDisclaimer": "Der Medi-Check ersetzt keine Konsultation Deines behandelnden Arztes!",
    "mediCheckCreation.summary.button.buy": "Kaufen",
    "mediCheckCreation.summary.label.payment.in.progress": "Ihr letzter Kauf ist noch in Validierung. Es kann einige Minuten dauern, bis sie einen neuen Kauf abschließen können.",
    "mediCheckDetails.label.id": "Medi-Check ID",
    "mediCheckDetails.label.buyInfo": "kaufen info text --> insert here <--",
    "mediCheckDetails.label.redeemInfo": "einlösen info text --> insert here <--",
    "mediCheckDetails.button.buy": "Jetzt kaufen",
    "mediCheckDetails.button.redeem": "Jetzt einlösen",
    "mediCheckDetails.label.pharmacyInquiry": "Es gibt Rückfragen von der Apotheke zu deinem Medi-Check. Kontaktiere sie so bald als möglich.",
    "mediCheckDetails.label.pharmacyInfo.name": "TopPharm Apotheke Witikon",
    "mediCheckDetails.label.pharmacyInfo.address": "Carl Spitteler-Strasse 2\n8053 Zürich",
    "mediCheckDetails.label.pharmacyInfo.phone": "044 380 00 26",
    "mediCheckDetails.label.pharmacyInfo.mail": "info@witikon.apotheke.ch",
    "mediCheckDetails.label.payment.in.progress": "Ihr letzter Kauf ist noch in Validierung. Es kann einige Minuten dauern, bis sie einen neuen Kauf abschließen können.",
    "pharmacy.overview.label.pharmacy": "Apotheke",
    "allergies.label.navBarTitle": "Meine Allergien",
    "allergies.empty.label.title": "Hast Du eine oder mehrere Medikamenten-, Nahrungsmittel- oder andere Allergien?",
    "allergies.empty.button.add": "Allergie hinzufügen",
    "allergies.label.title": "Deine bisher angegebenen Allergien:",
    "allergies.button.add": "Allergie hinzufügen",
    "allergies.button.save": "Speichern",
    "allergies.label.allergyType.food": "Lebensmittel-Allergie",
    "allergies.label.allergyType.medication": "Medikamenten-Allergie",
    "allergies.label.allergyType.other": "Andere Allergie",
    "allergies.addDialog.label.title": "Allergie hinzufügen",
    "allergies.search.label.title": "Gib bitte den Namen Deiner Allergien ein:",
    "allergies.textInput.label.title": "Gib bitte den Namen Deiner Allergien ein:",
    "allergies.search.label.medHint": "Medikamenten- und Inhaltsstoffe-Allergien",
    "allergies.search.label.searchFieldHint": "Allergie suchen",
    "allergies.textInput.label.foodHint": "Lebensmittel-Allergie",
    "allergies.textInput.label.otherHint": "Andere Allergie",
    "allergies.textInput.label.textFieldHint": "Allergie eingeben",
    "allergies.textInput.button.confirm": "Übernehmen"
}
export default de