import { BaseSaga } from './baseSaga'
import { put, all, takeEvery, select } from 'redux-saga/effects'
import { TypeKey as FormValuesTypeKey, SetReminderTypeAction, FormValuesActions } from '../actions/formValues'
import { TypeKey as FormWizardTypeKey } from '../actions/formWizard'
import { ReminderFormWizard } from '../../shared/reminderFormWizard'
import { push, goBack, go, LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { FormType, FormValuesState } from '../definition'
import { getFormValuesState } from './formValues'

class FormWizardSaga extends BaseSaga {

    formWizard: ReminderFormWizard
    constructor() {
        super()
        this.formWizard = ReminderFormWizard.getInstance()
    }
    
    public *entrySaga(): IterableIterator<any> {
        if (typeof window === 'undefined') {
            return
        }
        yield all([
            takeEvery([FormValuesTypeKey.SET_REMINDER_TYPE], this.setReminderType),
            takeEvery([FormWizardTypeKey.ROUTE_FORWARD], this.routeForward),
            takeEvery([FormWizardTypeKey.ROUTE_BACK], this.routeBack),
            takeEvery([LOCATION_CHANGE], this.onLocationChange)
        ])
    }

    private *onLocationChange(action: LocationChangeAction) {
        if (action.payload.action === 'POP') {
            const step = this.formWizard.getCurrentStep()

            if ((action.payload.location.pathname === FormType.SEARCH_FORM && action.payload.location.pathname !== step.formId) ||
                action.payload.location.pathname.split('/')[1] !== step.formId
            ) {
                if ((step && step.backStep)) { 
                    this.formWizard.getPreviousStepNumber() 
                    
                    if (step.backStep === FormType.REMINDER_SELECTION_FORM) {
                        yield put(FormValuesActions.reset())
                    }
                    yield put(goBack())
                } else {
                    if (!this.formWizard.activateBackStep()) {
                        this.formWizard.activeReminderType = undefined
                        yield put(FormValuesActions.reset())
                    } 
                } 
            }
        }
    }

    private *setReminderType(action: SetReminderTypeAction) {
        if (action.reminderType) {
            this.formWizard.activeReminderType = action.reminderType
            const currentFormStep = this.formWizard.getCurrentStep()

            if (currentFormStep) {
                yield put(push(`/${currentFormStep.formId}`))
            }            
        } else {
            this.formWizard.activeReminderType = action.reminderType
        }
    }

    private *routeForward() {
        const formValuesState: FormValuesState = yield select(getFormValuesState)
        const currentStep = this.formWizard.getCurrentStep()

        if ((currentStep.formId === FormType.WEEKDAY_INTERVAL_FORM || currentStep.formId === FormType.DAY_INTERVAL_FORM) && 
            (formValuesState && formValuesState.dateTimeQuantityUnits.length !== 0)        
        ) {
            const nextStepNumber = this.formWizard.getNextStepNumber()
            yield put(go(nextStepNumber))
        } else {
            const selectedUnit = formValuesState.dateTimeQuantityUnits[0] && formValuesState.dateTimeQuantityUnits[0].unit
            const isUnitTypeDifferent = formValuesState.dateTimeQuantityUnits.some(element => 
                element.unit !== selectedUnit
            )

            if (currentStep.formId === FormType.THERAPY_DURATION_FORM && isUnitTypeDifferent) {
                this.formWizard.getNextStepNumber()
                yield put(FormValuesActions.setAmount(null))
                yield put(push(`/${FormType.INTAKE_RECOMMENDATION_FORM}`))
            } else {
                const nextFormStep = this.formWizard.getNextStep()
                if (nextFormStep) {
                    yield put(push(`/${nextFormStep.formId}`))
                }               
            }
        }        
    } 

    private *routeBack() {
        const formValuesState: FormValuesState = yield select(getFormValuesState)
        const currentStep = this.formWizard.getCurrentStep()

        const selectedUnit = formValuesState.dateTimeQuantityUnits[0] && formValuesState.dateTimeQuantityUnits[0].unit
        const isUnitTypeDifferent = formValuesState.dateTimeQuantityUnits.some(element => 
            element.unit !== selectedUnit
        )
        if ((currentStep && currentStep.backStep)) { 
            const backStepNumber = this.formWizard.getPreviousStepNumber() 
            
            if (currentStep.formId === FormType.INTAKE_RECOMMENDATION_FORM && isUnitTypeDifferent) {
                yield put(goBack())
                return
            }
            if (currentStep.backStep === FormType.REMINDER_SELECTION_FORM) {
                yield put(FormValuesActions.reset())
            }
            yield put(go(backStepNumber))
        } else {
            if (!this.formWizard.activateBackStep()) {
                this.formWizard.activeReminderType = undefined
                yield put(FormValuesActions.reset())
            } 
            yield put(goBack())
        }       
    }
}

export default new FormWizardSaga().entrySaga