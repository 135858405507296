import { PaginatedResponse, MedicineArticle, AppState, SearchState, SearchResult, ImagePriorityType } from '../definition'
import { BaseSaga } from './baseSaga'
import { put, all, takeEvery, call, select } from 'redux-saga/effects'
import { TypeKey as SearchTypeKey, SearchActions, PerformSearchAction } from '../actions/search'
import NetworkClient from '../../api/networkClient'
import { CommonActions } from '../actions'

export const getSearchState = (state: AppState): SearchState => state.search

class SearchSaga extends BaseSaga {
    public *entrySaga(): IterableIterator<any> {
        yield all([
            takeEvery([SearchTypeKey.PERFORM_SEARCH], this.onSearch),
        ])
    }

    private *onSearch(action: PerformSearchAction) { 
        const searchState: SearchState = yield select(getSearchState)
        const { query } = searchState
        const name = query ? query : ''
        const pageNumber = action.pageNumber ? action.pageNumber : 0

        yield put(CommonActions.setLoading(true))
        try {
            const response: PaginatedResponse<MedicineArticle> = yield call(NetworkClient.article.getAll, name, action.locale, pageNumber, 20)
            
            let updatedContent: MedicineArticle[] = []
            if (response.content && response.content.length > 0) {
                updatedContent = response.content.map(article => {
                    if (article.imageTypes && article.imageTypes.length > 0) {
                        const imageTypePriority = article.imageTypes.find(type => 
                            type === ImagePriorityType.PICFRONT3D || 
                            type === ImagePriorityType.PICFRONT3DPLUS || 
                            type === ImagePriorityType.PICFRONT
                        )
                        const imageType = imageTypePriority ? imageTypePriority : article.imageTypes[0]
                        const imgUrl = article.imageURLTemplate && article.imageURLTemplate
                            .replace('<__IMAGETYPE__>', `${imageType}`)
                            .replace('<__IMAGESIZE__>', 'T')
                            .replace('<__PIXEL__>', '350') 

                        return { ...article, imageURLTemplate: imgUrl }
                    }
                    return article
                })
            }

            const searchResult: SearchResult = {
                articles: updatedContent ? updatedContent : null, 
                totalElements: response.totalElements ? response.totalElements : 0, 
                currentPage: response.currentPage ? response.currentPage : 0, 
                totalPages: response.totalPages ? response.totalPages : 0
            }

            yield put(SearchActions.setResult(searchResult))
        } catch(e) {
            console.log(e)
        } finally {
            yield put(CommonActions.setLoading(false))
        }     
    }
}

export default new SearchSaga().entrySaga