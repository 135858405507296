const es = {
    "general.label.ok": "OK",
    "general.label.yes": "Sí",
    "general.label.no": "No",
    "general.label.startOver": "Comenzar de nuevo",
    "general.label.startOver.title": "¿Quieres empezar de nuevo?",
    "general.label.startOver.desc": "Esto eliminará sus entradas y comenzará de nuevo.",
    "general.label.confirm": "Aceptar",
    "general.label.skip": "Saltar",
    "general.button.close": "Cerrar",
    "general.label.cancel": "Cancelar",
    "general.label.success": "Correctamente",
    "general.label.notSpecified": "Sin datos",
    "general.error.label.title": "Error",
    "general.error.label.desc": "¡Vaya, se ha producido un error! Vuelve a intentarlo más tarde.",
    "general.error.button.reload": "Volver a cargar",
    "general.error.session.desc": "Sesión expirada",
    "general.label.timePostfix": "{amount}",
    "general.error.noQuantity": "Sin cantidades de reserva",
    "general.label.minutes": "{amount} minutos",    
    "tabBar.label.today": "Hoy",
    "tabBar.label.cabinet": "Armario",
    "tabBar.label.more": "Más",    
    "appInfo.label.title": "Gestión de medicamentos sin ningún estrés",
    "appInfo.button.go": "¡Vamos!",
    "appInfo.button.recovery": "Restablecer los datos",
    "appInfo.label.dsgvo": "Si continúas, aceptas nuestras <a href=\"{amount}\">Condiciones de uso</a> y nuestra <a href=\"{total}\">Declaración de protección de datos</a>.",    
    "acceptTerms.label.title": "Hay nuevas condiciones de uso disponibles. Para poder continuar, es necesario aceptarlas.",
    "acceptTerms.label.dsgvo": "Si continúas, aceptas nuestras <a href=\"{amount}\">Condiciones de uso</a> y nuestra <a href=\"{total}\">Declaración de protección de datos</a>.",
    "acceptTerms.button.accept": "Aceptar y continuar",    
    "migrate.label.title": "¡Te doy la bienvenida! Hemos revisado la aplicación completa. Espero que tengas buena salud, ¡te deseo lo mejor! TOM.",
    "migrate.button.go": "¡Vamos!",
    "migrate.label.dsgvo": "Si continúas, aceptas nuestras <a href=\"{amount}\">Condiciones de uso</a> y nuestra <a href=\"{total}\">Declaración de protección de datos</a>.",    
    "welcome.label.navBarTitle": "Te doy la bienvenida",
    "welcome.label.textSegment.i0": "¡Hola! Soy TOM.",
    "welcome.label.textSegment.i1": "Voy a ayudarte a que te tomes los medicamentos.",
    "welcome.label.textSegment.i2": "¡Vamos a empezar!",
    "welcome.web.label.textSegment.i1": "Te ayudaré a crear un recordatorio para tu medicación",
    "welcome.web.label.textSegment.i2": "Primero busque el nombre de su medicamento.",    
    "firstMed.label.navBarTitle": "Te doy la bienvenida",
    "firstMed.label.title": "Tu armario de medicamentos\naún está vacío.\nAñade ahora tu primer medicamento.",
    "firstMed.button.add": "Añadir medicamento",
    "firstMed.button.scan": "Escanea el código web",
    "firstMed.button.later": "Ahora no, más tarde",    
    "create.label.navBarTitle.medicament.create": "Añadir medicamento",
    "create.label.navBarTitle.medicament.edit": "Cambiar medicamento",
    "create.label.navBarTitle.reminder.create": "Añadir recordatorio",
    "create.dialog.quit.label.title": "Cancelar el proceso",
    "create.dialog.quit.label.medicamentDesc": "Tu medicamento aún no se ha guardado. ¿Realmente quieres cancelar?",
    "create.dialog.quit.label.reminderDesc": "Tu recordatorio aún no se ha guardado. ¿Realmente quieres cancelar?",
    "create.dialog.quit.button.continue": "No, seguir",
    "create.dialog.quit.button.cancel": "Sí, cancelar",
    "create.selection.label.title": "Ahora puedes escanear el código de barras del embalaje o buscar el nombre del medicamento.",
    "create.selection.button.camera": "Escanear el código",
    "create.selection.button.search": "Buscar manualmente",
    "create.scan.label.title": "Orienta tu smartphone hacia el código de barras o el código QR.",
    "create.scan.button.cameraNotAvailable": "El escaneo a través de la cámara no está disponible en este momento. Comprueba tus ajustes o cambia al modo de entrada manual.",
    "create.scan.alert.label.fail.title": "El escaneo ha fallado",
    "create.scan.alert.label.fail.text": "Ten en cuenta lo siguiente:  \r\n- Garantiza que haya iluminación suficiente. \r\n- Evita las sombras.",
    "create.scan.alert.button.fail.search": "Buscar manualmente",
    "create.scan.alert.button.fail.ok": "OK",
    "create.scan.error.label.title": "Error al escanear",
    "create.scan.error.label.notFound": "No se ha podido encontrar el medicamento deseado",
    "create.scan.error.button.search": "Buscar manualmente",
    "create.scan.error.button.retry": "Volverlo a intentar",
    "create.search.label.text": "Introduce el nombre de tu medicamento: ",
    "create.search.label.desc": "Aquí puede ingresar fácilmente su medicamento con un recordatorio y al final transferirlo a la aplicación. El proceso es simple y fácil. Primero, busque su medicamento en nuestra base de datos o ingrese usted mismo",
    "create.search.label.placeholder.normal": "Introducir el nombre del medicamento",
    "create.search.label.placeholder.active": "Nombre del medicamento",
    "create.search.label.yourInput": "Tu entrada",
    "create.search.button.switchDatabase": "Cambiar el país",
    "create.search.label.searchResults": "Resultados de la búsqueda: ",
    "create.search.dialog.changeDb.label.title": "Cambiar la base de datos de medicamentos",
    "create.search.label.defaultDb": "Todos los países",
    "create.alreadyInCabinet.label.title": "Armario de medicamentos",
    "create.alreadyInCabinet.label.desc": "El medicamento \"{amount}\" ya está en tu armario de medicamentos, ¿lo quieres volver a añadir?",
    "create.alreadyInCabinet.button.accept": "Añadir",
    "create.alreadyInCabinet.button.decline": "Cancelar",
    "create.reminderQuery.label.text": "¿Quieres crear un recordatorio o tomarlo solo en caso necesario y colocarlo en el armario?",
    "create.reminderQuery.button.reminder": "Sí, crear recordatorio",
    "create.reminderQuery.button.dashboard": "No, al armario",
    "create.push.label.text": "Necesito tu consentimiento para que pueda recordarte las tomas.",
    "create.push.button.next": "Seguir",
    "create.medInfo.label.title": "¿Quieres que también memorice la fecha de caducidad para poderte recordar a tiempo?",
    "create.medInfo.label.expirationDate": "Fecha de caducidad",
    "create.medInfo.label.expirationDateNotificationTime": "Recordatorio antes de la caducidad",
    "create.medInfo.label.expirationDateNotificationChangeInfo": "Puedes cambiar este ajuste en cualquier momento en los ajustes",
    "create.medInfo.button.finish": "Seguir",
    "create.medInfo.button.skipInfo": "Finalizar sin la fecha de caducidad",
    "create.medInfo.dialog.exparationDate.label.title": "Indicar la fecha de caducidad",
    "create.intervalSelection.label.title": "¿Con qué regularidad quieres que te recuerde la toma?",
    "create.intervalSelection.label.daily.title": "Diariamente",
    "create.intervalSelection.label.daily.desc": "Una toma al día",
    "create.intervalSelection.label.weekday.title": "En determinados días de la semana",
    "create.intervalSelection.label.weekday.desc": "Toma varias veces por semana",
    "create.intervalSelection.label.days.title": "Cada x días",
    "create.intervalSelection.label.days.desc": "En intervalos diarios",
    "create.intervalSelection.label.hours.title": "Cada x horas",
    "create.intervalSelection.label.hours.desc": "En intervalos de horas",
    "create.intervalSelection.label.once.title": "Una vez",
    "create.intervalSelection.label.once.desc": "Toma única en caso de que sea necesario",
    "create.dailyReminder.label.createFirst.title": "¿A qué hora te lo recuerdo?",
    "create.dailyReminder.label.createAdditional.title": "¿A qué otra hora te lo recuerdo?",
    "create.dailyReminder.label.edit.title": "¿Cómo quieres cambiar tu recordatorio?",
    "create.dailyReminder.button.next": "Seguir",
    "create.dailyReminder.button.cancel": "Cancelar",
    "create.dailyReminder.dialog.time.title": "Fijar la hora",
    "create.dailyReminder.dialog.unit.title": "Cambiar la unidad",
    "create.dailyReminder.dialog.quantitiy.title": "Fijar la cantidad",
    "create.dailyReminder.dialog.sameTime.title": "Los recordatorios tienen horas idénticas",
    "create.dailyReminder.dialog.sameTime.desc": "No puedes crear dos recordatorios a la misma hora para el mismo medicamento. Cambia la hora seleccionada.",
    "create.dailyReminder.time.title": "Hora",
    "create.dailyReminder.unit.title": "Unidad",
    "create.dailyReminder.quantitiy.title": "Cantidad",
    "create.dailyReminderList.label.createFirst.title": "Has registrado tu primera hora. ¿Quieres añadir una más?",
    "create.dailyReminderList.label.createAdditional.title": "¿Quieres añadir otra hora más?",
    "create.dailyReminderList.label.addTime": "Añadir otra hora",
    "create.dailyReminderList.button.next": "Seguir",
    "create.dailyReminderList.dialog.delete.label.title": "¿Borrar la hora?",
    "create.dailyReminderList.dialog.delete.label.desc": "¿Quieres borrar la toma de las {amount} de verdad?",
    "create.dailyReminderList.dialog.delete.button.delete": "Sí, borrar",
    "create.dailyReminderList.dialog.delete.button.cancel": "Cancelar",
    "create.dailyReminderList.dialog.differentUnit.label.title": "Las unidades son diferentes",
    "create.dailyReminderList.dialog.differentUnit.label.desc": "¡Ojo! Has seleccionado distintas unidades para tus tomas. Así no podemos calcular/controlar tu reserva. ¿Quieres continuar?",
    "create.dailyReminderList.dialog.stockUnit.label.title": "Aviso",
    "create.dailyReminderList.dialog.stockUnit.label.desc": "Has cambiado la unidad. Ahora también tienes que volver a introducir la reserva.",
    "create.dailyReminderList.dialog.stockUnit.button.change": "Ir a la reserva",
    "create.dailyReminder.label.time.postfix": " ",
    "create.dailyReminderList.label.reminder.postfix": "Recordatorio",
    "create.weekdayReminder.label.title": "¿Qué días de la semana te lo recuerdo?",
    "create.weekdayReminder.label.monday.full": "Lunes",
    "create.weekdayReminder.label.monday.short": "Lu.",
    "create.weekdayReminder.label.tuesday.full": "Martes",
    "create.weekdayReminder.label.tuesday.short": "Ma.",
    "create.weekdayReminder.label.wednesday.full": "Miércoles",
    "create.weekdayReminder.label.wednesday.short": "Mi.",
    "create.weekdayReminder.label.thursday.full": "Jueves",
    "create.weekdayReminder.label.thursday.short": "Ju.",
    "create.weekdayReminder.label.friday.full": "Viernes",
    "create.weekdayReminder.label.friday.short": "Vi.",
    "create.weekdayReminder.label.saturday.full": "Sábado",
    "create.weekdayReminder.label.saturday.short": "Sá.",
    "create.weekdayReminder.label.sunday.full": "Domingo",
    "create.weekdayReminder.label.sunday.short": "Do.",
    "create.weekdayReminder.button.next": "Seguir",
    "create.daysReminder.label.title": "¿Con qué ritmo diario te lo recuerdo?",
    "create.daysReminder.button.days.title": "Ritmo diario",
    "create.daysReminder.button.days.msg": "Cada {amount} días",
    "create.daysReminder.button.next": "Seguir",
    "create.daysReminder.dialog.days.title": "Seleccionar ritmo diario",
    "create.hoursReminder.label.title": "¿Con qué ritmo horario te lo recuerdo?",
    "create.hoursReminder.dialog.hours.title": "Seleccionar ritmo horario",
    "create.hoursReminder.button.hours.title": "Ritmo horario",
    "create.hoursReminder.button.hours.msg": "Cada {amount} horas",
    "create.hoursReminder.button.timeStart.title": "Inicio del intervalo",
    "create.hoursReminder.button.timeEnd.title": "Final del intervalo",
    "create.hoursReminder.dialog.timeStart.title": "Seleccionar inicio del intervalo",
    "create.hoursReminder.dialog.timeEnd.title": "Seleccionar final del intervalo",
    "create.hoursReminder.button.unit.title": "Unidad",
    "create.hoursReminder.button.quantitiy.title": "Cantidad",
    "create.hoursReminder.label.time.postfix": " ",
    "create.hoursReminder.dialog.unit.title": "Cambiar la unidad",
    "create.hoursReminder.dialog.quantitiy.title": "Fijar la cantidad",
    "create.hoursReminder.errorDialog.endDateInvalid.title": "Final del intervalo inválido",
    "create.hoursReminder.errorDialog.endDateInvalid.desc": "El final del intervalo no puede ser anterior al inicio del mismo.",
    "create.hoursReminder.errorDialog.endDateInvalid.interval.desc": "El final del intervalo no puede ser anterior a la primera hora de toma.",
    "create.hoursReminder.errorDialog.startDateInvalid.title": "Inicio del intervalo inválido",
    "create.hoursReminder.errorDialog.startDateInvalid.desc": "El inicio del intervalo no puede ser posterior al final del intervalo.",
    "create.hoursReminder.button.next": "Seguir",
    "create.onceReminder.label.title": "¿Cuándo quieres que sea la única vez que te recuerde tu toma?",
    "create.onceReminder.dialog.date.title": "Seleccionar la fecha",
    "create.onceReminder.button.date.title": "Fecha",
    "create.onceReminder.button.time.title": "Hora",
    "create.onceReminder.button.unit.title": "Unidad",
    "create.onceReminder.button.quantitiy.title": "Cantidad",
    "create.onceReminder.dialog.time.title": "Fijar la hora",
    "create.onceReminder.dialog.unit.title": "Cambiar la unidad",
    "create.onceReminder.dialog.quantitiy.title": "Fijar la cantidad",
    "create.onceReminder.label.time.postfix": " ",
    "create.onceReminder.button.next": "Seguir",
    "create.period.label.title": "¿Cuánto va a durar tu tratamiento?",
    "create.period.label.startTime": "Inicio del tratamiento",
    "create.period.label.periodType": "Duración del tratamiento",
    "create.period.label.periodType.continuous": "Continuo",
    "create.period.label.periodType.endDate": "hasta la fecha final",
    "create.period.label.endDate": "Final del tratamiento",
    "create.period.dialog.startDate.title": "Seleccionar el inicio del tratamiento",
    "create.period.dialog.endDate.title": "Seleccionar el fin del tratamiento",
    "create.period.label.days": "Cantidad de días",
    "create.period.dialog.days.title": "Seleccionar la cantidad de días",
    "create.period.button.next": "Seguir",
    "create.stock.label.title": "¡Mantén tu reserva bajo control!",
    "create.stock.label.hint.stockInactive": "Indicar la cantidad de reserva actual",
    "create.stock.label.hint.stockActive": "Cantidad de reserva",
    "create.stock.label.hint.unit": "Unidad",
    "create.stock.label.expirationDateNotificationTime": "Control de reserva",
    "create.stock.label.expirationDateNotificationChangeInfo": "Tras cada toma, TOM actualiza la cantidad de pastillas de tu reserva y te informa cuando tienes que hacer un pedido.",
    "create.stock.button.next": "Seguir",
    "create.stock.dialog.label.stock.title": "Cantidad de reserva actual",
    "create.stock.dialog.label.unit.title": "Cambiar la unidad",
    "create.intakeInfo.label.title": "¿Cómo tienes que tomarte el medicamento?",
    "create.intakeInfo.label.intakeOptions.title": "Observación sobre la toma",
    "create.intakeInfo.label.intakeOptions.preMeal": "Antes de comer",
    "create.intakeInfo.label.intakeOptions.meal": "A la hora de comer",
    "create.intakeInfo.label.intakeOptions.postMeal": "Después de comer",
    "create.intakeInfo.label.intakeOptions.none": "Sin información",
    "create.intakeInfo.label.changeInfo": "Este ajuste lo puedes cambiar en cualquier momento",
    "create.intakeInfo.button.finish": "Seguir",
    "create.summary.label.title": "¿Todos los datos para esta entrada son correctos?",
    "create.summary.label.interval": "Intervalo",
    "create.summary.label.reminder": "{amount}. Recordatorio",
    "create.summary.label.emptyReminder": "Recordatorio",
    "create.summary.label.therapyPeriod.title": "Duración del tratamiento",
    "create.summary.label.therapyPeriod.continuous.desc": "a partir de {amount}",
    "create.summary.label.stock": "Cantidad de reserva",
    "create.summary.label.expiryDate": "Fecha de caducidad",
    "create.summary.label.intakeInfo": "Observación sobre la toma",
    "create.summary.label.notes": "Nota",
    "create.summary.dialog.notes.label.title": "Añadir nota",
    "create.summary.dialog.notes.placeholder.active": "Nota",
    "create.summary.dialog.notes.placeholder.inactive": "Finalidad, peculiaridades, instrucciones médicas, etc.",
    "create.summary.label.reminder.weekdays": "Días de la semana",
    "create.summary.label.reminder.dayInterval.title": "Intervalo diario",
    "create.summary.label.reminder.dayInterval.desc": "cada {amount} días",
    "create.summary.label.reminder.hourInterval.title": "Intervalo horario",
    "create.summary.label.reminder.hourInterval.desc": "cada {amount} horas",
    "create.summary.label.reminder.hourPeriod.title": "Período de intervalo",
    "create.summary.label.reminder.hourPeriod.desc": "desde {amount} hasta {total}",
    "create.summary.label.intervalOnce.date": "Fecha",
    "create.summary.label.intervalOnce.time": "Hora",
    "create.summary.label.dosage": "Cantidad",
    "create.summary.button.ok": "Sí, todo está correcto",
    "create.short.summary.label.title": "¿Quieres añadir el siguiente medicamento a tu armario?",
    "create.short.summary.button.add": "Sí, añadir",
    "create.web.summary.label.title": "Es así de fácil en TOM", 
    "create.web.summary.label.desc": "Si lo desea, puedo transferir su terapia directamente a la aplicación gratuita",
    "create.web.summary.button.add": "Sí gustosamente.",
    "create.web.summary.button.cancel": "No, no es necesario",
    "qr.code.title": "Con este código QR puede administrar su medicación directamente en la aplicación. Para ello, escanee el código con la aplicación móvil de TOM:",
    "qr.code.desc": "¿Todavía no tienes la aplicación TOM? Luego instálala gratis usando los enlaces a continuación, luego puedes escanear el código en la aplicación",
    "cabinet.label.navBarTitle": "Armario de medicamentos",
    "cabinet.label.stock": "Reserva",
    "cabinet.label.expiry": "Transcurso",
    "cabinet.label.notSpecified": "Sin datos",
    "cabinet.filter.label.title": "Filtro",
    "cabinet.filter.label.all": "Mostrar todos",
    "cabinet.filter.label.withReminder": "Con recordatorio",
    "cabinet.filter.label.withoutReminder": "Sin recordatorio",
    "cabinet.filter.label.withPrescription": "Venta con receta",
    "cabinet.filter.label.withoutPrescription": "Venta sin receta",
    "cabinet.dialog.sorting.label.title": "Seleccionar clasificación",
    "cabinet.dialog.sorting.label.name.asc": "Nombre {ASC}",
    "cabinet.dialog.sorting.label.name.desc": "Nombre {DESC}",
    "cabinet.dialog.sorting.label.expiryDate.asc": "Fecha de caducidad {ASC}",
    "cabinet.dialog.sorting.label.expiryDate.desc": "Fecha de caducidad {DESC}",
    "cabinet.dialog.sorting.label.stock.asc": "Reserva {ASC}",
    "cabinet.dialog.sorting.label.stock.desc": "Reserva {DESC}",    
    "detail.label.navBarTitle": "Vista general de los medicamentos",
    "detail.label.title": "Tus datos: ",
    "detail.label.editTitle": "Ahora puedes editar la entrada a tu voluntad: ",
    "detail.button.save": "Guardar",
    "detail.button.addReminder": "Añadir recordatorio",
    "detail.dialog.deleteSelection.label.title": "Borrar",
    "detail.dialog.deleteSelection.label.desc": "¿Quieres borrar el medicamento completo o solo el recordatorio?",
    "detail.dialog.deleteSelection.button.med": "Medicamento",
    "detail.dialog.deleteSelection.button.reminder": "Recordatorio",
    "detail.dialog.deleteConfirm.label.title": "¿Borrar de verdad?",
    "detail.dialog.deleteConfirm.label.reminderDesc": "¿Realmente deseas borrar el recordatorio?",
    "detail.dialog.deleteConfirm.label.medDesc": "¿Realmente deseas borrar el medicamento?",
    "detail.dialog.deleteConfirm.button.yes": "Sí, borrar.",
    "detail.dialog.deleteConfirm.button.no": "No, cancelar.",
    "detail.success.label.text": "Las entradas se han actualizado correctamente.",    
    "today.label.next.title": "Siguiente\ntoma en",
    "today.label.next.at": "a las",
    "today.label.taken.title": "Correctamente\ntomado",
    "today.label.taken.at": "el {amount} a las",
    "today.label.skipped.title": "No\ntomado",
    "today.label.skipped.at": "el {amount} a las",
    "today.label.overdue.title": "Toma\ncon retraso desde",
    "today.label.overdue.at": "Hoy a las",
    "today.label.planned.title": "Toma\nplanificada en",
    "today.label.planned.day": "1 día",
    "today.label.planned.days": "{amount} días",
    "today.label.planned.at": "el {amount} a las",
    "today.button.take": "Tomar ahora",
    "today.button.later": "Aplazar",
    "today.button.skip": "Omitir",
    "today.emptyState.label.text": "Hoy no tienes que tomar medicamentos",
    "today.label.clock": "{amount}",
    "today.postponeDialog.label.title": "¿Cuánto deseas aplazar la toma?",
    "today.postponeDialog.label.content": "{amount} minutos",
    "today.postponeDialog.button.later": "Aplazar",
    "today.postponeConfirmDialog.label.title": "Aplazar la toma",
    "today.postponeConfirmDialog.label.desc": "¿Seguro que quieres aplazar la toma {amount} minutos?",
    "today.postponeConfirmDialog.button.later": "Aplazar",
    "today.label.selectDay": "Seleccionar el día",
    "today.intakeInfo.label.confirmed": "Tomado a las {amount} horas",
    "today.intakeInfo.label.postponed": "Aplazado a las {amount} horas",
    "today.intakeInfo.label.overdue": "Con retraso desde las {amount} horas",
    "today.intakeInfo.label.skipped": "Omitido.\n{amount}",
    "today.intakeInfo.label.skippedNoReason": "Omitido.",
    "today.actionSheet.label.takeNow": "Tomar ahora",
    "today.actionSheet.label.takeAllNow": "Tomar ahora todos",
    "today.actionSheet.label.takeOnPoint": "Tomado a las {amount} horas en punto",
    "today.actionSheet.label.takeAllOnPoint": "Todos tomados a las {amount} horas en punto",
    "today.actionSheet.label.takeOtherTime": "Tomado a otra hora",
    "today.actionSheet.label.takeAllOtherTime": "Todos tomados a otra hora",
    "today.actionSheet.label.skip": "Omitir",
    "today.actionSheet.label.skipAll": "Omitir todos",
    "today.actionSheet.label.postpone": "Aplazar",
    "today.actionSheet.label.postponeAll": "Aplazar todos",
    "today.actionSheet.label.titleAll": "Todos los medicamentos a las {amount} horas",
    "today.actionSheet.label.reset": "Restablecer el estado de toma",
    "today.timePicker.label.title": "Fijar la hora",
    "today.listBottomButtons.button.takeAllNow": "Tomar ahora todos",
    "today.listBottomButtons.button.takeAllOnPoint": "Todos tomados a la hora exacta",
    "today.listBottomButtons.button.skipAll": "Omitir todos",
    "today.listBottomButtons.button.takeAllOnOtherPoint": "Todos tomados a otra hora",
    "today.error.batchIntakes": "Lamentablemente no han podido actualizarse todas las tomas. Inténtalo de nuevo más tarde.",    
    "actionSheet.label.new": "Nuevo",    
    "addEntry.label.title": "Añadir entrada",
    "addEntry.label.med": "Añadir medicamento",
    "addEntry.label.mediCheck": "Medi-Check",
    "addEntry.label.qrCodeMed": "Escanea el código web",    
    "invalidCredentials.label.title": "Durante el proceso de autenticación se ha producido un error.",
    "invalidCredentials.label.text": "Vuelve a intentarlo. También puedes escribirnos a support@innovation6.ch e intentaremos ayudarte.",
    "invalidCredentials.button.retry": "Volverlo a intentar",
    "invalidCredentials.button.logout": "Cerrar la sesión",
    "invalidCredentials.logoutConfirm.label.title": "Cerrar la sesión",
    "invalidCredentials.logoutConfirm.label.text": "Con esta opción borras tus datos de este dispositivo.",
    "invalidCredentials.logoutConfirm.button.accept": "Continuar",
    "invalidCredentials.logoutConfirm.button.cancel": "Cancelar",    
    "loadingCurrentUserFailed.label.title": "¡Vaya! Al cargar tus datos se ha producido un error.",
    "loadingCurrentUserFailed.label.text": "Vuelve a intentarlo. También puedes escribirnos a support@innovation6.ch e intentaremos ayudarte.",
    "loadingCurrentUserFailed.button.retry": "Volverlo a intentar",    
    "loadingGTCFailed.label.title": "¡Vaya! Al cargar las Condiciones Generales de Contratación se ha producido un error.",
    "loadingGTCFailed.label.text": "Vuelve a intentarlo. También puedes escribirnos a support@innovation6.ch e intentaremos ayudarte.",
    "loadingGTCFailed.button.retry": "Volverlo a intentar",    
    "defaultError.label.title": "¡Algo ha salido mal!",
    "defaultError.label.text": "Vuelve a intentarlo. También puedes escribirnos a support@innovation6.ch e intentaremos ayudarte.",
    "defaultError.button.retry": "Volverlo a intentar",    
    "confetti.label.title": "¡Enhorabuena!\nHas creado correctamente tu primer medicamento.",
    "confetti.button.next": "Seguir",    
    "more.label.navBarTitle": "Más",
    "more.profile.label.title": "Datos personales",
    "more.profile.label.allergies": "Alergias",
    "more.medicheck.label.title": "Medi-Check",
    "more.medicheck.label.desc": "El Medi-Check es realizado por una farmacia suiza y puede darte información adicional sobre interacciones, doble medicación, etc. De forma cómoda y fácil desde casa.",
    "more.legal.label.title": "Información legal",
    "more.legal.label.agb": "Condiciones de uso",
    "more.legal.label.dsg": "Declaración de protección de datos",
    "more.legal.label.imprint": "Aviso legal",
    "more.legal.label.delete": "Borrar los datos",
    "more.legal.label.messageSupport": "Mensaje al servicio de atención al cliente",
    "more.legal.label.updateNews": "Previsión 2020",
    "more.label.version": "Aviso sobre la versión {amount}",
    "more.settings.label.design.title": "Adaptar el diseño",
    "more.settings.label.design.desc": "Elige entre el diseño estándar de TOM o los diseños prémium con un modo oscuro o el modo color.",
    "more.settings.label.push.title": "Ajustar los recordatorios",
    "more.settings.label.push.desc": "Determina el tipo y la intensidad de los recordatorios y los tonos, y elige si deseas que el nombre de los medicamentos se indique con el recordatorio.",
    "more.settings.label.title": "Ajustes",
    "more.settings.label.desc": "Aquí pueden realizarse otros ajustes sobre los recordatorios y tonos, y puede seleccionarse un diseño distinto.",
    "more.label.userId": "ID de usuario",
    "more.premium.label.title": "Funciones Prémium",
    "more.premium.label.desc": "Con las funciones prémium tendrás muchas ventajas y una mayor flexibilidad con nuevos diseños, tonos, recordatorios y más.",    
    "imprint.label.navBarTitle": "Aviso legal",
    "imprint.label.cooperation": "Innovation 6 AG",
    "imprint.label.address": "Pfäffikerstrasse 109,\n8615 Wermatswil,\nSuiza",
    "imprint.label.uid": "CIF:  CHE-263.807.653",
    "imprint.label.ceo": "Gerente:  Sven Beichler",
    "imprint.label.mail": "Correo electrónico:  support@innovation6.ch",
    "imprint.label.web": "Web:  www.tommedications.com",    
    "revokeData.label.navBarTitle": "Borrar los datos",
    "revokeData.label.title": "¿Realmente quieres borrar todos tus datos? Este proceso no se puede deshacer.",
    "revokeData.button.revoke": "Sí, borrar los datos",
    "revokeData.button.keep": "No, mejor no",
    "revokeData.confirmDialog.label.title": "¿Borrar los datos definitivamente?",
    "revokeData.confirmDialog.label.desc": "¿Realmente quieres borrar todos tus datos ahora?",
    "revokeData.confirmDialog.button.delete": "Sí, borrar definitivamente",
    "revokeData.closeDialog.label.title": "Datos borrados",
    "revokeData.closeDialog.label.desc": "Tus datos se han borrado de manera irreversible. ¡Esperamos volver a verte pronto!",
    "revokeData.closeDialog.button.bye": "¡Hasta luego!",    
    "ommitReason.label.navBarTitle": "Omitir la toma",
    "ommitReason.label.title": "¿Hay un motivo concreto por el que omites el medicamento?",
    "ommitReason.button.noReason": "No añadir nada",
    "ommitReason.label.storageHint": "Tus datos sobre el medicamento omitido se anotarán en tus estadísticas y pueden ofrecer datos importantes a tu médico.",
    "ommitReason.label.reasonHint": "Seleccionar el motivo",    
    "updateDialog.label.title": "Actualización disponible",
    "updateDialog.label.message": "Hay una nueva versión de {amount} disponible.",
    "updateDialog.button.update": "Actualización",    
    "mediCheck.teaser.screen1.label.title": "Medi-Check",
    "mediCheck.teaser.screen1.label.desc": "El Medi-Check comprueba cómoda y fácilmente desde casa si tus medicamentos presentan interacciones y mucho más.",
    "mediCheck.teaser.screen1.label.hint": "Es realizado personalmente por la farmacéutica FPH Anja Bauert de la farmacia TopPharm Apotheke Witikon/ZH",
    "mediCheck.teaser.screen1.button.close": "Cerrar",
    "mediCheck.teaser.screen1.button.learnMore": "Seguir",
    "mediCheck.teaser.screen2.label.title": "El Medi-Check incluye observaciones sobre: ",
    "mediCheck.teaser.screen2.label.desc.full": "\n- Indicaciones\n- Interacciones\n- Dobles medicaciones\n- Indicaciones sobre la toma\n- Avisos en caso de alergias\n\nEnvía tus medicamentos para su comprobación directamente desde TOM.",
    "mediCheck.teaser.screen2.label.desc.highlighted": "- Indicaciones\n- Interacciones\n- Dobles medicaciones\n- Indicaciones sobre la toma\n- Avisos en caso de alergias",
    "mediCheck.teaser.screen2.button.close": "Cerrar",
    "mediCheck.teaser.screen2.button.continue": "Iniciar ahora",
    "mediCheckList.label.navBarTitle": "Medi-Checks anteriores",
    "mediCheckList.label.share": "Compartir Medi-Check",
    "mediCheckList.switch.label.open": "Abierto",
    "mediCheckList.openSection.label.paymentNotCompleted": "Pago no finalizado",
    "mediCheckList.openSection.label.inProgress": "En procesamiento",
    "mediCheckList.openSection.label.inquiry": "Consulta",
    "mediCheckList.switch.label.finished": "Finalizado",
    "mediCheckList.button.startMediCheck": "Iniciar nuevo Medi-Check",
    "mediCheckList.empty.label.open": "En este momento no tienes ningún Medi-Checks pendiente",
    "mediCheckList.empty.label.finished": "Ninguno de tus Medi-Checks se ha finalizado aún. Espera un momento, por favor.",
    "mediCheckList.empty.label.general": "Aún no has creado ningún Medi-Check. Con un clic puedes comprobar tus medicamentos cómodamente desde casa.",
    "mediCheckList.label.transmitted": "Enviado el {amount}",
    "mediCheckList.label.recieved": "Recibido el {amount}",
    "mediCheckCreation.label.navBarTitle": "Medi-Check",
    "mediCheckCreation.medSelection.label.title": "Estos son los medicamentos que tienes en el armario. Selecciona un máximo de seis medicamentos o añade nuevos.",
    "mediCheckCreation.medSelection.label.hintCabinet": "Medicamentos de tu armario",
    "mediCheckCreation.medSelection.label.addMed": "Añadir medicamento",
    "mediCheckCreation.medSelection.button.next": "Seguir",
    "mediCheckCreation.medSelection.error.limitReached": "Ya has seleccionado la cantidad máxima posible de medicamentos.",
    "mediCheckCreation.medSelection.error.sameArticle": "Has seleccionado dos veces el mismo medicamento para el Medi-Check. Cambia tu selección.",
    "mediCheckCreation.medSelection.error.selectMore.label.title": "Has seleccionado una cantidad insuficiente de medicamentos.",
    "mediCheckCreation.medSelection.error.selectMore.label.desc": "Tienes que seleccionar al menos dos medicamentos",
    "mediCheckCreation.dialogQuit.label.title": "Cancelar Medi-Check",
    "mediCheckCreation.dialogQuit.label.desc": "¿Seguro que no quieres realizar el Medi-Check?",
    "mediCheckCreation.dialogQuit.button.continue": "No, seguir",
    "mediCheckCreation.dialogQuit.button.cancel": "Sí, cancelar",
    "mediCheckCreation.allergieSelection.label.title": "¿Tienes alguna alergia?",
    "mediCheckCreation.allergieSelection.label.allergyType.food": "Alergia alimentaria",
    "mediCheckCreation.allergieSelection.label.allergyType.medication": "Alergia medicamentosa",
    "mediCheckCreation.allergieSelection.label.allergyType.other": "Otras alergias",
    "mediCheckCreation.allergieSelection.label.addAllergie": "Añadir otras alergias",
    "mediCheckCreation.allergieSelection.label.noAllergiesSoFar": "Aún no has indicado ninguna alergia. Si tienes alguna alergia es importante que la indiques para que se pueda tener en cuenta en el Medi-Check.",
    "mediCheckCreation.allergieSelection.button.next": "Seguir",
    "mediCheckCreation.allergieSelection.button.nextWithoutAllergies": "Seguir sin indicar las alergias",
    "mediCheckCreation.allergieSelection.error.limitReached": "Ya has seleccionado la cantidad máxima posible de alergias.",
    "mediCheckCreation.summary.label.title": "Resumen de los elementos que has seleccionado para tu Medi-Check",
    "mediCheckCreation.summary.label.allergyType.food": "Alergias alimentarias seleccionadas",
    "mediCheckCreation.summary.label.allergyType.medication": "Alergias medicamentosas seleccionadas",
    "mediCheckCreation.summary.label.allergyType.other": "Otras alergias seleccionadas",
    "mediCheckCreation.summary.label.meds": "Medicamentos seleccionados",
    "mediCheckCreation.summary.label.editMeds": "Editar medicamentos",
    "mediCheckCreation.summary.label.editAllergies": "Editar alergias",
    "mediCheckCreation.summary.label.legalDisclaimer": "Comprendo que el Medi-Check solo tiene en cuenta las alergias y los medicamentos que se han enviado y que no sustituye la consulta con el personal médico o farmacéutico.",
    "mediCheckCreation.summary.button.buy": "Comprar",
    "mediCheckCreation.summary.label.paymentInProgress": "Tu última compra aún se está validando. Por lo tanto, tu compra no ha podido finalizarse. Inténtalo de nuevo más tarde.",
    "mediCheckDetails.label.id": "ID de Medi-Check",
    "mediCheckDetails.label.buyInfo": "Se ha producido un problema al comprar el Medi-Check. Inténtalo de nuevo haciendo clic en «Comprar ahora». Comprobaremos si la última compra se ha realizado correctamente. En caso afirmativo, se utilizará esta y no se iniciará ningún otro proceso de compra.",
    "mediCheckDetails.button.buy": "Comprar ahora",
    "mediCheckDetails.label.pharmacyInquiry": "La farmacia tiene preguntas sobre tu Medi-Check. Ponte en contacto con la farmacia lo antes posible.",
    "mediCheckDetails.label.pharmacyInquiry.info": "Mientras la pregunta esté sin resolver, no se puede finalizar tu Medi-Check. Ponte en contacto rápidamente con la farmacia.",
    "mediCheckDetails.label.pharmacyInquiry.question": "PREGUNTA: \n\"{amount}\" ",
    "mediCheckDetails.label.pharmacyInfo.name": "Farmacia TopPharm Apotheke Witikon",
    "mediCheckDetails.label.pharmacyInfo.address": "Carl Spitteler-Strasse 2\n8053 Zúrich",
    "mediCheckDetails.label.pharmacyInfo.website": "https: //www.apotheke.ch/witikon",
    "mediCheckDetails.label.pharmacyInfo.mail": "info@witikon.apotheke.ch",
    "mediCheckDetails.label.payment.in.progress": "Tu última compra aún se está validando. Es posible que la finalización de la nueva compra tarde algunos minutos.",    
    "pharmacy.overview.label.pharmacy": "Farmacia",    
    "allergies.label.navBarTitle": "Mis alergias",
    "allergies.empty.label.title": "¿Tienes alguna o varias alergias medicamentosas, alimentarias o de otro tipo?",
    "allergies.empty.button.add": "Añadir alergia",
    "allergies.label.title": "Las alergias que ya has indicado: ",
    "allergies.button.add": "Añadir alergia",
    "allergies.button.save": "Guardar",
    "allergies.label.allergyType.food": "Alergia alimentaria",
    "allergies.label.allergyType.medication": "Alergia medicamentosa",
    "allergies.label.allergyType.other": "Otras alergias",
    "allergies.addDialog.label.title": "Añadir alergia",
    "allergies.search.label.title": "Introduce el nombre de tus alergias: ",
    "allergies.textInput.label.title": "Introduce el nombre de tus alergias: ",
    "allergies.search.label.medHint": "Alergias medicamentosas y a excipientes",
    "allergies.search.label.searchFieldHint": "Buscar alergia",
    "allergies.textInput.label.foodHint": "Alergia alimentaria",
    "allergies.textInput.label.otherHint": "Otras alergias",
    "allergies.textInput.label.textFieldHint": "Introducir alergia",
    "allergies.textInput.button.confirm": "Aceptar",    
    "premium.navBar.title": "Prémium",
    "premium.label.tag": "Prémium",
    "premium.label.tagActive": "Prémium activo",
    "premium.label.title": "¿Quieres algo más? Con las funciones prémium de TOM podrás disfrutar de muchas ventajas: ",
    "premium.label.alarmSettings.title": "Ajustes de alarma",
    "premium.label.alarmSettings.desc": "Elige la alarma entre distintas opciones:  a la hora en punto, antes, después o todas.",
    "premium.label.ringtoneChoice.title": "Selección de tono",
    "premium.label.ringtoneChoice.desc": "Tienes a tu disposición hasta 20 tonos distintos, además del tono estándar.",
    "premium.label.design.title": "Diseño",
    "premium.label.design.desc": "Si prefieres más oscuridad, elige el modo oscuro o el modo color para un mayor colorido. Elige entre tres diseños.",
    "premium.label.cabinet.title": "Armario",
    "premium.label.cabinet.desc": "¿Demasiados medicamentos en el armario? Con la nueva vista de lista te ofrecemos una visión general para que todo esté más organizado.",
    "premium.label.sorting.title": "Ordenar",
    "premium.label.sorting.desc": "También puedes ordenar tu armario de medicamentos por reservas y por fecha de caducidad.",
    "premium.label.subscriptionModels": "Modelos de suscripción",
    "premium.label.monthly.title": "Suscripción mensual",
    "premium.label.monthly.desc": "Se puede cancelar en cualquier momento hasta final de mes.",
    "premium.label.yearly.title": "Suscripción anual",
    "premium.label.yearly.desc": "Unser Besteller:  ¡ahorra 25%s!",
    "premium.button.subscribeNow": "Formalizar la suscripción",
    "premium.label.withClickOnButton": "Haz clic en el botón y te transferiremos al fabricante",
    "premium.label.manufacturer": "fabricante",
    "premium.label.automaticRenew": "La suscripción se prorrogará automáticamente en un plazo de 24 horas previas al vencimiento de la suscripción actual",
    "premium.label.within24Hours": "en un plazo de 24 horas",
    "premium.label.privacyAndTerms": "Mediante la formalización de una suscripción aceptas nuestras <a href=\"{amount}\">Condiciones de uso</a> y nuestra <a href=\"{amount}\">Declaración de protección de datos</a>",
    "premium.label.termsOfUse": "Condiciones de uso",
    "premium.label.privacyPolicy": "Declaración de protección de datos",    
    "pushSettings.label.navBarTitle": "Ajustar el recordatorio",
    "pushSettings.label.standard": "Ajustes estándar",
    "pushSettings.label.premiumSettings": "Ajustes prémium",
    "pushSettings.label.title": "Aquí puedes ajustar cómo quieres que te recuerde tu toma.",
    "pushSettings.label.nowBecomeMember": "Jetzt Premium-Member werden und viele Vorteile geniessen!",
    "pushSettings.label.sendReminders.title": "Enviar recordatorio",
    "pushSettings.label.sendNoReminders.desc.inactive": "Si ya no quieres recibir los recordatorios de TOM, deberías activar esta función.",
    "pushSettings.label.sendNoReminders.desc.active": "Si quieres recibir los recordatorios de TOM siempre, deberías desactivar esta función.",
    "pushSettings.label.hideMedicamentName.title": "Ocultar el nombre del medicamento",
    "pushSettings.label.hideMedicamentName.desc.active": "Si quieres ver el nombre del medicamento en tus mensajes de notificación instantánea, deberías desactivar esta función.",
    "pushSettings.label.hideMedicamentName.desc.inactive": "Si quieres ver el nombre del medicamento en tus mensajes de notificación instantánea, deberías desactivar esta función.",
    "pushSettings.label.notificationWithoutSound.title": "Notificación con vibración",
    "pushSettings.label.notificationWithoutSound.desc.inactive": "Si quieres recibir los recordatorios sin tono, deberías activar esta función.",
    "pushSettings.label.notificationWithoutSound.desc.active": "Si deseas recibir los recordatorios siempre con tono, deberías desactivar esta función. Asegúrate de que tu teléfono no está en silencio.",
    "pushSettings.label.changeTone.title": "Cambiar el tono de la notificación",
    "pushSettings.label.changeTone.desc.active": "Selección de un tono individual de notificación",
    "pushSettings.label.changeTone.desc.inactive": "Selección de un tono individual de notificación",
    "pushSettings.label.intensity.title": "Adaptar la intensidad de la notificación",
    "pushSettings.label.intensity.desc.active": "Aquí puedes ajustar con cuánta intensidad quieres que se te recuerde tu toma",
    "pushSettings.label.intensity.desc.inactive": "Aquí puedes ajustar con cuánta intensidad quieres que se te recuerde tu toma",
    "pushSettings.label.premium": "Prémium",
    "pushSettings.label.more": "Mehr",
    "pushSettings.label.premiumActive": "Prémium activo",
    "pushSettings.label.onSpecifiedTime.title": "A la hora indicada",
    "pushSettings.label.onSpecifiedTime.desc.active": "El recordatorio se realizará a la hora indicada",
    "pushSettings.label.onSpecifiedTime.desc.inactive": "No se realizará el recordatorio a la hora de recordatorio indicada.",
    "pushSettings.label.before.title": "Antes",
    "pushSettings.label.before.desc.active": "Se enviará un recordatorio adicional 10 minutos antes de la hora exacta de la toma.",
    "pushSettings.label.before.desc.inactive": "No se enviará ningún recordatorio adicional antes de la hora exacta de la toma.",
    "pushSettings.label.before.placeholder": "Antes",
    "pushSettings.label.after.title": "Después",
    "pushSettings.dialog.timeSelection.label.title": "Selección de la hora",
    "pushSettings.label.after.desc.active": "Se enviará un recordatorio adicional 10 minutos después de la hora exacta de la toma.",
    "pushSettings.label.after.desc.inactive": "No se enviará ningún recordatorio adicional después de la hora exacta de la toma.",
    "pushSettings.label.after.placeholder": "Después",
    "pushSettings.label.pushSound": "Tono de la notificación",
    "pushSettings.dialog.pushSound.label.title": "Elige tu tono",
    "pushSettings.label.soundFile.gooseWalk": "Marcha del ganso",
    "pushSettings.label.soundFile.majorBlues.140": "Blues",
    "pushSettings.label.soundFile.majorBlues.90": "Estoy bien.",
    "pushSettings.label.soundFile.bell": "Pequeñas campanas",
    "pushSettings.label.soundFile.ringing": "Campana nerviosa",
    "pushSettings.label.soundFile.dark.ringing": "Taaa!",
    "pushSettings.label.soundFile.bright.ringing": "Guiño",
    "pushSettings.label.soundFile.cowbells": "Romper la campana",
    "pushSettings.label.soundFile.techno": "Techno",
    "pushSettings.label.soundFile.jazzTrumpets": "Trompetas de Jazz",
    "pushSettings.label.soundFile.mandolin": "Mandolina",
    "pushSettings.label.soundFile.orchestraString": "Beethoven",
    "pushSettings.label.soundFile.birds": "Canto de pájaros",
    "pushSettings.label.soundFile.orchestraTrumpets": "Trompetas de orquesta",
    "pushSettings.label.soundFile.pianoSurprise": "Piano como este",
    "pushSettings.label.soundFile.pianoFastAsLightning": "Piano con una diferencia",
    "pushSettings.label.soundFile.retro": "Ondas retro",
    "pushSettings.label.soundFile.scienceFiction": "Ciencia Ficción",
    "pushSettings.label.soundFile.digitalPeep": "Tutto digitale",
    "pushSettings.label.soundFile.trumpet": "Trompeta de estilo libre",    
    "customTone.navBar.title": "Tonos de notificación",    
    "design.label.navBarTitle": "Adaptar el diseño",
    "design.label.title": "Si te gusta ver cosas nuevas de vez en cuando, puedes cambiar la apariencia de la aplicación.",
    "design.label.modeDark": "Dark-Mode",
    "design.label.modeLight": "Modo claro",
    "design.label.modeColor": "Color-Mode",
    "design.selection.light.label.title": "Modo claro (estándar)",
    "design.selection.light.label.desc": "Nuestro modo estándar claro:  elegante, intemporal y gratis para siempre para todos los usuarios de TOM.",
    "design.selection.dark.label.title": "Dark-Mode",
    "design.selection.dark.label.desc": "¿Quieres proteger la batería y, al mismo tiempo, tu vista? Con el modo oscuro de TOM es posible.",
    "design.selection.color.label.title": "Color-Mode",
    "design.selection.color.label.desc": "Para quienes nunca tienen suficiente color, TOM también brilla con otros colores.",
    "design.label.premiumTag": "Prémium",
    "design.label.getPremium": "Jetzt Premium-Member werden und viele Vorteile genießen!",
    "design.button.getPremium": "Mehr",
    "design.button.save": "Guardar",    
    "restoreIAP.label.title": "Si has vuelto a instalar la aplicación, puedes restablecer tus compras aquí.",
    "restoreIAP.button.restore": "Restablecer las compras",
    "restoreIAP.error.label.title": "El restablecimiento ha fallado",
    "restoreIAP.error.label.desc": "Se ha producido un error al restablecer tus compras. Inténtalo de nuevo más tarde.",
    "restoreIAP.label.nothingToRestore": "No hay compras para restablecer.",
    "restoreIAP.label.restoreSuccess": "Las compras se han restablecido correctamente.",    
    "qrCodeMed.label.navBarTitel": "Añadir medicamento",
    "qrCodeMed.label.title": "Ahora puedes escanear fácilmente el código QR del tratamiento que has creado en tommedications.com.",
    "qrCodeMed.label.desc": "¿Aún no has creado ningún tratamiento? En www.tommedications.com, haz clic en \"Registrar tratamiento\".",
    "qrCodeMed.label.descHighlight": "www.tommedications.com",
    "qrCodeMed.button.scan": "Escanear el código QR ahora.",
    "qrCodeMed.scan.label.title": "Ahora apunta tu smartphone al código QR del sitio web tommedications.com",
    "qrCodeMed.successDialog.label.title": "Medicamento creado.",
    "qrCodeMed.successDialog.label.desc": "Tu medicamento se ha creado correctamente.",
    "qrCodeMed.errorDialog.label.title": "Error al crear.",
    "qrCodeMed.errorDialog.label.desc": "No ha podido crearse tu medicamento."        
}
export default es