import React, { FunctionComponent } from 'react'
import { Container, Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Header from './header'
import Alert from './alert'
import { withRouter } from 'react-router-dom'
import { Location } from 'history'
import { history } from '../../state/store'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import { FormType, FormValuesState, AppState } from '../../state/definition'
import FormPage from '../pages/formPage'
import '../../App.scss'
import { useSelector } from 'react-redux'

const useStyles =  makeStyles((theme: Theme) => ({
    container: {
        overflow: 'hidden'
    }
}))

const BaseLayout: FunctionComponent<{ location: Location }> = (props) => {
    const classes = useStyles()
    const { location } = props

    const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)

    return (
        <Container maxWidth="lg" component="main" className={classes.container}>
            <Alert />
            <Header/>
            <Box marginTop={7} style={{ overflow: 'hidden' }}>
                <TransitionGroup className="transition-group" exit={false}>
                    <CSSTransition
                        key={location.key}
                        timeout={{ enter: 1000, exit: 0 }} 
                        appear
                        in
                        classNames={`${history.action === 'POP' ? 'swipe-bottom' : 'swipe-top' }`}
                        mountOnEnter={true}
                        unmountOnExit={true}         
                    >
                        <Switch location={location}>
                            <Route exact path="/" >
                                <FormPage path={FormType.SEARCH_FORM}/>
                            </Route>
                            {!formValuesState.article && location.pathname !== `${FormType.SEARCH_FORM}` &&
                                <Redirect to={`${FormType.SEARCH_FORM}`} /> 
                            }
                            <Route path="/:formId" component={FormPage} />          
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </Box>                    
        </Container>
    )
}

export default withRouter(BaseLayout)