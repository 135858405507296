import { RouterState } from 'connected-react-router'

export interface AppState { 
    router: RouterState
    formValues: FormValuesState
    search: SearchState
    unit: UnitState
    common: CommonState
}

export interface SearchState {
    query?: string
    result?: SearchResult
}

export interface SearchResult {
    articles: MedicineArticle[] | null
    currentPage: number
    totalElements: number
    totalPages: number  
}

export interface UnitState {
    unitList: Array<MedicineUnit>
}

export enum FormType {
    SEARCH_FORM = '/',
    REMINDER_SELECTION_FORM = 'reminder',
    DATE_TIME_QUANTITY_UNIT_FORM = 'dateTimeQuantityUnit',
    TIME_REMINDER_OVERVIEW_FORM = 'timeReminderOverview',
    WEEKDAY_INTERVAL_FORM = 'weekdays',
    DAY_INTERVAL_FORM = 'dayInterval',
    HOUR_INTERVAL_FORM = 'hourInterval',
    THERAPY_DURATION_FORM = 'therapyDuration',    
    AMOUNT_FORM = 'amount',
    INTAKE_RECOMMENDATION_FORM = 'intakeRecommendation',
    OVERVIEW = 'overview',
    QR_CODE = 'confirm'
}

export enum ReminderType {
    DAILY = 'DAILY',
    WEEKDAY = 'WEEKDAY',
    DAILY_INTERVAL = 'DAILY_INTERVAL',
    HOUR_INTERVAL = 'HOUR_INTERVAL',
    ONCE = 'ONCE'
}

export enum IntakeRecommendationType {
    BEFORE_MEAL = 'BEFORE_MEAL',
    DURING_MEAL = 'DURING_MEAL',
    AFTER_MEAL = 'AFTER_MEAL',
    NONE = 'NONE'
}

export enum Weekday {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export enum TherapyDurationType {
    CONTINUOUS = 'CONTINUOUS',
    END_DATE  = 'END_DATE'
}

export interface FormValuesState {
    article?: MedicineArticle | string
    reminderType?: ReminderType
    idCounter?: number
    dateTimeQuantityUnits?: Array<DateTimeQuantityUnit>
    dayInteval?: number
    weekdays?: Array<Weekday>
    therapyDuration?: {        
        durationType: TherapyDurationType
        startDate: Date
        endDate?: Date
        durationInDays?: number
    }
    amount?: number
    intakeRecommendation?: IntakeRecommendationType
    note?: string
    qrCodeValues?: string
}

export interface DateTimeQuantityUnit {
    id: number
    date?: Date 
    timeOfDayStart?: Date
    timeOfDayEnd?: Date
    hourInterval?: number
    unit?: string
    quantity?: number
}

export interface CommonState {
    loading: boolean
    messages: { [id: string]: Message }
}

export interface Message {
    id: string
    title?: string
    description?: string
    data?: any
    timestamp: number
    timeout?: number
}

/**
 * Tom Medicheck DTOs
 */
export interface MediCheckDetail {
    allergies: string | null
    created: string
    doubleMedications: string | null
    id: string
    intakeDirections: string | null
    inquiry: string | null
    /** e.g "DE" */
    language: string
    /** e.g. "CH" */
    location: string
    medicineCheckId: string
    medicineInteractions: string | null
    modified: string
    name: string
    /** e.g. "PAID" */
    paymentStatus: string
    /** e.g. "UNPROCESSED" */
    polyMedicationList: Array<PolyMedication> | null
    status: string
    userAllergyList: UserAllergy[] | null
    userId: number
    userMedicineCabinetArticleList: UserMedicineCabinetArticle[] | null
}

export interface InternationalName {
    de?: string,    
    en?: string,
    fr?: string,
    it?: string
    es?: string
}

export interface PolyMedication {    
        articleId: string | null,
        medicineCabinetArticleId: string,
        text: string      
}

export type BarcodeType =  'GTIN8' | 'GTIN12' | 'GTIN13' | 'GTIN14'

export enum UserAllergyType {
    FOOD = 'FOOD',
    SUBSTANCE = 'SUBSTANCE',
    OTHER = 'OTHER'
}

export type UserAllergy = UserAllergyFood | UserAllergySubstance

export enum ImagePriorityType {
    PICFRONT3D = 'PICFRONT3D',
    PICFRONT3DPLUS = 'PICFRONT3DPLUS',
    PICFRONT = 'PICFRONT'
}

export interface UserAllergyFood {
    allergyType: UserAllergyType.FOOD | UserAllergyType.OTHER
    id: string | null
    name: string
    substance: null
    userId: number | null
}

export interface UserAllergySubstance {
    allergyType: UserAllergyType.SUBSTANCE
    id: string
    name: null
    substance: MedicineSubstance
    userId: number
}

export interface MedicineSubstance {
    id: string,
    substanceNumber: number,
    name: InternationalName,
    latinName: string,
    importFrom: string,
    validInCountries: string[] // TLD
}

export interface MedicineUnit {
    dataType: string // "double"
    id: number
    maxValue: number
    minValue: number
    name: InternationalName
    step: number
    unitType?: string // "PIECE"
}

export interface MedicineProduct {
    compendiumDocumentNumber: number
    components: Array<any> | null
    dosage: number | null
    dosageUnit: string | null
    name: InternationalName,
    productNumber: number
}

export interface MedicineArticle {
    articleNumber: string // "4129794"
    articleType: string // "M9"
    barcode: string // "7640110460351"
    barcodeType: BarcodeType // "GTIN13"
    id: string
    imageTypes: Array<string>
    imageURLTemplate: string //  "https://index.hcisolutions.ch/files/pics/4129794_<__IMAGETYPE__>_<__IMAGESIZE__>.jpg?key=pharmacode&px=<__PIXEL__>"
    importFrom: string // "HCI"
    name: InternationalName
    prescriptionOnly: false
    product: MedicineProduct | null
    quantity: number
    unit: MedicineUnit | null
}

/** a user defined CabinetArticle must have a name */
export interface UserMedicineCabinetArticle {
    article: MedicineArticle | null
    expirationDate: string | null
    id: string
    lifecycle: string // "ACTIVE"
    medicineCabinetId: string
    name: string |  null
    notes: string | null
    reminderSet: boolean | null
    showAllergyWarning: boolean | null
    showExpirationWarning: boolean | null
    showReorderWarning: boolean | null
    stock: string | null
    unit: string | null
    userId: number | null
}

export interface PaginatedResponse<T> {
    content: Array<T>
    currentPage: number
    pageSize: number
    totalElements: number
    totalPages: number    
}

