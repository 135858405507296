/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { FormType, AppState, FormValuesState, UnitState, MedicineArticle, DateTimeQuantityUnit } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded'
import { Button, Typography, Theme, makeStyles, Grid, InputAdornment, FormControl, InputLabel, Select, MenuItem, FilledInput, Collapse, DialogTitle, Dialog, Box, DialogContent, DialogActions } from '@material-ui/core'
import { FormWizardActions, FormValuesActions, CommonActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { TimePicker } from '@material-ui/pickers'
import { set, format, getHours, addMinutes, isBefore, isAfter } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { goBack, push } from 'connected-react-router'
import { endOfDay } from 'date-fns/esm'

const useStyles =  makeStyles((theme: Theme) => ({ 
    formControl: {
        '.svg': {
            margin: 5, 
            fill: theme.colors.lightBlue
        },
        '& input[type=number]': {
            '&::-webkit-inner-spin-button': {
                opacity: 1,
                height: '35px',
                width: '35px',
                marginRight: '5px',
                background: theme.colors.purpleLight5
            }
        }
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    cardIcon: {
        backgroundColor: theme.colors.purpleLight5,
        borderRadius: '7px',
        marginRight: '12px'
    },
    dialog: {
        '& h2': {
            textTransform: 'uppercase',
            color: theme.colors.purple,
            textAlign: 'center'
        }, 
        '& h5': {
            fontSize: '20px'
        }
    },
}))

const hourIntervalForm: Form = {
    formType: FormType.HOUR_INTERVAL_FORM,
    title: () => <FormattedMessage id='create.hoursReminder.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const intl = useIntl()
        const location = useLocation()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const unitState: UnitState = useSelector((state: AppState) => state.unit)

        const locale = intl.locale.split('-')[0]
        let selectedArticleUnitType: string 
        const article: MedicineArticle = formValuesState.article as MedicineArticle
        if (article instanceof Object && article.unit) {
            selectedArticleUnitType = article.unit.unitType
        } else {
            selectedArticleUnitType = unitState.unitList && unitState.unitList[0].unitType
        }

        let hoursIntervalModel = []
        let endHoursIntervalModel = [] 
        let defaultId = formValuesState.idCounter
        let defaultHourInterval = undefined
        let defaultStartTime = set(new Date(), { hours: 8, minutes: 0, seconds: 0 })
        let defaultEndTime = undefined 
        let defaultUnit = selectedArticleUnitType
        let defaultQuantity = 1

        if (formValuesState && formValuesState.dateTimeQuantityUnits.length !== 0) {
            const updateReminder: DateTimeQuantityUnit = formValuesState.dateTimeQuantityUnits[0]
            const timeOfDayEnd = updateReminder.timeOfDayEnd 
                ? format(updateReminder.timeOfDayEnd, 'HH:mm') 
                : undefined
                
            defaultId = updateReminder.id
            defaultHourInterval = updateReminder.hourInterval
            defaultStartTime = updateReminder.timeOfDayStart
            defaultEndTime = timeOfDayEnd
            defaultUnit = updateReminder.unit
            defaultQuantity = updateReminder.quantity
        }

        const [hourInterval, setHourInterval] = useState<number | undefined>(defaultHourInterval)
        const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(defaultStartTime)
        const [selectedEndTime, setSelectedEndTime] = useState<string | null>(defaultEndTime)
        const [unit, setUnit] = useState<string>(defaultUnit)
        const [quantity, setQuantity] = useState<number>(defaultQuantity)
        const [openDialog, setOpenDialog] = React.useState<boolean>(false)

        useEffect(() => {
            if (hourInterval && selectedStartTime) {    
                if (isAfter(addMinutes(selectedStartTime, hourInterval*60), endOfDay(new Date()))) {
                    dispatch(CommonActions.setMessage(
                        'ALERT', 
                        'create.hoursReminder.errorDialog.startDateInvalid.title',
                        'create.hoursReminder.errorDialog.startDateInvalid.desc', 
                        4000, 
                        { type: 'warning' })
                    ) 
                    if (selectedEndTime) {
                        setSelectedEndTime(undefined)
                    }
                }                       
            }
        }, [hourInterval, selectedStartTime, dispatch, selectedEndTime]) 

        const dateTimeQuantityUnit: DateTimeQuantityUnit = {
            id: defaultId,
            date: undefined, 
            timeOfDayStart: selectedStartTime,
            timeOfDayEnd: selectedEndTime ? set(new Date(), { hours: parseInt(selectedEndTime.split(':')[0]), minutes: parseInt(selectedEndTime.split(':')[1]), seconds: 0 }) : undefined,
            hourInterval: hourInterval,
            unit: unit,
            quantity: quantity
        }

        for (let interval = 0.5; interval <= 12.5; interval += 0.5) {
            hoursIntervalModel.push(interval) 
        }        

        const unitMinValue = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.minValue) || 1
        const unitMaxValue = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.maxValue) || 999
        const unitStep = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.step) || 1

        if (hourInterval && selectedStartTime) {
            let startTime: Date = selectedStartTime

            for (let hour = getHours(selectedStartTime); hour < 24; hour += hourInterval) {
                if (isBefore(addMinutes(startTime, hourInterval*60), endOfDay(new Date()))) {
                    endHoursIntervalModel.push(format(addMinutes(startTime, hourInterval*60), 'HH:mm'))
                    startTime = addMinutes(startTime, hourInterval*60)
                }              
            }

            if (hourInterval && selectedEndTime && endHoursIntervalModel.length > 0 ) {
                const endTime: Date = set(new Date(), { hours: parseInt(selectedEndTime.split(':')[0]), minutes: parseInt(selectedEndTime.split(':')[1]), seconds: 0 })
                const minIntervalValue: Date = set(new Date(), { hours: parseInt(endHoursIntervalModel[0].split(':')[0]), minutes: parseInt(endHoursIntervalModel[0].split(':')[1]), seconds: 0 })

                if (isBefore(endTime, minIntervalValue)) {
                    setSelectedEndTime(format(minIntervalValue, 'HH:mm'))
                }
            }
        }

        const handleHourIntervalSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (Number(event.target.value) !== hourInterval) {
                setSelectedEndTime(undefined)
            }
            setHourInterval(Number(event.target.value))
        }

        const handleSelectedEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedEndTime(event.target.value)
        }

        const handleUnitSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (unit !== event.target.value) {
                setQuantity(1)
            }
            setUnit(event.target.value)
        }

        const handleQuantitySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (Number(event.target.value) < unitMinValue ) {
                setQuantity(unitMinValue)
            } else if (Number(event.target.value) > unitMaxValue ) {
                setQuantity(unitMaxValue)
            } else {
                setQuantity(Number(event.target.value))
            }
        }

        const handleForwardAction = () => {
            if ((formValuesState && formValuesState.dateTimeQuantityUnits.length !== 0) || (location.state && location.state.fromOverview)) {
                dispatch(FormValuesActions.updateReminderTime(dateTimeQuantityUnit, dateTimeQuantityUnit.id))
            } else {
                dispatch(FormValuesActions.addReminderTime(dateTimeQuantityUnit)) 
            }

            if (formValuesState.dateTimeQuantityUnits.length > 0 && location.state && location.state.fromOverview) {
                if (formValuesState.dateTimeQuantityUnits[0].unit !== dateTimeQuantityUnit.unit) {
                    setOpenDialog(true)
                    return
                }
            }
            
            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        const renderAlertDialog = () => {
            return (
                <Dialog
                    aria-labelledby="alert-dialog-hourInterval"
                    className={classes.dialog} 
                    open={openDialog}
                >
                    <DialogTitle id="alert-dialog-hourInterval">
                        <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.label.title' /> 
                    </DialogTitle>
                    <DialogContent 
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box m={4}>
                            <Typography variant="h5" align="center">
                                <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.label.desc' />
                            </Typography> 
                        </Box>                                                   
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button 
                            onClick={() => {
                                dispatch(push({ pathname: `/${FormType.AMOUNT_FORM}`, state: { fromReminderOverview: location } }))
                                dispatch(FormValuesActions.setAmount(null)) 
                                setOpenDialog(false)
                            }} 
                            color="primary"
                            variant="contained"
                            disableRipple
                        >
                            <Typography variant="body2">
                                <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.button.change' />
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >       
                <Grid item>
                    <FormControl 
                        variant="filled" 
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="select-hour-interval-label">
                            <FormattedMessage id={`create.hoursReminder.button.hours.title`} /> 
                        </InputLabel>
                        <Select
                            labelId="select-hour-interval-label"
                            id="select-hour-interval"
                            fullWidth
                            disableUnderline
                            value={hourInterval || ''}
                            onChange={handleHourIntervalSelect}
                        >
                            {hoursIntervalModel.length > 0 && hoursIntervalModel.map((inteval, i) => (
                                <MenuItem 
                                    key={i} 
                                    defaultValue=" "
                                    value={inteval}
                                >
                                    {inteval}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>          
                <Grid item>
                    <Collapse 
                        in={!!hourInterval} 
                        timeout={300}
                    >
                        <TimePicker
                            label={<FormattedMessage id="create.hoursReminder.button.timeStart.title" />}
                            ampm={false}
                            openTo="hours"
                            format="HH:mm"
                            cancelLabel={<FormattedMessage id="general.label.cancel" />}
                            okLabel={<FormattedMessage id="general.label.confirm" />}
                            value={selectedStartTime}
                            inputVariant="filled"
                            orientation="portrait"
                            onChange={(startTime) => {
                                setSelectedStartTime(startTime)
                                setSelectedEndTime(undefined)
                            }}
                            InputProps={{
                                disableUnderline: true,
                                fullWidth: true,
                                endAdornment: (
                                    <InputAdornment position="end"> 
                                        <ScheduleRoundedIcon
                                            fontSize="small"  
                                            classes={{ root: classes.cardIcon }}
                                            style={{ 
                                                marginRight: '0px', 
                                                padding: '3px'
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Collapse>
                </Grid> 
                <Grid item>
                    <Collapse 
                        in={!!hourInterval} 
                        timeout={300}
                    >
                        <FormControl 
                            variant="filled" 
                            className={classes.formControl}
                            // disabled={isAfter(addMinutes(selectedStartTime, hourInterval*60), endOfDay(new Date()))}
                        >
                            <InputLabel htmlFor="select-end-interval-label">
                                <FormattedMessage id={`create.hoursReminder.button.timeEnd.title`} /> 
                            </InputLabel>
                            <Select
                                labelId="select-end-interval-label"
                                id="select-end-interval"
                                fullWidth
                                disableUnderline
                                value={selectedEndTime || ''}
                                onChange={handleSelectedEndTime}
                            >
                                {endHoursIntervalModel.length > 0 && endHoursIntervalModel.map((time, i) => (
                                    <MenuItem 
                                        key={i} 
                                        defaultValue=" "
                                        value={time}
                                    >
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Collapse>
                </Grid> 
                <Grid item>
                    <Collapse 
                        in={!!hourInterval} 
                        timeout={300}
                    >
                        <FormControl 
                            variant="filled" 
                            className={classes.formControl}
                        >
                            <InputLabel htmlFor="select-unit_label">
                                <FormattedMessage id={`create.hoursReminder.button.unit.title`} /> 
                            </InputLabel>
                            <Select
                                labelId="select-unit-label"
                                id="select-unit"
                                fullWidth
                                disableUnderline
                                value={unit}
                                onChange={handleUnitSelect}
                            >
                                {unitState && unitState.unitList && 
                                    unitState.unitList.map(unit => (
                                        <MenuItem 
                                            key={unit.id} 
                                            value={unit.unitType}
                                        >
                                            {unit.name[`${locale}`]}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Collapse>
                </Grid>  
                <Grid item>
                    <Collapse 
                        in={!!hourInterval} 
                        timeout={300}
                    >
                        <FormControl 
                            variant="filled" 
                            className={classes.formControl}
                        >
                            <InputLabel htmlFor="select-quantity-label">
                                <FormattedMessage id={`create.hoursReminder.button.quantitiy.title`} /> 
                            </InputLabel>
                            <FilledInput
                                id="select-quantity-label"
                                name="select-quantity"
                                fullWidth
                                disableUnderline
                                type="number"
                                onKeyPress={e => e.preventDefault()}
                                inputProps={{
                                    min: unitMinValue,
                                    max: unitMaxValue,
                                    step: unitStep
                                }} 
                                value={quantity}
                                onChange={handleQuantitySelect}
                            />
                        </FormControl>
                    </Collapse>
                </Grid>  
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick = {handleForwardAction}
                        disabled={!selectedEndTime || !hourInterval}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.hoursReminder.button.next' />
                        </Typography>
                    </Button>
                </Grid>
                {renderAlertDialog()}
            </Grid>
        )
    }
}

export default hourIntervalForm