/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { FormType, AppState, DateTimeQuantityUnit, UnitState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, makeStyles, Theme, Typography, Grid, Card, CardContent, Dialog, DialogActions, DialogTitle, DialogContent, Box } from '@material-ui/core'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { push, goBack } from 'connected-react-router'
import { format, getHours, getMinutes } from 'date-fns'

const useStyles =  makeStyles((theme: Theme) => ({
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    card: {
        alignItems: 'center',
        padding: '5px',
        cursor: 'initial', 
        '& h5 > span': {
            color: theme.colors.purpleLight4,
            fontSize: '8px',
            margin: '0 5px'
        }
    },
    iconWrapper: {
        backgroundColor: theme.colors.purpleLight5,
        color: theme.colors.purpleLight3,
        borderRadius: '7px',
        marginRight: '15px',
        padding: '4px',
        cursor: 'pointer'
    },
    dialog: {
        '& h2': {
            textTransform: 'uppercase',
            color: theme.colors.purple,
            textAlign: 'center'
        }, 
        '& h5': {
            fontSize: '20px'
        }
    },
}))

const timeReminderOverviewForm: Form = {
    formType: FormType.TIME_REMINDER_OVERVIEW_FORM,
    title: () => <FormattedMessage id='create.dailyReminderList.label.createFirst.title' />,
    formContent: () => {
        const classes = useStyles()
        const intl = useIntl()
        const dispatch = useDispatch()
        const location = useLocation()

        const dateTimeQuantityUnits: Array<DateTimeQuantityUnit> = useSelector((state: AppState) => state.formValues.dateTimeQuantityUnits)
        const unitState: UnitState = useSelector((state: AppState) => state.unit)
        const locale = intl.locale.split('-')[0]

        const [openDialog, setOpenDialog] = React.useState<boolean>(false)
        const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false)
        const [reminderIdToDelete, setReminderIdToDelete] = React.useState<number | null>(null)

        const handleDeleteReminder = (id: number) => {
            setReminderIdToDelete(id)
            setOpenDeleteDialog(true)
        }

        const handleRouteForward = () => {
            if (dateTimeQuantityUnits.length > 0 || (location.state && location.state.fromOverview)) {
                const selectedUnit = dateTimeQuantityUnits[0] && dateTimeQuantityUnits[0].unit
                const isUnitTypeDifferent = dateTimeQuantityUnits.some(element => 
                    element.unit !== selectedUnit
                )
                if (isUnitTypeDifferent) {
                    setOpenDialog(true)
                    return 
                }                
            } 

            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            }            
        }

        const renderAlertDialog = () => {
            const timeToRemove = reminderIdToDelete && dateTimeQuantityUnits.find(element => element.id === reminderIdToDelete).timeOfDayStart

            return (
                <>
                    {reminderIdToDelete && 
                        <Dialog 
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.dialog} 
                            onClose={() => {
                                setOpenDeleteDialog(false)
                                setReminderIdToDelete(null)
                            }} 
                            open={openDeleteDialog}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <FormattedMessage id='create.summary.dialog.notes.label.title' /> 
                            </DialogTitle>
                            <DialogContent 
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box m={4}>
                                    <Typography variant="h5" align="center">
                                        <FormattedMessage id='create.dailyReminderList.dialog.delete.label.desc' values={{ amount: format(timeToRemove, 'HH:mm') }}/> 
                                    </Typography> 
                                </Box>                                                   
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={() => {
                                        setOpenDeleteDialog(false)
                                        setReminderIdToDelete(null)
                                    }}
                                    color="primary"
                                >
                                    <Typography variant="h5">
                                        <FormattedMessage id='create.dailyReminderList.dialog.delete.button.cancel' />
                                    </Typography>
                                </Button>
                                <Button 
                                    onClick={() => {
                                        dispatch(FormValuesActions.removeReminderTime(reminderIdToDelete))
                                        setOpenDeleteDialog(false)
                                        setReminderIdToDelete(null)
                                    }} 
                                    color="primary"
                                    variant="contained"
                                    disableRipple
                                >
                                    <Typography variant="body2">
                                        <FormattedMessage id='create.dailyReminderList.dialog.delete.button.delete' />
                                    </Typography>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                    {
                        <Dialog 
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.dialog}  
                            open={openDialog}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <FormattedMessage id='create.dailyReminderList.dialog.differentUnit.label.title' /> 
                            </DialogTitle>
                            <DialogContent 
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box m={4}>
                                    <Typography variant="h5" align="center">
                                        <FormattedMessage id='create.dailyReminderList.dialog.differentUnit.label.desc' /> 
                                    </Typography> 
                                </Box>                                                   
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    onClick={() => {
                                        setOpenDialog(false)
                                    }}
                                    color="primary"
                                >
                                    <Typography variant="h5">
                                        <FormattedMessage id='general.label.no' />
                                    </Typography>
                                </Button>
                                <Button 
                                    onClick={() => {
                                        setOpenDialog(false)
                                        if (location.state && location.state.fromOverview) {
                                            dispatch(goBack())
                                        } else {
                                            dispatch(FormWizardActions.routeForward())
                                        }
                                    }} 
                                    color="primary"
                                    variant="contained"
                                    disableRipple
                                >
                                    <Typography variant="body2">
                                        <FormattedMessage id='general.label.yes' />
                                    </Typography>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </>  
            )
        }

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                {dateTimeQuantityUnits
                    .sort((a, b) => getMinutes(a.timeOfDayStart) - getMinutes(b.timeOfDayStart))
                    .sort((a, b) => getHours(a.timeOfDayStart) - getHours(b.timeOfDayStart))
                    .map((reminder, i) => {
                        const unitName = reminder.unit && unitState && unitState.unitList.find(unit => unit.unitType === reminder.unit).name

                        return (  
                            <Grid item key={reminder.id}>
                                <Card className={classes.card}>           
                                    <CardContent>
                                        <Typography variant="h6">
                                            <FormattedMessage id="create.summary.label.reminder" values={{ amount: i + 1 }} />
                                        </Typography> 
                                        <Typography 
                                            variant="h5"
                                            style={{
                                                width: dateTimeQuantityUnits.length > 1 ? '290px' : '320px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {`${format(reminder.timeOfDayStart, 'HH:mm')}`} <FormattedMessage id="create.dailyReminder.label.time.postfix"/> 
                                            <span> &bull; </span>
                                            {`${reminder.quantity ? reminder.quantity : ''} ${unitName ? unitName[`${locale}`] : ''}`}
                                        </Typography>                  
                                    </CardContent>
                                    <div style={{ width: 'fit-content', display: 'flex' }}>
                                        <DeleteOutlineRoundedIcon
                                            fontSize="small" 
                                            classes={{ root: classes.iconWrapper }}
                                            style={{ 
                                                backgroundColor: 'white', 
                                                marginRight: '10px',
                                                display: dateTimeQuantityUnits.length > 1 ? 'flex' : 'none' 
                                            }}
                                            onClick={() => handleDeleteReminder(reminder.id)} 
                                        />
                                        <EditRoundedIcon 
                                            fontSize="small" 
                                            classes={{ root: classes.iconWrapper }}
                                            onClick={() => { 
                                                dispatch(push({ pathname: `/${FormType.DATE_TIME_QUANTITY_UNIT_FORM}`, state: { fromReminderOverview: location, reminderId: reminder.id } }))
                                            }} 
                                        />
                                    </div>
                                </Card>
                                {renderAlertDialog()} 
                            </Grid>
                        )
                })}
                <Grid item>
                    <Button
                        color="primary"
                        onClick={() => { 
                            dispatch(push({ pathname: `/${FormType.DATE_TIME_QUANTITY_UNIT_FORM}`, state: { fromReminderOverview: location } }))
                        }}
                    >
                        <AddRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="h5">
                            <FormattedMessage id='create.dailyReminderList.label.addTime' />
                        </Typography>                   
                    </Button> 
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleRouteForward}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.dailyReminderList.button.next' />
                        </Typography>                    
                    </Button>
                </Grid>                
            </Grid>
        )
    }
}

export default timeReminderOverviewForm