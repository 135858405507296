const it = {
    "general.label.ok": "OK",
    "general.label.yes": "Sì",
    "general.label.no": "No",
    "general.label.startOver": "Ricominciare",
    "general.label.startOver.title": "Vuoi ricominciare da capo?",
    "general.label.startOver.desc": "Questo cancellerà le tue voci e ricomincerà da capo.",
    "general.label.confirm": "Acquisisci",
    "general.label.skip": "Salta",
    "general.button.close": "Chiudi",
    "general.label.cancel": "Esci",
    "general.label.success": "Corretto",
    "general.label.notSpecified": "Nessuna informazione",
    "general.error.label.title": "Errore",
    "general.error.label.desc": "Oh cielo, si è verificato un errore. Riprovare più tardi.",
    "general.error.button.reload": "Ricarica",
    "general.error.session.desc": "Sessione scaduta",
    "general.label.timePostfix": "{amount}",
    "general.error.noQuantity": "Non ci sono quantità disponibili a magazzino",
    "general.label.minutes": "{amount} Minuti",    
    "tabBar.label.today": "Oggi",
    "tabBar.label.cabinet": "Armadietto",
    "tabBar.label.more": "Info",    
    "appInfo.label.title": "Gestire i farmaci senza stress",
    "appInfo.button.go": "Si parte",
    "appInfo.button.recovery": "Ripristina dati",
    "appInfo.label.dsgvo": "Continuando, accetti le <a href=\"{amount}\">Condizioni di utilizzo</a> e <a href=\"{total}\">l’Informativa sulla privacy</a>.",    
    "acceptTerms.label.title": "Sono disponibili nuovi termini. Per proseguire, accettali.",
    "acceptTerms.label.dsgvo": "Continuando, accetti le <a href=\"{amount}\">Condizioni di utilizzo</a> e <a href=\"{total}\">l’Informativa sulla privacy</a>.",
    "acceptTerms.button.accept": "Accettare e continuare",    
    "migrate.label.title": "Benvenuti! Abbiamo rinnovato completamente l’app. Vi auguro buona salute e tutto il meglio per il futuro. TOM.",
    "migrate.button.go": "Si parte",
    "migrate.label.dsgvo": "Continuando, accetti le <a href=\"{amount}\">Condizioni di utilizzo</a> e <a href=\"{total}\">l’Informativa sulla privacy</a>.",    
    "welcome.label.navBarTitle": "Benvenuto",
    "welcome.label.textSegment.i0": "Ciao! Sono TOM.",
    "welcome.label.textSegment.i1": "Ti aiuto nell’assunzione dei farmaci.",
    "welcome.label.textSegment.i2": "Iniziamo subito!", 
    "welcome.web.label.textSegment.i1": "Ti aiuterò a creare un promemoria per i tuoi farmaci",
    "welcome.web.label.textSegment.i2": "Prima cerca il nome del tuo farmaco.",   
    "firstMed.label.navBarTitle": "Benvenuto",
    "firstMed.label.title": "Il tuo armadietto dei farmaci è ancora vuoto.\nAggiungi subito il primo medicamento.",
    "firstMed.button.add": "Aggiungi farmaco",
    "firstMed.button.scan": "Scansiona il codice web",
    "firstMed.button.later": "Non ora, più tardi",    
    "create.label.navBarTitle.medicament.create": "Aggiungi farmaco",
    "create.label.navBarTitle.medicament.edit": "Cambia farmaco",
    "create.label.navBarTitle.reminder.create": "Aggiungi promemoria",
    "create.dialog.quit.label.title": "Annulla processo",
    "create.dialog.quit.label.medicamentDesc": "Il tuo farmaco non è stato ancora salvato. Uscire comunque?",
    "create.dialog.quit.label.reminderDesc": "La tua promemoria non è stata ancora salvata. Sei sicuro di voler annullare?",
    "create.dialog.quit.button.continue": "No, continua",
    "create.dialog.quit.button.cancel": "Sì, esci",
    "create.selection.label.title": "Ora puoi scansionare il codice a barre della confezione o cercare il nome del farmaco.",
    "create.selection.button.camera": "Scansiona il codice",
    "create.selection.button.search": "Ricerca manuale",
    "create.scan.label.title": "Inquadra il codice a barre o il codice QR con lo smartphone.",
    "create.scan.button.cameraNotAvailable": "Al momento la scansione tramite fotocamera non è disponibile. Controlla le impostazioni o passa alla modalità di inserimento manuale.",
    "create.scan.alert.label.fail.title": "Scansione non riuscita",
    "create.scan.alert.label.fail.text": "Considera i punti seguenti:  \r\n- controlla che la luminosità sia sufficiente. \r\n- evita le ombre",
    "create.scan.alert.button.fail.search": "Ricerca manuale",
    "create.scan.alert.button.fail.ok": "OK",
    "create.scan.error.label.title": "Errore durante la scansione",
    "create.search.label.desc": "Qui puoi inserire facilmente il tuo farmaco con un promemoria e alla fine trasferirlo nell'app. Il processo è semplice e facile. Per prima cosa cerchi il tuo farmaco nel nostro database o lo inserisci tu stesso.",
    "create.scan.error.label.notFound": "Il farmaco desiderato non è stato trovato",
    "create.scan.error.button.search": "Ricerca manuale",
    "create.scan.error.button.retry": "Riprova",
    "create.search.label.text": "Inserisci il nome del tuo farmaco: ",
    "create.search.label.placeholder.normal": "Inserisci nome farmaco",
    "create.search.label.placeholder.active": "Nome farmaco",
    "create.search.label.yourInput": "Il tuo inserimento",
    "create.search.button.switchDatabase": "Cambia paese",
    "create.search.label.searchResults": "Risultati ricerca: ",
    "create.search.dialog.changeDb.label.title": "Cambia database farmaci",
    "create.search.label.defaultDb": "Tutti i paesi",
    "create.alreadyInCabinet.label.title": "Armadietto dei farmaci",
    "create.alreadyInCabinet.label.desc": "Il farmaco \"{amount}\" è già nell’armadietto. Aggiungerlo comunque?",
    "create.alreadyInCabinet.button.accept": "Aggiungi",
    "create.alreadyInCabinet.button.decline": "Esci",
    "create.reminderQuery.label.text": "Desideri creare un promemoria o inserirlo solamente nell’armadietto, assumendolo al bisogno?",
    "create.reminderQuery.button.reminder": "Sì, crea promemoria",
    "create.reminderQuery.button.dashboard": "No, nell’armadietto",
    "create.push.label.text": "Mi serve ancora il tuo permesso per ricordarti l’assunzione.",
    "create.push.button.next": "Avanti",
    "create.medInfo.label.title": "Devo annotare anche la data di scadenza per inviarti un promemoria per tempo?",
    "create.medInfo.label.expirationDate": "Data di scadenza",
    "create.medInfo.label.expirationDateNotificationTime": "Promemoria dalla scadenza",
    "create.medInfo.label.expirationDateNotificationChangeInfo": "Puoi modificare questo dato successivamente nelle impostazioni",
    "create.medInfo.button.finish": "Avanti",
    "create.medInfo.button.skipInfo": "Concludi senza inserire la scadenza",
    "create.medInfo.dialog.exparationDate.label.title": "Inserisci scadenza",
    "create.intervalSelection.label.title": "Con quale cadenza devo ricordarti l’assunzione?",
    "create.intervalSelection.label.daily.title": "Giornaliera",
    "create.intervalSelection.label.daily.desc": "Un’assunzione al giorno",
    "create.intervalSelection.label.weekday.title": "In determinati giorni della settimana",
    "create.intervalSelection.label.weekday.desc": "Assunzione più volte alla settimana",
    "create.intervalSelection.label.days.title": "Ogni x giorni",
    "create.intervalSelection.label.days.desc": "Con cadenza giornaliera",
    "create.intervalSelection.label.hours.title": "Ogni x ore",
    "create.intervalSelection.label.hours.desc": "Con cadenza oraria",
    "create.intervalSelection.label.once.title": "Una volta",
    "create.intervalSelection.label.once.desc": "Assunzione una tantum al bisogno",
    "create.dailyReminder.label.createFirst.title": "A che ora devo ricordarti l’assunzione?",
    "create.dailyReminder.label.createAdditional.title": "A quale altra ora devo ricordarti l’assunzione?",
    "create.dailyReminder.label.edit.title": "Come desideri modificare il promemoria?",
    "create.dailyReminder.button.next": "Avanti",
    "create.dailyReminder.button.cancel": "Esci",
    "create.dailyReminder.dialog.time.title": "Fissa orario",
    "create.dailyReminder.dialog.unit.title": "Modifica unità",
    "create.dailyReminder.dialog.quantitiy.title": "Fissa quantità",
    "create.dailyReminder.dialog.sameTime.title": "Orari promemoria identici",
    "create.dailyReminder.dialog.sameTime.desc": "Non puoi creare due promemoria per lo stesso orario e per la stessa medicina. Modifica l’orario selezionato.",
    "create.dailyReminder.time.title": "Ora",
    "create.dailyReminder.unit.title": "Unità",
    "create.dailyReminder.quantitiy.title": "Quantità",
    "create.dailyReminderList.label.createFirst.title": "Hai registrato il tuo primo orario. Desideri aggiungerne un altro?",
    "create.dailyReminderList.label.createAdditional.title": "Desideri aggiungere un altro orario?",
    "create.dailyReminderList.label.addTime": "Aggiungi altro orario",
    "create.dailyReminderList.button.next": "Avanti",
    "create.dailyReminderList.dialog.delete.label.title": "Cancellare orario?",
    "create.dailyReminderList.dialog.delete.label.desc": "Cancellare l’assunzione alle ore {amount}?",
    "create.dailyReminderList.dialog.delete.button.delete": "Sì, cancella",
    "create.dailyReminderList.dialog.delete.button.cancel": "Esci",
    "create.dailyReminderList.dialog.differentUnit.label.title": "Unità diverse",
    "create.dailyReminderList.dialog.differentUnit.label.desc": "Attenzione:  per le assunzioni hai selezionato unità diverse. In questo caso non possiamo calcolare/monitorare le tue scorte. Desideri comunque continuare?",
    "create.dailyReminderList.dialog.stockUnit.label.title": "Nota",
    "create.dailyReminderList.dialog.stockUnit.label.desc": "Hai cambiato l'unità. Ciò significa che dovete rientrare in magazzino.",
    "create.dailyReminderList.dialog.stockUnit.button.change": "Continua a magazzino",
    "create.dailyReminder.label.time.postfix": " ",
    "create.dailyReminderList.label.reminder.postfix": "Promemoria",
    "create.weekdayReminder.label.title": "In quali giorni della settimana devo ricordarti l’assunzione?",
    "create.weekdayReminder.label.monday.full": "Lunedì",
    "create.weekdayReminder.label.monday.short": "Lun.",
    "create.weekdayReminder.label.tuesday.full": "Martedì",
    "create.weekdayReminder.label.tuesday.short": "Mar.",
    "create.weekdayReminder.label.wednesday.full": "Mercoledì",
    "create.weekdayReminder.label.wednesday.short": "Mer.",
    "create.weekdayReminder.label.thursday.full": "Giovedì",
    "create.weekdayReminder.label.thursday.short": "Gio.",
    "create.weekdayReminder.label.friday.full": "Venerdì",
    "create.weekdayReminder.label.friday.short": "Ven.",
    "create.weekdayReminder.label.saturday.full": "Sabato",
    "create.weekdayReminder.label.saturday.short": "Sab.",
    "create.weekdayReminder.label.sunday.full": "Domenica",
    "create.weekdayReminder.label.sunday.short": "Dom.",
    "create.weekdayReminder.button.next": "Avanti",
    "create.daysReminder.label.title": "Con quale cadenza giornaliera devo ricordarti l’assunzione?",
    "create.daysReminder.button.days.title": "Cadenza giornaliera",
    "create.daysReminder.button.days.msg": "Ogni {amount} giorni",
    "create.daysReminder.button.next": "Avanti",
    "create.daysReminder.dialog.days.title": "Scegli cadenza giornaliera",
    "create.hoursReminder.label.title": "Con quale cadenza oraria devo ricordarti l’assunzione?",
    "create.hoursReminder.dialog.hours.title": "Scegli cadenza oraria",
    "create.hoursReminder.button.hours.title": "Cadenza oraria",
    "create.hoursReminder.button.hours.msg": "Ogni {amount} ore",
    "create.hoursReminder.button.timeStart.title": "Inizio intervallo",
    "create.hoursReminder.button.timeEnd.title": "Fine intervallo",
    "create.hoursReminder.dialog.timeStart.title": "Scegli inizio intervallo",
    "create.hoursReminder.dialog.timeEnd.title": "Scegli fine intervallo",
    "create.hoursReminder.button.unit.title": "Unità",
    "create.hoursReminder.button.quantitiy.title": "Quantità",
    "create.hoursReminder.label.time.postfix": " ",
    "create.hoursReminder.dialog.unit.title": "Modifica unità",
    "create.hoursReminder.dialog.quantitiy.title": "Fissa quantità",
    "create.hoursReminder.errorDialog.endDateInvalid.title": "Fine intervallo non valida",
    "create.hoursReminder.errorDialog.endDateInvalid.desc": "La fine intervallo non può essere precedente all’inizio.",
    "create.hoursReminder.errorDialog.endDateInvalid.interval.desc": "La fine dell’intervallo non può essere precedente al primo orario di assunzione.",
    "create.hoursReminder.errorDialog.startDateInvalid.title": "Inizio intervallo non valido",
    "create.hoursReminder.errorDialog.startDateInvalid.desc": "L’inizio dell’intervallo non può essere precedente alla fine.",
    "create.hoursReminder.button.next": "Avanti",
    "create.onceReminder.label.title": "Quando devo ricordarti una tantum l’assunzione del farmaco?",
    "create.onceReminder.dialog.date.title": "Scegli data",
    "create.onceReminder.button.date.title": "Data",
    "create.onceReminder.button.time.title": "Ora",
    "create.onceReminder.button.unit.title": "Unità",
    "create.onceReminder.button.quantitiy.title": "Quantità",
    "create.onceReminder.dialog.time.title": "Fissa orario",
    "create.onceReminder.dialog.unit.title": "Modifica unità",
    "create.onceReminder.dialog.quantitiy.title": "Fissa quantità",
    "create.onceReminder.label.time.postfix": " ",
    "create.onceReminder.button.next": "Avanti",
    "create.period.label.title": "Quanto deve durare la terapia?",
    "create.period.label.startTime": "Inizio terapia",
    "create.period.label.periodType": "Durata terapia",
    "create.period.label.periodType.continuous": "Continua",
    "create.period.label.periodType.endDate": "Fino alla data finale",
    "create.period.label.endDate": "Fine terapia",
    "create.period.dialog.startDate.title": "Scegli inizio terapia",
    "create.period.dialog.endDate.title": "Scegli fine terapia",
    "create.period.label.days": "Numero giorni",
    "create.period.dialog.days.title": "Scegli numero giorni",
    "create.period.button.next": "Avanti",
    "create.stock.label.title": "Mantenere una visione d'insieme del vostro stock!",
    "create.stock.label.hint.stockInactive": "Inserisci la scorta disponibile",
    "create.stock.label.hint.stockActive": "Scorta",
    "create.stock.label.hint.unit": "Unità",
    "create.stock.label.expirationDateNotificationTime": "Controllo delle scorte",
    "create.stock.label.expirationDateNotificationChangeInfo": "TOM aggiorna il numero di compresse in magazzino ogni volta che le prendete e vi informa se dovete riordinare.",
    "create.stock.button.next": "Avanti",
    "create.stock.dialog.label.stock.title": "Scorta disponibile",
    "create.stock.dialog.label.unit.title": "Modifica unità",
    "create.intakeInfo.label.title": "Come si deve assumere il farmaco?",
    "create.intakeInfo.label.intakeOptions.title": "Nota sull’assunzione",
    "create.intakeInfo.label.intakeOptions.preMeal": "Prima dei pasti",
    "create.intakeInfo.label.intakeOptions.meal": "Ai pasti",
    "create.intakeInfo.label.intakeOptions.postMeal": "Dopo i pasti",
    "create.intakeInfo.label.intakeOptions.none": "Nessuna informazione",
    "create.intakeInfo.label.changeInfo": "Puoi sempre cambiare le informazioni in un secondo momento",
    "create.intakeInfo.button.finish": "Avanti",
    "create.summary.label.title": "Le informazioni sono tutte corrette?",
    "create.summary.label.interval": "Intervallo",
    "create.summary.label.reminder": "{amount}. Promemoria",
    "create.summary.label.emptyReminder": "Promemoria",
    "create.summary.label.therapyPeriod.title": "Durata terapia",
    "create.summary.label.therapyPeriod.continuous.desc": "da {amount}",
    "create.summary.label.stock": "Scorta",
    "create.summary.label.expiryDate": "Data di scadenza",
    "create.summary.label.intakeInfo": "Nota sull’assunzione",
    "create.summary.label.notes": "Nota",
    "create.summary.dialog.notes.label.title": "Aggiungi nota",
    "create.summary.dialog.notes.placeholder.active": "Nota",
    "create.summary.dialog.notes.placeholder.inactive": "Scopo, particolarità, indicazioni del medico...",
    "create.summary.label.reminder.weekdays": "Giorni della settimana",
    "create.summary.label.reminder.dayInterval.title": "Intervallo di giorni",
    "create.summary.label.reminder.dayInterval.desc": "Ogni {amount} giorni",
    "create.summary.label.reminder.hourInterval.title": "Intervallo di ore",
    "create.summary.label.reminder.hourInterval.desc": "Ogni {amount} ore",
    "create.summary.label.reminder.hourPeriod.title": "Periodo dell’intervallo",
    "create.summary.label.reminder.hourPeriod.desc": "Da {amount} a {total}",
    "create.summary.label.intervalOnce.date": "Data",
    "create.summary.label.intervalOnce.time": "Ora",
    "create.summary.label.dosage": "Quantità",
    "create.summary.button.ok": "Sì, è tutto corretto",
    "create.short.summary.label.title": "Vuole aggiungere il seguente medicinale al suo armadietto dei medicinali?",
    "create.short.summary.button.add": "Sì, aggiungere",   
    "create.web.summary.label.title": "È così facile in TOM.", 
    "create.web.summary.label.desc": "Se vuoi, posso trasferire la tua terapia direttamente sull'app gratuita.",
    "create.web.summary.button.add": "Sì volentieri.",
    "create.web.summary.button.cancel": "No, non è necessario.",
    "qr.code.title": "Con questo codice QR puoi gestire i tuoi farmaci direttamente nell'app. Per farlo, scansiona il codice con l'app mobile TOM:",
    "qr.code.desc": "Non hai ancora l'app TOM? Quindi installala gratuitamente utilizzando i link sottostanti, quindi puoi scansionare il codice nell'app.", 
    "cabinet.label.navBarTitle": "Armadietto dei farmaci",
    "cabinet.label.stock": "Scorta",
    "cabinet.label.expiry": "Scadenza",
    "cabinet.label.notSpecified": "n.d.",
    "cabinet.filter.label.title": "Filtri",
    "cabinet.filter.label.all": "Mostra tutti",
    "cabinet.filter.label.withReminder": "Con promemoria",
    "cabinet.filter.label.withoutReminder": "Senza promemoria",
    "cabinet.filter.label.withPrescription": "Con obbligo di ricetta",
    "cabinet.filter.label.withoutPrescription": "Senza obbligo di ricetta",
    "cabinet.dialog.sorting.label.title": "Seleziona l'ordinamento",
    "cabinet.dialog.sorting.label.name.asc": "Nome {ASC}",
    "cabinet.dialog.sorting.label.name.desc": "Nome {DESC}",
    "cabinet.dialog.sorting.label.expiryDate.asc": "Data di scadenza {ASC}",
    "cabinet.dialog.sorting.label.expiryDate.desc": "Data di scadenza {DESC}",
    "cabinet.dialog.sorting.label.stock.asc": "Scorte {ASC}",
    "cabinet.dialog.sorting.label.stock.desc": "Scorte {DESC}",    
    "detail.label.navBarTitle": "Riepilogo farmaci",
    "detail.label.title": "Le tue indicazioni: ",
    "detail.label.editTitle": "Ora puoi modificare liberamente la voce: ",
    "detail.button.save": "Salva",
    "detail.button.addReminder": "Aggiungi promemoria",
    "detail.dialog.deleteSelection.label.title": "Cancellare",
    "detail.dialog.deleteSelection.label.desc": "Vuole cancellare l'intera medicina o solo il promemoria?",
    "detail.dialog.deleteSelection.button.med": "Farmaci",
    "detail.dialog.deleteSelection.button.reminder": "Promemoria",
    "detail.dialog.deleteConfirm.label.title": "Eliminare davvero?",
    "detail.dialog.deleteConfirm.label.reminderDesc": "Vuoi davvero cancellare il promemoria?",
    "detail.dialog.deleteConfirm.label.medDesc": "Vuole davvero cancellare la medicina?",
    "detail.dialog.deleteConfirm.button.yes": "Sì, cancella.",
    "detail.dialog.deleteConfirm.button.no": "No, abortire.",
    "detail.success.label.text": "Le voci sono state aggiornate con successo.",    
    "today.label.next.title": "Prossima\nassunzione fra",
    "today.label.next.at": "alle",
    "today.label.taken.title": "Assunto\ncorrettamente",
    "today.label.taken.at": "il {amount} alle",
    "today.label.skipped.title": "Non\nassunto",
    "today.label.skipped.at": "il {amount} alle",
    "today.label.overdue.title": "Assunzione\nin ritardo da",
    "today.label.overdue.at": "Oggi alle",
    "today.label.planned.title": "Assunzione\nprevista fra",
    "today.label.planned.day": "1 giorno",
    "today.label.planned.days": "{amount} giorni",
    "today.label.planned.at": "il {amount} alle",
    "today.button.take": "Assumi ora",
    "today.button.later": "Sposta",
    "today.button.skip": "Tralascia",
    "today.emptyState.label.text": "Oggi non devi assumere farmaci",
    "today.label.clock": "{amount}",
    "today.postponeDialog.label.title": "Di quanto desideri posticipare l’assunzione?",
    "today.postponeDialog.label.content": "{amount} Minuti",
    "today.postponeDialog.button.later": "Sposta",
    "today.postponeConfirmDialog.label.title": "Posticipa assunzione",
    "today.postponeConfirmDialog.label.desc": "Sei sicuro di voler posticipare l’assunzione di {amount} minuti?",
    "today.postponeConfirmDialog.button.later": "Sposta",
    "today.label.selectDay": "Seleziona il giorno",
    "today.intakeInfo.label.confirmed": "Presa alle {amount}",
    "today.intakeInfo.label.postponed": "Spostato a {amount}",
    "today.intakeInfo.label.overdue": "In ritardo dal {amount}",
    "today.intakeInfo.label.skipped": "Omesso.\n{amount}",
    "today.intakeInfo.label.skippedNoReason": "Omesso.",
    "today.actionSheet.label.takeNow": "Prendilo ora",
    "today.actionSheet.label.takeAllNow": "Prendeteli tutti ora",
    "today.actionSheet.label.takeOnPoint": "Preso in orario alle {amount}",
    "today.actionSheet.label.takeAllOnPoint": "Tutto è stato preso in tempo alle {amount}",
    "today.actionSheet.label.takeOtherTime": "Preso in un altro momento",
    "today.actionSheet.label.takeAllOtherTime": "Tutto preso in un momento diverso",
    "today.actionSheet.label.skip": "Omettere",
    "today.actionSheet.label.skipAll": "Salta tutto",
    "today.actionSheet.label.postpone": "Spostare",
    "today.actionSheet.label.postponeAll": "Spostare tutti",
    "today.actionSheet.label.titleAll": "Tutte le medicine alle {amount}",
    "today.actionSheet.label.reset": "Ripristinare lo stato dei prendere",
    "today.timePicker.label.title": "Fissa orario",
    "today.listBottomButtons.button.takeAllNow": "Prendeteli tutti ora",
    "today.listBottomButtons.button.takeAllOnPoint": "Tutto preso in tempo",
    "today.listBottomButtons.button.skipAll": "Salta tutto",
    "today.listBottomButtons.button.takeAllOnOtherPoint": "Tutto preso in un momento diverso",
    "today.error.batchIntakes": "Siamo spiacenti, non è stato possibile aggiornare tutte le prese di corrente, si prega di riprovare più tardi.",    
    "actionSheet.label.new": "Nuovo",    
    "addEntry.label.title": "Aggiungi voce",
    "addEntry.label.med": "Aggiungi farmaco",
    "addEntry.label.mediCheck": "Medi-Check",
    "addEntry.label.qrCodeMed": "Scansiona il codice web",    
    "invalidCredentials.label.title": "Si è verificato un errore nel processo di autenticazione.",
    "invalidCredentials.label.text": "La preghiamo di riprovare. Oppure scriveteci a support(at)innovation6.ch e cercheremo di aiutarvi!",
    "invalidCredentials.button.retry": "Riprova",
    "invalidCredentials.button.logout": "Logout",
    "invalidCredentials.logoutConfirm.label.title": "Logout",
    "invalidCredentials.logoutConfirm.label.text": "Con questa azione cancellerai i tuoi dati dal dispositivo.",
    "invalidCredentials.logoutConfirm.button.accept": "Continua",
    "invalidCredentials.logoutConfirm.button.cancel": "Esci",
    "loadingCurrentUserFailed.label.title": "Oh, cielo! Purtroppo qualcosa è andato storto durante il caricamento dei dati.",
    "loadingCurrentUserFailed.label.text": "La preghiamo di riprovare. Oppure scriveteci a support(at)innovation6.ch e cercheremo di aiutarvi!",
    "loadingCurrentUserFailed.button.retry": "Riprova",    
    "loadingGTCFailed.label.title": "Oh, cielo! Qualcosa è andato storto durante il caricamento delle Condizioni Generali.",
    "loadingGTCFailed.label.text": "La preghiamo di riprovare. Oppure scriveteci a support(at)innovation6.ch e cercheremo di aiutarvi!",
    "loadingGTCFailed.button.retry": "Riprova",    
    "defaultError.label.title": "Si è verificato un errore.",
    "defaultError.label.text": "La preghiamo di riprovare. Oppure scriveteci a support(at)innovation6.ch e cercheremo di aiutarvi!",
    "defaultError.button.retry": "Riprova",    
    "confetti.label.title": "Complimenti!\nHai aggiunto correttamente il primo farmaco.",
    "confetti.button.next": "Avanti",    
    "more.label.navBarTitle": "Informazioni",
    "more.profile.label.title": "Dati personali",
    "more.profile.label.allergies": "Allergie",
    "more.medicheck.label.title": "Medi-Check",
    "more.medicheck.label.desc": "Il Medi-Check viene eseguito da una farmacia svizzera e potrebbe essere in grado di darti nuovi consigli su interazioni, doppio trattamento e altro. Facile e comodo da casa.",
    "more.legal.label.title": "Informazioni legali",
    "more.legal.label.agb": "Condizioni di utilizzo",
    "more.legal.label.dsg": "Informativa sulla privacy",
    "more.legal.label.imprint": "Impressum",
    "more.legal.label.delete": "Cancella dati",
    "more.legal.label.messageSupport": "Messaggio al supporto",
    "more.legal.label.updateNews": "Anteprima 2020",
    "more.label.version": "Nota sulla versione {amount}",
    "more.settings.label.design.title": "Personalizza l’interfaccia",
    "more.settings.label.design.desc": "Scegli una delle interfacce standard di TOM o tra le premium Dark Mode o Color Mode.",
    "more.settings.label.push.title": "Modifica i promemoria",
    "more.settings.label.push.desc": "Definisci la modalità e la frequenza dei promemoria e dei suoni, decidendo se visualizzare o meno i nomi dei farmaci nel promemoria.",
    "more.settings.label.title": "Impostazioni",
    "more.settings.label.desc": "Qui è possibile modificare le impostazioni di promemoria e suoni o selezionare un’altra interfaccia grafica.",
    "more.label.userId": "ID utente",
    "more.premium.label.title": "Funzioni premium",
    "more.premium.label.desc": "Le funzioni premium ti assicurano molti vantaggi e più flessibilità con nuove interfacce grafiche, nuovi suoni, promemoria e molto altro ancora.",
    "imprint.label.navBarTitle": "Impressum",
    "imprint.label.cooperation": "Innovation 6 AG",
    "imprint.label.address": "Pfäffikerstrasse 109,\n8615 Wermatswil,\nSvizzera\n",
    "imprint.label.uid": "P.IVA:  CHE-263.807.653",
    "imprint.label.ceo": "Dirigente:  Sven Beichler",
    "imprint.label.mail": "E-mail:  support@innovation6.ch",
    "imprint.label.web": "Web:  www.tommedications.com",    
    "revokeData.label.navBarTitle": "Cancella dati",
    "revokeData.label.title": "Sei sicuro/a di voler cancellare tutti i tuoi dati? Il processo è irreversibile!",
    "revokeData.button.revoke": "Sì, cancella dati",
    "revokeData.button.keep": "No, non cancellare",
    "revokeData.confirmDialog.label.title": "Cancellare definitivamente i dati?",
    "revokeData.confirmDialog.label.desc": "Sei veramente sicuro/a di voler cancellare tutti i tuoi dati?",
    "revokeData.confirmDialog.button.delete": "Sì, cancella definitivamente i dati",
    "revokeData.closeDialog.label.title": "Dati cancellati",
    "revokeData.closeDialog.label.desc": "I vostri dati sono stati irrevocabilmente cancellati. Speriamo di rivedervi presto!",
    "revokeData.closeDialog.button.bye": "Addio!",    
    "ommitReason.label.navBarTitle": "Salta la medicina",
    "ommitReason.label.title": "C'è un motivo particolare per cui state saltando i farmaci?",
    "ommitReason.button.noReason": "Niente da aggiungere",
    "ommitReason.label.storageHint": "Le vostre informazioni su un farmaco mancato saranno annotate nelle vostre statistiche e possono fornire informazioni importanti al vostro medico.",
    "ommitReason.label.reasonHint": "Seleziona il motivo",    
    "updateDialog.label.title": "Aggiornamento disponibile",
    "updateDialog.label.message": "È disponibile una nuova versione di {amount}.",
    "updateDialog.button.update": "Aggiorna",    
    "mediCheck.teaser.screen1.label.title": "Il Medi-Check",
    "mediCheck.teaser.screen1.label.desc": "Medi-Check controlla le tue medicine comodamente e in sicurezza da casa per interazioni e molto altro.",
    "mediCheck.teaser.screen1.label.hint": "Effettuato personalmente dalla farmacista FPH Anja Bauert dell'Apotheke Witikon / ZH toppharm.",
    "mediCheck.teaser.screen1.button.close": "Chiudere",
    "mediCheck.teaser.screen1.button.learnMore": "Continua",
    "mediCheck.teaser.screen2.label.title": "Il Medi-Check include informazioni su: ",
    "mediCheck.teaser.screen2.label.desc.full": "\n- Indicazioni\n- Interazioni\n- Doppia medicazione\n- Istruzioni per l'uso\n- Consigli sulle allergie\n\nInvia il tuo farmaco per la revisione direttamente da TOM!",
    "mediCheck.teaser.screen2.label.desc.highlighted": "- Indicazioni\n- Interazioni\n- Doppia medicazione\n- Istruzioni per l'uso\n- Consigli sulle allergie",
    "mediCheck.teaser.screen2.button.close": "Chiudere",
    "mediCheck.teaser.screen2.button.continue": "Inizia ora",
    "mediCheckList.label.navBarTitle": "Precedenti Medi-Checks",
    "mediCheckList.label.share": "Condividi Medi-Check",
    "mediCheckList.switch.label.open": "Aprire",
    "mediCheckList.openSection.label.paymentNotCompleted": "Pagamento non completato",
    "mediCheckList.openSection.label.inProgress": "In processo",
    "mediCheckList.openSection.label.inquiry": "Richiesta",
    "mediCheckList.switch.label.finished": "Fatto",
    "mediCheckList.button.startMediCheck": "Avviare un nuovo Medi-Check",
    "mediCheckList.empty.label.open": "Attualmente non disponete di Medi-Checks aperti",
    "mediCheckList.empty.label.finished": "Nessuno dei vostri controlli mediali è stato completato. Vi prego di avere pazienza ancora un po'.",
    "mediCheckList.empty.label.general": "Non avete ancora organizzato un controllo medianico. Con un solo clic potete controllare i vostri farmaci comodamente da casa vostra.",
    "mediCheckList.label.transmitted": "Trasmesso su {amount}",
    "mediCheckList.label.recieved": "Ricevuto su {amount}",
    "mediCheckCreation.label.navBarTitle": "Medi-Check",
    "mediCheckCreation.medSelection.label.title": "Quelle nell'armadietto sono le tue droghe. Selezionare un massimo di 6 farmaci o aggiungerne di nuovi.",
    "mediCheckCreation.medSelection.label.hintCabinet": "Farmaci dal tuo armadietto",
    "mediCheckCreation.medSelection.label.addMed": "Aggiungi una medicina",
    "mediCheckCreation.medSelection.button.next": "Continua",
    "mediCheckCreation.medSelection.error.limitReached": "Avete già selezionato il numero massimo possibile di farmaci.",
    "mediCheckCreation.medSelection.error.sameArticle": "Avete selezionato lo stesso farmaco due volte per il Medi-Check. Si prega di modificare la selezione.",
    "mediCheckCreation.medSelection.error.selectMore.label.title": "Sono stati selezionati troppi pochi farmaci.",
    "mediCheckCreation.medSelection.error.selectMore.label.desc": "È necessario selezionare almeno 2 farmaci",
    "mediCheckCreation.dialogQuit.label.title": "Annulla MediCheck",
    "mediCheckCreation.dialogQuit.label.desc": "È sicuro di non voler fare un Medi-Check?",
    "mediCheckCreation.dialogQuit.button.continue": "No, continua.",
    "mediCheckCreation.dialogQuit.button.cancel": "Sì, abortire",
    "mediCheckCreation.allergieSelection.label.title": "Hai qualche allergia?",
    "mediCheckCreation.allergieSelection.label.allergyType.food": "Allergia alimentare",
    "mediCheckCreation.allergieSelection.label.allergyType.medication": "Allergia ai farmaci",
    "mediCheckCreation.allergieSelection.label.allergyType.other": "Altre allergie",
    "mediCheckCreation.allergieSelection.label.addAllergie": "Aggiungi un'altra allergia",
    "mediCheckCreation.allergieSelection.label.noAllergiesSoFar": "Non hai ancora dichiarato alcuna allergia. Se soffrite di allergie, queste informazioni sono importanti affinché possano essere prese in considerazione nel Medi-Check.",
    "mediCheckCreation.allergieSelection.button.next": "Continua",
    "mediCheckCreation.allergieSelection.button.nextWithoutAllergies": "Ulteriore senza allergie",
    "mediCheckCreation.allergieSelection.error.limitReached": "Avete già selezionato il numero massimo possibile di allergie.",
    "mediCheckCreation.summary.label.title": "Riepilogo degli elementi selezionati per il vostro Medi-Check",
    "mediCheckCreation.summary.label.allergyType.food": "Allergie alimentari selezionate",
    "mediCheckCreation.summary.label.allergyType.medication": "Allergie a farmaci selezionati",
    "mediCheckCreation.summary.label.allergyType.other": "Altre allergie selezionate",
    "mediCheckCreation.summary.label.meds": "Farmaci selezionati",
    "mediCheckCreation.summary.label.editMeds": "Modifica farmaci",
    "mediCheckCreation.summary.label.editAllergies": "Modifica allergie",
    "mediCheckCreation.summary.label.legalDisclaimer": "Ho capito che il Medi-Check considera solo i farmaci e le allergie che sono stati trasmessi e non può sostituire la consultazione di un medico o di un farmacista.",
    "mediCheckCreation.summary.button.buy": "Acquista",
    "mediCheckCreation.summary.label.paymentInProgress": "Il vostro ultimo acquisto è ancora in fase di convalida. Pertanto l'acquisto non ha potuto essere completato, si prega di riprovare più tardi.",
    "mediCheckDetails.label.id": "Medi-Check ID",
    "mediCheckDetails.label.buyInfo": "C'è stato un problema durante l'acquisto del Medi-Check, si prega di riprovare cliccando su \"Acquista ora\". Controlliamo se l'ultimo acquisto è andato a buon fine. In questo caso, viene utilizzato l'ultimo processo di acquisto e non viene avviato alcun nuovo processo di acquisto.",
    "mediCheckDetails.button.buy": "Acquista ora",
    "mediCheckDetails.label.pharmacyInquiry": "Ci sono domande dalla farmacia sul vostro Medi-Check. Contattateli il prima possibile.",
    "mediCheckDetails.label.pharmacyInquiry.info": "Finché la questione non sarà chiarita, il vostro Medi-Check non potrà essere completato. Si prega di contattare immediatamente la farmacia.",
    "mediCheckDetails.label.pharmacyInquiry.question": "DOMANDA: \n\"{amount}\" ",
    "mediCheckDetails.label.pharmacyInfo.name": "TopPharm Apotheke Witikon",
    "mediCheckDetails.label.pharmacyInfo.address": "Carl Spitteler-Strasse 2\n8053 Zürich",
    "mediCheckDetails.label.pharmacyInfo.website": "https: //www.apotheke.ch/witikon",
    "mediCheckDetails.label.pharmacyInfo.mail": "info@witikon.apotheke.ch",
    "mediCheckDetails.label.payment.in.progress": "Il vostro ultimo acquisto è ancora in fase di convalida. Potrebbe essere necessario qualche minuto prima di poter effettuare un nuovo acquisto.", 
    "pharmacy.overview.label.pharmacy": "Farmacia",    
    "allergies.label.navBarTitle": "Le mie allergie",
    "allergies.empty.label.title": "Soffre di una o più allergie a uno o più farmaci, alimenti o altri tipi di allergie?",
    "allergies.empty.button.add": "Aggiungi l'allergia",
    "allergies.label.title": "Le vostre allergie precedentemente segnalate: ",
    "allergies.button.add": "Aggiungi l'allergia",
    "allergies.button.save": "Salva",
    "allergies.label.allergyType.food": "Allergia alimentare",
    "allergies.label.allergyType.medication": "Allergia ai farmaci",
    "allergies.label.allergyType.other": "Altre allergie",
    "allergies.addDialog.label.title": "Aggiungi l'allergia",
    "allergies.search.label.title": "Inserisci il nome delle tue allergie: ",
    "allergies.textInput.label.title": "Inserisci il nome delle tue allergie: ",
    "allergies.search.label.medHint": "Allergie ai farmaci e agli ingredienti",
    "allergies.search.label.searchFieldHint": "Ricerca per allergia",
    "allergies.textInput.label.foodHint": "Allergia alimentare",
    "allergies.textInput.label.otherHint": "Altre allergie",
    "allergies.textInput.label.textFieldHint": "Inserisci allergia",
    "allergies.textInput.button.confirm": "Acquisisci",    
    "premium.navBar.title": "Premium",
    "premium.label.tag": "Premium",
    "premium.label.tagActive": "Premium attivo",
    "premium.label.title": "Ne vuoi ancora un po '? Con le funzionalità TOM Premium godi di molti vantaggi: ",
    "premium.label.alarmSettings.title": "Impostazioni allarme",
    "premium.label.alarmSettings.desc": "Scegli una delle diverse opzioni di avviso:  al momento esatto dell’assunzione, prima, dopo o tutte insieme.",
    "premium.label.ringtoneChoice.title": "Selezione suono",
    "premium.label.ringtoneChoice.desc": "È possibile scegliere tra 20 suoni diversi oltre a quello standard.",
    "premium.label.design.title": "Interfaccia",
    "premium.label.design.desc": "Meglio uno stile più scuro con Dark Mode o più colorato con Color Mode? Scegli tra tre interfacce grafiche.",
    "premium.label.cabinet.title": "Armadietto",
    "premium.label.cabinet.desc": "Troppi farmaci nell’armadietto? La nuova visualizzazione a elenco facilita il controllo della situazione.",
    "premium.label.sorting.title": "Selezionamento",
    "premium.label.sorting.desc": "Inoltre puoi riordinare il tuo armadietto dei medicinali in tutta semplicità, in base a scorte o data di scadenza.",
    "premium.label.subscriptionModels": "Tipi di abbonamento",
    "premium.label.monthly.title": "Abbonamento mensile",
    "premium.label.monthly.desc": "Revocabile in qualsiasi momento entro la fine del mese!",
    "premium.label.yearly.title": "Abbonamento annuale",
    "premium.label.yearly.desc": "Il nostro bestseller:  risparmia il 25%!",
    "premium.button.subscribeNow": "Sottoscrivi subito il tuo abbonamento",
    "premium.label.withClickOnButton": "Facendo clic sul pulsante sarai reindirizzato al produttore",
    "premium.label.manufacturer": "produttore",
    "premium.label.automaticRenew": "L’abbonamento si rinnova automaticamente entro 24 ore dalla scadenza dell’abbonamento attuale",
    "premium.label.within24Hours": "entro 24 ore",
    "premium.label.privacyAndTerms": "Sottoscrivendo un abbonamento, accetti le nostre <a href=\"{amount}\">condizioni di utilizzo</a> e l’<a href=\"{amount}\">informativa sulla privacy</a>",
    "premium.label.termsOfUse": "Condizioni di utilizzo",
    "premium.label.privacyPolicy": "Informativa sulla privacy",    
    "pushSettings.label.navBarTitle": "Modifica i promemoria",
    "pushSettings.label.standard": "Impostazioni standard",
    "pushSettings.label.premiumSettings": "Impostazioni premium",
    "pushSettings.label.title": "Qui puoi impostare la modalità dei promemoria per le assunzioni in base alle tue preferenze.",
    "pushSettings.label.nowBecomeMember": "Diventa subito un membro premium e approfitta di numerosi vantaggi!",
    "pushSettings.label.sendReminders.title": "Invia promemoria",
    "pushSettings.label.sendNoReminders.desc.inactive": "Attiva questa funzione per non ricevere più promemoria da TOM.",
    "pushSettings.label.sendNoReminders.desc.active": "Disattiva questa funzione per ricevere sempre promemoria da TOM.",
    "pushSettings.label.hideMedicamentName.title": "Nascondi nome farmaco",
    "pushSettings.label.hideMedicamentName.desc.active": "Disattiva questa funzione per visualizzare il nome del farmaco nelle tue notifiche push.",
    "pushSettings.label.hideMedicamentName.desc.inactive": "Attiva questa funzione per proteggere le tue notifiche push da occhi indiscreti.",
    "pushSettings.label.notificationWithoutSound.title": "Notifica con vibrazione",
    "pushSettings.label.notificationWithoutSound.desc.inactive": "Attiva questa funzione per ricevere promemoria in modalità silenziosa.",
    "pushSettings.label.notificationWithoutSound.desc.active": "Disattiva questa funzione per abbinare sempre i tuoi promemoria a un suono. Assicurati che il tuo telefono non sia in modalità silenziosa.",
    "pushSettings.label.changeTone.title": "Cambia il suono di notifica",
    "pushSettings.label.changeTone.desc.active": "Scegli un tono di notifica personalizzato",
    "pushSettings.label.changeTone.desc.inactive": "Scegli un tono di notifica personalizzato",
    "pushSettings.label.intensity.title": "Regola l’intensità della notifica",
    "pushSettings.label.intensity.desc.active": "Puoi impostare l’intensità che preferisci per i promemoria di assunzione",
    "pushSettings.label.intensity.desc.inactive": "Puoi impostare l’intensità che preferisci per i promemoria di assunzione",
    "pushSettings.label.premium": "Premium",
    "pushSettings.label.more": "Info",
    "pushSettings.label.premiumActive": "Premium attivo",
    "pushSettings.label.onSpecifiedTime.title": "All’ora specificata",
    "pushSettings.label.onSpecifiedTime.desc.active": "Il promemoria avviene all’ora specificata",
    "pushSettings.label.onSpecifiedTime.desc.inactive": "Non viene inviato alcun promemoria all’orario indicato.",
    "pushSettings.label.before.title": "Prima",
    "pushSettings.label.before.desc.active": "Un promemoria supplementare viene inviato 10 minuti prima dell’orario esatto di assunzione.",
    "pushSettings.label.before.desc.inactive": "Non viene inviato alcun promemoria supplementare prima dell’orario esatto di assunzione.",
    "pushSettings.label.before.placeholder": "Prima",
    "pushSettings.label.after.title": "Dopo",
    "pushSettings.dialog.timeSelection.label.title": "Seleziona l’orario",
    "pushSettings.label.after.desc.active": "Un promemoria supplementare viene inviato 10 minuti dopo l’orario esatto di assunzione.",
    "pushSettings.label.after.desc.inactive": "Non viene inviato alcun promemoria supplementare dopo l’orario esatto di assunzione.",
    "pushSettings.label.after.placeholder": "Dopo",
    "pushSettings.label.pushSound": "Suono notifica",
    "pushSettings.dialog.pushSound.label.title": "Seleziona il suono",
    "pushSettings.label.soundFile.gooseWalk": "Marcia dell'oca",
    "pushSettings.label.soundFile.majorBlues.140": "Blues",
    "pushSettings.label.soundFile.majorBlues.90": "Sono a posto",
    "pushSettings.label.soundFile.bell": "Campanellino",
    "pushSettings.label.soundFile.ringing": "Campana nervosa",
    "pushSettings.label.soundFile.dark.ringing": "Taaa!",
    "pushSettings.label.soundFile.bright.ringing": "Ammiccare",
    "pushSettings.label.soundFile.cowbells": "Pausa campana",
    "pushSettings.label.soundFile.techno": "Techno",
    "pushSettings.label.soundFile.jazzTrumpets": "Trombe Jazz",
    "pushSettings.label.soundFile.mandolin": "Mandolino",
    "pushSettings.label.soundFile.orchestraString": "Beethoven",
    "pushSettings.label.soundFile.birds": "Canto degli uccelli",
    "pushSettings.label.soundFile.orchestraTrumpets": "Trombe d'orchestra",
    "pushSettings.label.soundFile.pianoSurprise": "Pianoforte come questo",
    "pushSettings.label.soundFile.pianoFastAsLightning": "Piano con una differenza",
    "pushSettings.label.soundFile.retro": "Onde retrò",
    "pushSettings.label.soundFile.scienceFiction": "Fantascienza",
    "pushSettings.label.soundFile.digitalPeep": "Tutto digitale",
    "pushSettings.label.soundFile.trumpet": "Tromba Freestyle",    
    "customTone.navBar.title": "Suoni notifica",    
    "design.label.navBarTitle": "Personalizza interfaccia",
    "design.label.title": "Se hai voglia di un po’ di novità, puoi modificare l’estetica dell’app.",
    "design.label.modeDark": "Modalità scura",
    "design.label.modeLight": "Modalità chiara",
    "design.label.modeColor": "Modalità colore",
    "design.selection.light.label.title": "Modalità chiara (standard)",
    "design.selection.light.label.desc": "La nostra interfaccia chiara standard:  raffinata, attuale e sempre gratuita per tutti gli utenti TOM!",
    "design.selection.dark.label.title": "Modalità scura",
    "design.selection.dark.label.desc": "Salvaguardare la batteria e al contempo i tuoi occhi? È possibile con la modalità scura di TOM.",
    "design.selection.color.label.title": "Modalità colore",
    "design.selection.color.label.desc": "Per gli amanti del colore, TOM può brillare anche in altre tonalità.",
    "design.label.premiumTag": "Premium",
    "design.label.getPremium": "Diventa subito un membro premium e approfitta di numerosi vantaggi!",
    "design.button.getPremium": "Info",
    "design.button.save": "Salva",    
    "restoreIAP.label.title": "Se hai reinstallato l’app, puoi ripristinare qui i tuoi acquisti.",
    "restoreIAP.button.restore": "Ripristina gli acquisti",
    "restoreIAP.error.label.title": "Ripristino fallito",
    "restoreIAP.error.label.desc": "Il ripristino degli acquisti non è riuscito, riprova più tardi.",
    "restoreIAP.label.nothingToRestore": "Non vi erano acquisti da ripristinare.",
    "restoreIAP.label.restoreSuccess": "Gli acquisti sono stati ripristinati con successo.",    
    "qrCodeMed.label.navBarTitel": "Aggiungi farmaco",
    "qrCodeMed.label.title": "Adesso puoi scansionare in tutta semplicità il codice QR della tua terapia che hai generato su tommedications.com.",
    "qrCodeMed.label.desc": "Non hai ancora compilato una terapia? Vai su www.tommedications.com e fai clic su “Crea terapia”.",
    "qrCodeMed.label.descHighlight": "www.tommedications.com",
    "qrCodeMed.button.scan": "Scansiona il codice QR.",
    "qrCodeMed.scan.label.title": "Ora punta il tuo smartphone verso il QR Code del sito web tommedications.com",
    "qrCodeMed.successDialog.label.title": "Farmaco inserito.",
    "qrCodeMed.successDialog.label.desc": "Il tuo farmaco è stato inserito.",
    "qrCodeMed.errorDialog.label.title": "Errore durante l’inserimento.",
    "qrCodeMed.errorDialog.label.desc": "Non è stato possibile inserire il tuo farmaco."        
}
export default it