import { all } from 'redux-saga/effects'
import formWizard from './formWizard'
import search from './search'
import unit from './unit'
import formValues from './formValues'

export default function* rootSaga() {
    yield all([
        formWizard(),
        search(),
        unit(),
        formValues()
    ])
}
