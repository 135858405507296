const fr = {
    "general.label.ok": "OK",
    "general.label.yes": "Oui",
    "general.label.no": "Non",
    "general.label.startOver": "Recommencer",
    "general.label.startOver.title": "Voulez-vous recommencer?",
    "general.label.startOver.desc": "Cela supprimera vos entrées et recommencera.",
    "general.label.confirm": "Valider",
    "general.label.skip": "Passer",
    "general.button.close": "Fermer",
    "general.label.cancel": "Annuler",
    "general.label.success": "Réussi",
    "general.label.notSpecified": "Pas d’indication",
    "general.error.label.title": "Erreur",
    "general.error.label.desc": "Oh mon Dieu, une erreur est survenue, veuillez réessayer ultérieurement.",
    "general.error.button.reload": "Réactualiser",
    "general.error.session.desc": "Session expirée",
    "general.label.timePostfix": "{amount}",
    "general.error.noQuantity": "Aucune quantité de stock disponible",
    "general.label.minutes": "{amount} minutes",    
    "tabBar.label.today": "Aujourd’hui",
    "tabBar.label.cabinet": "Armoire",
    "tabBar.label.more": "Plus",    
    "appInfo.label.title": "Gérer ses médicaments en toute sérénité",
    "appInfo.button.go": "C’est parti",
    "appInfo.button.recovery": "Restaurer les données",
    "appInfo.label.dsgvo": "En continuant, vous acceptez nos <a href=\"{amount}\">conditions d’utilisation</a> et notre <a href=\"{total}\">déclaration de confidentialité</a>.",
    "acceptTerms.label.title": "De nouvelles conditions sont disponibles. Accepter pour pouvoir continuer.",
    "acceptTerms.label.dsgvo": "En continuant, vous acceptez nos <a href=\"{amount}\">conditions d’utilisation</a> et notre <a href=\"{total}\">déclaration de confidentialité</a>.",
    "acceptTerms.button.accept": "Accepter et continuer",
    "migrate.label.title": "Bienvenue ! Nous avons entièrement revu l’application. Je vous souhaite une bonne santé et tout le meilleur pour l'avenir. TOM .",
    "migrate.button.go": "C’est parti",
    "migrate.label.dsgvo": "En continuant, vous acceptez nos <a href=\"{amount}\">conditions d’utilisation</a> et notre <a href=\"{total}\">déclaration de confidentialité</a>.",
    "welcome.label.navBarTitle": "Bienvenue",
    "welcome.label.textSegment.i0": "Bonjour! Je m’appelle TOM.",
    "welcome.label.textSegment.i1": "Je suis là pour vous aider à bien prendre vos médicaments.",
    "welcome.label.textSegment.i2": "On y va!",
    "welcome.web.label.textSegment.i1": "Je vais vous aider à créer un rappel pour vos médicaments",
    "welcome.web.label.textSegment.i2": "Cherchez d'abord le nom de votre médicament.",    
    "firstMed.label.navBarTitle": "Bienvenue",
    "firstMed.label.title": "Votre armoire à pharmacie est encore vide.\nAjoutez-y votre premier médicament.",
    "firstMed.button.add": "Ajouter un médicament",
    "firstMed.button.scan": "Scanner le code Web",
    "firstMed.button.later": "Non, je le ferai plus tard",    
    "create.label.navBarTitle.medicament.create": "Ajouter un médicament",
    "create.label.navBarTitle.medicament.edit": "Changer un médicament",
    "create.label.navBarTitle.reminder.create": "Ajouter un rappel",
    "create.dialog.quit.label.title": "Annuler la procédure",
    "create.dialog.quit.label.medicamentDesc": "Votre médicament n’a pas encore été sauvegardé. Souhaitez-vous vraiment annuler?",
    "create.dialog.quit.label.reminderDesc": "Votre rappel n’a pas encore été sauvegardé. Souhaitez-vous vraiment annuler?",
    "create.dialog.quit.button.continue": "Non, je continue",
    "create.dialog.quit.button.cancel": "Oui, je veux annuler",
    "create.selection.label.title": "Vous pouvez maintenant scanner le code-barre de l’emballage ou rechercher le nom du médicament.",
    "create.selection.button.camera": "Scanner le code-barre",
    "create.selection.button.search": "Recherche manuelle",
    "create.scan.label.title": "Placez votre téléphone face au code-barre ou au QR Code.",
    "create.scan.button.cameraNotAvailable": "Il n’est pour le moment pas possible de scanner le code-barre avec l’appareil photo. Veuillez vérifier le paramétrage de votre mobile ou opter pour une recherche manuelle !",
    "create.scan.alert.label.fail.title": "Échec",
    "create.scan.alert.label.fail.text": "Assurez-vous que les consignes suivantes sont respectées:  \r\n- Veillez à ce qu’il y ait suffisamment de luminosité. \r\n- Évitez les zones d’ombre",
    "create.scan.alert.button.fail.search": "Recherche manuelle",
    "create.scan.alert.button.fail.ok": "OK",
    "create.scan.error.label.title": "Erreur scan",
    "create.scan.error.label.notFound": "Impossible de trouver votre médicament",
    "create.scan.error.button.search": "Recherche manuelle",
    "create.scan.error.button.retry": "Réessayer",
    "create.search.label.text": "Veuillez indiquer le nom de votre médicament : ",
    "create.search.label.desc": "Ici, vous pouvez facilement saisir votre médicament avec un rappel et à la fin le transférer sur l'application. Le processus est simple et facile. Vous recherchez d'abord votre médicament dans notre base de données ou vous le saisissez vous-même.",
    "create.search.label.placeholder.normal": "Saisir le nom du médicament",
    "create.search.label.placeholder.active": "Nom du médicament",
    "create.search.label.yourInput": "Votre saisie",
    "create.search.button.switchDatabase": "Changer de pays",
    "create.search.label.searchResults": "Résultats de la recherche: ",
    "create.search.dialog.changeDb.label.title": "Changer la base de données des médicaments",
    "create.search.label.defaultDb": "Tous les pays",
    "create.alreadyInCabinet.label.title": "Armoire à pharmacie",
    "create.alreadyInCabinet.label.desc": "Le médicament \"{amount}\" est déjà dans votre armoire à pharmacie, souhaitez-vous quand même le rajouter?",
    "create.alreadyInCabinet.button.accept": "Ajouter",
    "create.alreadyInCabinet.button.decline": "Annuler",
    "create.reminderQuery.label.text": "Souhaitez-vous établir un rappel ou le mettre dans l’armoire et ne le prendre que si nécessaire?",
    "create.reminderQuery.button.reminder": "Oui, je veux un rappel",
    "create.reminderQuery.button.dashboard": "Non, dans l’armoire",
    "create.push.label.text": "Il me faut encore votre autorisation pour valider le rappel.",
    "create.push.button.next": "Continuer",
    "create.medInfo.label.title": "Souhaitez-vous aussi m’indiquer la date de péremption afin que je puisse vous prévenir à temps?",
    "create.medInfo.label.expirationDate": "Date de péremption",
    "create.medInfo.label.expirationDateNotificationTime": "Rappel avant expiration",
    "create.medInfo.label.expirationDateNotificationChangeInfo": "Vous pourrez modifier ce réglage ultérieurement",
    "create.medInfo.button.finish": "Continuer",
    "create.medInfo.button.skipInfo": "Finaliser sans date de péremption",
    "create.medInfo.dialog.exparationDate.label.title": "Indiquer la date de péremption",
    "create.intervalSelection.label.title": "Quelle fréquence souhaitez-vous définir pour le rappel?",
    "create.intervalSelection.label.daily.title": "Quotidien",
    "create.intervalSelection.label.daily.desc": "Une prise par jour",
    "create.intervalSelection.label.weekday.title": "Certains jours de la semaine",
    "create.intervalSelection.label.weekday.desc": "Plusieurs prises par semaine",
    "create.intervalSelection.label.days.title": "Tous les X jours",
    "create.intervalSelection.label.days.desc": "Intervalle en jours",
    "create.intervalSelection.label.hours.title": "Toutes les X heures",
    "create.intervalSelection.label.hours.desc": "Intervalle en heures",
    "create.intervalSelection.label.once.title": "Unique",
    "create.intervalSelection.label.once.desc": "Prise unique en cas de besoin",
    "create.dailyReminder.label.createFirst.title": "Quelle heure souhaitez-vous définir pour le rappel?",
    "create.dailyReminder.label.createAdditional.title": "Quelle autre heure souhaitez-vous définir pour le rappel?",
    "create.dailyReminder.label.edit.title": "Quelle(s) modification(s) souhaitez-vous apporter au rappel?",
    "create.dailyReminder.button.next": "Continuer",
    "create.dailyReminder.button.cancel": "Annuler",
    "create.dailyReminder.dialog.time.title": "Définir une heure",
    "create.dailyReminder.dialog.unit.title": "Modifier l’unité",
    "create.dailyReminder.dialog.quantitiy.title": "Définir une quantité",
    "create.dailyReminder.dialog.sameTime.title": "Heures du rappel identiques",
    "create.dailyReminder.dialog.sameTime.desc": "Vous ne pouvez pas créer deux rappels en même temps pour le même médicament. Veuillez modifier l’heure sélectionnée.",
    "create.dailyReminder.time.title": "Heure",
    "create.dailyReminder.unit.title": "Unité",
    "create.dailyReminder.quantitiy.title": "Quantité",
    "create.dailyReminderList.label.createFirst.title": "Vous avez défini votre première heure. Souhaitez-vous en ajouter une autre ?",
    "create.dailyReminderList.label.createAdditional.title": "Souhaitez-vous ajouter une autre heure?",
    "create.dailyReminderList.label.addTime": "Ajouter une autre heure",
    "create.dailyReminderList.button.next": "Continuer",
    "create.dailyReminderList.dialog.delete.label.title": "Supprimer l’heure?",
    "create.dailyReminderList.dialog.delete.label.desc": "Souhaitez-vous vraiment supprimer le rappel pour {amount}?",
    "create.dailyReminderList.dialog.delete.button.delete": "Oui, supprimer",
    "create.dailyReminderList.dialog.delete.button.cancel": "Annuler",
    "create.dailyReminderList.dialog.differentUnit.label.title": "Unités différentes",
    "create.dailyReminderList.dialog.differentUnit.label.desc": "Attention, vous avez défini des unités différentes pour le rappel. Nous ne pouvons donc pas calculer/surveiller vos réserves. Souhaitez-vous tout de même continuer?",
    "create.dailyReminderList.dialog.stockUnit.label.title": "Note",
    "create.dailyReminderList.dialog.stockUnit.label.desc": "Vous avez changé d'unité. Cela signifie que vous devez réintégrer le stock .",
    "create.dailyReminderList.dialog.stockUnit.button.change": "Continuer à stocker",
    "create.dailyReminder.label.time.postfix": " ",
    "create.dailyReminderList.label.reminder.postfix": "Rappel",
    "create.weekdayReminder.label.title": "Quels jours souhaitez-vous définir pour le rappel?",
    "create.weekdayReminder.label.monday.full": "Lundi",
    "create.weekdayReminder.label.monday.short": "Lun.",
    "create.weekdayReminder.label.tuesday.full": "Mardi",
    "create.weekdayReminder.label.tuesday.short": "Mar.",
    "create.weekdayReminder.label.wednesday.full": "Mercredi",
    "create.weekdayReminder.label.wednesday.short": "Mer.",
    "create.weekdayReminder.label.thursday.full": "Jeudi",
    "create.weekdayReminder.label.thursday.short": "Jeu.",
    "create.weekdayReminder.label.friday.full": "Vendredi",
    "create.weekdayReminder.label.friday.short": "Ven.",
    "create.weekdayReminder.label.saturday.full": "Samedi",
    "create.weekdayReminder.label.saturday.short": "Sam.",
    "create.weekdayReminder.label.sunday.full": "Dimanche",
    "create.weekdayReminder.label.sunday.short": "Dim.",
    "create.weekdayReminder.button.next": "Continuer",
    "create.daysReminder.label.title": "Combien de jours d’intervalle souhaitez-vous entre les rappels?",
    "create.daysReminder.button.days.title": "Intervalle en jours",
    "create.daysReminder.button.days.msg": "Tous les {amount} jours",
    "create.daysReminder.button.next": "Continuer",
    "create.daysReminder.dialog.days.title": "Sélectionner le nombre de jours d’intervalle",
    "create.hoursReminder.label.title": "Combien d’heures d’intervalle souhaitez-vous entre les rappels?",
    "create.hoursReminder.dialog.hours.title": "Sélectionner le nombre d’heures d’intervalle",
    "create.hoursReminder.button.hours.title": "Intervalle en heures",
    "create.hoursReminder.button.hours.msg": "Toutes les {amount} heures",
    "create.hoursReminder.button.timeStart.title": "Début de l’intervalle",
    "create.hoursReminder.button.timeEnd.title": "Fin de l’intervalle",
    "create.hoursReminder.dialog.timeStart.title": "Indiquer le début de l’intervalle",
    "create.hoursReminder.dialog.timeEnd.title": "Indiquer la fin de l’intervalle",
    "create.hoursReminder.button.unit.title": "Unité",
    "create.hoursReminder.button.quantitiy.title": "Quantité",
    "create.hoursReminder.label.time.postfix": " ",
    "create.hoursReminder.dialog.unit.title": "Modifier l’unité",
    "create.hoursReminder.dialog.quantitiy.title": "Définir une quantité",
    "create.hoursReminder.errorDialog.endDateInvalid.title": "Fin d’intervalle non valide",
    "create.hoursReminder.errorDialog.endDateInvalid.desc": "La fin de l’intervalle ne peut pas être définie avant son début.",
    "create.hoursReminder.errorDialog.endDateInvalid.interval.desc": "La fin de l’intervalle ne peut pas être définie avant son début.",
    "create.hoursReminder.errorDialog.startDateInvalid.title": "Début d’intervalle non valide",
    "create.hoursReminder.errorDialog.startDateInvalid.desc": "Le début de l’intervalle ne peut pas être défini après la fin de celui-ci.",
    "create.hoursReminder.button.next": "Continuer",
    "create.onceReminder.label.title": "Quelle date souhaitez-vous définir pour votre rappel unique?",
    "create.onceReminder.dialog.date.title": "Sélectionner la date",
    "create.onceReminder.button.date.title": "Date",
    "create.onceReminder.button.time.title": "Heure",
    "create.onceReminder.button.unit.title": "Unité",
    "create.onceReminder.button.quantitiy.title": "Quantité",
    "create.onceReminder.dialog.time.title": "Définir une heure",
    "create.onceReminder.dialog.unit.title": "Modifier l’unité",
    "create.onceReminder.dialog.quantitiy.title": "Définir une quantité",
    "create.onceReminder.label.time.postfix": " ",
    "create.onceReminder.button.next": "Continuer",
    "create.period.label.title": "Quelle est la durée du traitement?",
    "create.period.label.startTime": "Début du traitement",
    "create.period.label.periodType": "Durée du traitement",
    "create.period.label.periodType.continuous": "Continu",
    "create.period.label.periodType.endDate": "Jusqu’à la date de fin",
    "create.period.label.endDate": "Fin du traitement",
    "create.period.dialog.startDate.title": "Sélectionner une date de début",
    "create.period.dialog.endDate.title": "Sélectionner une date de fin",
    "create.period.label.days": "Nombre de jours",
    "create.period.dialog.days.title": "Sélectionner un nombre de jours",
    "create.period.button.next": "Continuer",
    "create.stock.label.title": "Gardez une vue d'ensemble de votre stock!",
    "create.stock.label.hint.stockInactive": "Indiquer la quantité actuellement en réserve",
    "create.stock.label.hint.stockActive": "Quantité en réserve",
    "create.stock.label.hint.unit": "Unité",
    "create.stock.label.expirationDateNotificationTime": "Contrôle des stocks",
    "create.stock.label.expirationDateNotificationChangeInfo": "TOM met à jour le nombre de comprimés dans votre stock à chaque fois que vous les prenez et vous informe si vous devez les commander à nouveau .",
    "create.stock.button.next": "Continuer",
    "create.stock.dialog.label.stock.title": "Quantité actuellement en réserve",
    "create.stock.dialog.label.unit.title": "Modifier l’unité",
    "create.intakeInfo.label.title": "Comment le médicament doit-il être pris?",
    "create.intakeInfo.label.intakeOptions.title": "Remarque relative à la prise",
    "create.intakeInfo.label.intakeOptions.preMeal": "Avant le repas",
    "create.intakeInfo.label.intakeOptions.meal": "Pendant le repas",
    "create.intakeInfo.label.intakeOptions.postMeal": "Après le repas",
    "create.intakeInfo.label.intakeOptions.none": "Aucune information",
    "create.intakeInfo.label.changeInfo": "Vous pourrez par la suite changer ces informations à tout moment",
    "create.intakeInfo.button.finish": "Continuer",
    "create.summary.label.title": "Toutes les informations relatives à cette saisie sont-elles correctes?",
    "create.summary.label.interval": "Intervalle",
    "create.summary.label.reminder": "{amount}. Rappel",
    "create.summary.label.emptyReminder": "Rappel",
    "create.summary.label.therapyPeriod.title": "Durée du traitement",
    "create.summary.label.therapyPeriod.continuous.desc": "à partir de {amount}",
    "create.summary.label.stock": "Quantité en réserve",
    "create.summary.label.expiryDate": "Date de péremption",
    "create.summary.label.intakeInfo": "Remarque relative à la prise",
    "create.summary.label.notes": "Note",
    "create.summary.dialog.notes.label.title": "Ajouter une note",
    "create.summary.dialog.notes.placeholder.active": "Note",
    "create.summary.dialog.notes.placeholder.inactive": "But, particularités, recommandations médicales, etc.",
    "create.summary.label.reminder.weekdays": "Jours",
    "create.summary.label.reminder.dayInterval.title": "Intervalle en jours",
    "create.summary.label.reminder.dayInterval.desc": "tous les {amount} jours",
    "create.summary.label.reminder.hourInterval.title": "Intervalle en heure",
    "create.summary.label.reminder.hourInterval.desc": "toutes les {amount} heures",
    "create.summary.label.reminder.hourPeriod.title": "Durée de l’intervalle",
    "create.summary.label.reminder.hourPeriod.desc": "de {amount} à {total}",
    "create.summary.label.intervalOnce.date": "Date",
    "create.summary.label.intervalOnce.time": "Heure",
    "create.summary.label.dosage": "Quantité",
    "create.summary.button.ok": "Oui, tout est correct",
    "create.short.summary.label.title": "Voulez-vous ajouter le médicament suivant à votre armoire ?",
    "create.short.summary.button.add": "Oui, ajoutez",   
    "create.web.summary.label.title": "C'est aussi simple que ça dans TOM.", 
    "create.web.summary.label.desc": "Si vous le souhaitez, je peux transférer votre thérapie directement vers l'application gratuite.",
    "create.web.summary.button.add": "Oui volontiers.",
    "create.web.summary.button.cancel": "Non, pas nécessaire.",
    "qr.code.title": "Avec ce code QR, vous pouvez gérer vos médicaments directement dans l'application. Pour ce faire, scannez le code avec l'application mobile TOM:",
    "qr.code.desc": "Vous n'avez pas encore l'application TOM? Ensuite, installez-la gratuitement en utilisant les liens ci-dessous, puis vous pourrez scanner le code dans l'application.", 
    "cabinet.label.navBarTitle": "Armoire à pharmacie",
    "cabinet.label.stock": "Réserves",
    "cabinet.label.expiry": "Expiration",
    "cabinet.label.notSpecified": "Aucune indication",
    "cabinet.filter.label.title": "Filtre",
    "cabinet.filter.label.all": "Tout montrer",
    "cabinet.filter.label.withReminder": "Avec rappel",
    "cabinet.filter.label.withoutReminder": "Sans rappel",
    "cabinet.filter.label.withPrescription": "Soumis à ordonnance",
    "cabinet.filter.label.withoutPrescription": "Non soumis à ordonnance",
    "cabinet.dialog.sorting.label.title": "Sélectionnez le tri",
    "cabinet.dialog.sorting.label.name.asc": "Nom {ASC}",
    "cabinet.dialog.sorting.label.name.desc": "Nom {DESC}",
    "cabinet.dialog.sorting.label.expiryDate.asc": "Date d'expiration {ASC}",
    "cabinet.dialog.sorting.label.expiryDate.desc": "Date d'expiration {DESC}",
    "cabinet.dialog.sorting.label.stock.asc": "Stock {ASC}",
    "cabinet.dialog.sorting.label.stock.desc": "Stock {DESC}",    
    "detail.label.navBarTitle": "Aperçu des médicaments",
    "detail.label.title": "Vos indications: ",
    "detail.label.editTitle": "Vous pouvez désormais modifier votre saisie : ",
    "detail.button.save": "Sauvegarder",
    "detail.button.addReminder": "Ajouter un rappel",
    "detail.dialog.deleteSelection.label.title": "Supprimer",
    "detail.dialog.deleteSelection.label.desc": "Vous voulez effacer tout le médicament ou seulement le rappel ?",
    "detail.dialog.deleteSelection.button.med": "Médicament",
    "detail.dialog.deleteSelection.button.reminder": "Rappel",
    "detail.dialog.deleteConfirm.label.title": "Supprimer vraiment ?",
    "detail.dialog.deleteConfirm.label.reminderDesc": "Voulez-vous vraiment supprimer le rappel ?",
    "detail.dialog.deleteConfirm.label.medDesc": "Voulez-vous vraiment effacer le médicament ?",
    "detail.dialog.deleteConfirm.button.yes": "Oui, supprimer.",
    "detail.dialog.deleteConfirm.button.no": "Non, abandonnez.",
    "detail.success.label.text": "Les entrées ont été mises à jour avec succès.",
    "today.label.next.title": "Prochaine\nprise dans",
    "today.label.next.at": "à",
    "today.label.taken.title": "Bien\npris",
    "today.label.taken.at": "le {amount} à",
    "today.label.skipped.title": "Pas\npris",
    "today.label.skipped.at": "le {amount} à",
    "today.label.overdue.title": "Prise\nen retard depuis",
    "today.label.overdue.at": "Aujourd’hui à",
    "today.label.planned.title": "Prise\nprévue dans",
    "today.label.planned.day": "1 jour",
    "today.label.planned.days": "{amount} Jours",
    "today.label.planned.at": "le {amount} à",
    "today.button.take": "Prendre maintenant",
    "today.button.later": "Reporter",
    "today.button.skip": "Ne pas prendre",
    "today.emptyState.label.text": "Vous n’avez pas de médicaments à prendre aujourd’hui",
    "today.label.clock": "{amount}",
    "today.postponeDialog.label.title": "De combien de temps souhaitez-vous reporter la prise du médicament ?",
    "today.postponeDialog.label.content": "{amount} minutes",
    "today.postponeDialog.button.later": "Reporter",
    "today.postponeConfirmDialog.label.title": "Reporter la prise",
    "today.postponeConfirmDialog.label.desc": "Êtes-vous sûr de vouloir reporter la prise du médicament de {amount} minutes ?",
    "today.postponeConfirmDialog.button.later": "Reporter",
    "today.label.selectDay": "Sélectionnez le jour",
    "today.intakeInfo.label.confirmed": "Prise à {amount}",
    "today.intakeInfo.label.postponed": "Reporté à {amount}",
    "today.intakeInfo.label.overdue": "En retard depuis {amount}",
    "today.intakeInfo.label.skipped": "Omis.\n{amount}",
    "today.intakeInfo.label.skippedNoReason": "Omis.",
    "today.actionSheet.label.takeNow": "Prenez maintenant",
    "today.actionSheet.label.takeAllNow": "Prenez-les tous maintenant",
    "today.actionSheet.label.takeOnPoint": "Prise à l'heure à {amount}",
    "today.actionSheet.label.takeAllOnPoint": "Tout a été pris à l'heure à {amount}",
    "today.actionSheet.label.takeOtherTime": "Pris à un autre moment",
    "today.actionSheet.label.takeAllOtherTime": "Toutes prises à un moment différent",
    "today.actionSheet.label.skip": "Omettre",
    "today.actionSheet.label.skipAll": "Sauter tout",
    "today.actionSheet.label.postpone": "Reporter",
    "today.actionSheet.label.postponeAll": "Déplacer tout",
    "today.actionSheet.label.titleAll": "Toutes les médicaments à {amount}",
    "today.actionSheet.label.reset": "Rétablir le statut de prendre",
    "today.timePicker.label.title": "Définir une heure",
    "today.listBottomButtons.button.takeAllNow": "Prenez-les tous maintenant",
    "today.listBottomButtons.button.takeAllOnPoint": "Tous pris à temps",
    "today.listBottomButtons.button.skipAll": "Sauter tout",
    "today.listBottomButtons.button.takeAllOnOtherPoint": "Toutes prises à un moment différent",
    "today.error.batchIntakes": "Nous sommes désolés, tous les apports n'ont pas pu être mis à jour, veuillez réessayer plus tard.",    
    "actionSheet.label.new": "Nouveau",    
    "addEntry.label.title": "Ajouter une entrée",
    "addEntry.label.med": "Ajouter un médicament",
    "addEntry.label.mediCheck": "Medi-Check",
    "addEntry.label.qrCodeMed": "Scanner le code Web",    
    "invalidCredentials.label.title": "Une erreur est survenue lors du processus d’authentification.",
    "invalidCredentials.label.text": "Veuillez réessayer. Ou écrivez-nous à support(at)innovation6.ch et nous essaierons de vous aider !",
    "invalidCredentials.button.retry": "Réessayer",
    "invalidCredentials.button.logout": "Déconnexion",
    "invalidCredentials.logoutConfirm.label.title": "Déconnexion",
    "invalidCredentials.logoutConfirm.label.text": "En poursuivant, toutes vos données saisies seront effacées de cet appareil.",
    "invalidCredentials.logoutConfirm.button.accept": "Continuer",
    "invalidCredentials.logoutConfirm.button.cancel": "Annuler",    
    "loadingCurrentUserFailed.label.title": "Malheureusement, quelque chose s'est mal passé lors du chargement de vos données.",
    "loadingCurrentUserFailed.label.text": "Veuillez réessayer. Ou écrivez-nous à support(at)innovation6.ch et nous essaierons de vous aider !",
    "loadingCurrentUserFailed.button.retry": "Réessayer",    
    "loadingGTCFailed.label.title": "Quelque chose s'est mal passé lors du chargement des conditions générales.",
    "loadingGTCFailed.label.text": "Veuillez réessayer. Ou écrivez-nous à support(at)innovation6.ch et nous essaierons de vous aider !",
    "loadingGTCFailed.button.retry": "Réessayer",    
    "defaultError.label.title": "Une erreur est survenue.",
    "defaultError.label.text": "Veuillez réessayer. Ou écrivez-nous à support(at)innovation6.ch et nous essaierons de vous aider !",
    "defaultError.button.retry": "Réessayer",    
    "confetti.label.title": "Félicitations !\nVous avez enregistré votre premier médicament.",
    "confetti.button.next": "Continuer",    
    "more.label.navBarTitle": "Plus",
    "more.profile.label.title": "Données personnelles",
    "more.profile.label.allergies": "Allergies",
    "more.medicheck.label.title": "Medi-Check",
    "more.medicheck.label.desc": "Le Medi-Check est réalisé par une pharmacie suisse et peut être en mesure de vous donner de nouveaux conseils sur les interactions, la double médication et plus encore. Facile et confortable depuis la maison.",
    "more.legal.label.title": "Informations juridiques",
    "more.legal.label.agb": "Conditions d’utilisation",
    "more.legal.label.dsg": "Déclaration de confidentialité",
    "more.legal.label.imprint": "Impressum",
    "more.legal.label.delete": "Supprimer les données",
    "more.legal.label.messageSupport": "Message au support",
    "more.legal.label.updateNews": "Aperçu 2020",
    "more.label.version": "Note de mise à jour {amount}",
    "more.settings.label.design.title": "Modifier le design",
    "more.settings.label.design.desc": "Sélectionnez le design standard TOM ou un design premium Dark Mode ou Color Mode.",
    "more.settings.label.push.title": "Adapter les rappels",
    "more.settings.label.push.desc": "Déterminez le type et l’intensité des rappels, des sons et si les noms des médicaments doivent être affichés sur le rappel.",
    "more.settings.label.title": "Paramètres",
    "more.settings.label.desc": "Cet écran permet d’effectuer d’autres réglages pour les rappels et les sons ou de sélectionner un autre design.",
    "more.label.userId": "Identifiant utilisateur",
    "more.premium.label.title": "Fonctionnalités premium",
    "more.premium.label.desc": "Avec les fonctions premium, vous bénéficiez de nombreux avantages, d’une plus grande flexibilité avec de nouveaux designs, sons, rappels, et plus encore.",
    "imprint.label.navBarTitle": "Impressum",
    "imprint.label.cooperation": "Innovation 6 AG",
    "imprint.label.address": "Pfäffikerstrasse 109,\n8615 Wermatswil,\nSuisse",
    "imprint.label.uid": "IDE :  CHE-263.807.653",
    "imprint.label.ceo": "Directeur général :  Sven Beichler",
    "imprint.label.mail": "E-mail :  support@innovation6.ch",
    "imprint.label.web": "Site Web :  www.tommedications.com",    
    "revokeData.label.navBarTitle": "Supprimer les données",
    "revokeData.label.title": "Êtes-vous sûr de vouloir supprimer vos données ? Cette opération est irréversible !",
    "revokeData.button.revoke": "Oui, supprimer les données",
    "revokeData.button.keep": "Non, ne pas supprimer",
    "revokeData.confirmDialog.label.title": "Supprimer définitivement les données ?",
    "revokeData.confirmDialog.label.desc": "Êtes-vous absolument sûr de vouloir supprimer vos données ?",
    "revokeData.confirmDialog.button.delete": "Oui, supprimer définitivement",
    "revokeData.closeDialog.label.title": "Données supprimées",
    "revokeData.closeDialog.label.desc": "Vos données ont été irrévocablement supprimées. Nous espérons vous revoir bientôt !",
    "revokeData.closeDialog.button.bye": "Au revoir !",    
    "ommitReason.label.navBarTitle": "Sauter le médicament",
    "ommitReason.label.title": "Y a-t-il une raison particulière pour laquelle vous ne prenez pas vos médicaments ?",
    "ommitReason.button.noReason": "Rien à ajouter",
    "ommitReason.label.storageHint": "Les informations concernant un médicament oublié seront notées dans vos statistiques et peuvent fournir des informations importantes à votre médecin .",
    "ommitReason.label.reasonHint": "Sélectionnez le motif",    
    "updateDialog.label.title": "Mise à jour disponible",
    "updateDialog.label.message": "Une nouvelle version de {amount} est disponible.",
    "updateDialog.button.update": "Mettre à jour",    
    "mediCheck.teaser.screen1.label.title": "Le Medi-Check",
    "mediCheck.teaser.screen1.label.desc": "Le Medi-Check vérifie vos médicaments confortablement et en toute sécurité depuis la maison pour les interactions et bien plus encore.",
    "mediCheck.teaser.screen1.label.hint": "Réalisé personnellement par la pharmacienne FPH Anja Bauert d'Apotheke Witikon / ZH toppharm.",
    "mediCheck.teaser.screen1.button.close": "Fermer",
    "mediCheck.teaser.screen1.button.learnMore": "Continuer",
    "mediCheck.teaser.screen2.label.title": "Le Medi-Check comprend desinformations sur: ",
    "mediCheck.teaser.screen2.label.desc.full": "\n- Indications\n- Interactions\n- Double médication\n- Conseils sur la prise de médicaments\n- Conseils pour les allergies\n\nEnvoyez vos médicaments directement depuis TOM pour vérification !",
    "mediCheck.teaser.screen2.label.desc.highlighted": "- Indications\n- Interactions\n- Double médication\n- Conseils sur la prise de médicaments\n- Conseils pour les allergies",
    "mediCheck.teaser.screen2.button.close": "Fermer",
    "mediCheck.teaser.screen2.button.continue": "Démarrer maintenant",
    "mediCheckList.label.navBarTitle": "Medi-Checks précédents",
    "mediCheckList.label.share": "Partager le Medi-Check",
    "mediCheckList.switch.label.open": "Ouvrir",
    "mediCheckList.openSection.label.paymentNotCompleted": "Paiement non effectué",
    "mediCheckList.openSection.label.inProgress": "En cours",
    "mediCheckList.openSection.label.inquiry": "Demande",
    "mediCheckList.switch.label.finished": "Fait",
    "mediCheckList.button.startMediCheck": "Démarrer un nouveau Medi-Check",
    "mediCheckList.empty.label.open": "Actuellement, vous n'avez pas de Medi-Checks ouverts",
    "mediCheckList.empty.label.finished": "Aucun de vos contrôles de médiation n'a été effectué. Veuillez patienter encore un peu.",
    "mediCheckList.empty.label.general": "Vous n'avez pas encore mis en place un Medi-Check. En un clic, vous pouvez vérifier vos médicaments dans le confort de votre foyer.",
    "mediCheckList.label.transmitted": "Transmis le {amount}",
    "mediCheckList.label.recieved": "Reçu sur {amount}",
    "mediCheckCreation.label.navBarTitle": "Medi-Check",
    "mediCheckCreation.medSelection.label.title": "Ce sont vos drogues dans le cabinet. Sélectionnez un maximum de 6 médicaments ou ajoutez-en de nouveaux.",
    "mediCheckCreation.medSelection.label.hintCabinet": "Les drogues de votre armoire",
    "mediCheckCreation.medSelection.label.addMed": "Ajouter un médicament",
    "mediCheckCreation.medSelection.button.next": "Continuer",
    "mediCheckCreation.medSelection.error.limitReached": "Vous avez déjà sélectionné le nombre maximum de médicaments possibles.",
    "mediCheckCreation.medSelection.error.sameArticle": "Vous avez sélectionné deux fois le même médicament pour le Medi-Check. Veuillez modifier votre sélection.",
    "mediCheckCreation.medSelection.error.selectMore.label.title": "Trop peu de médicaments ont été sélectionnés.",
    "mediCheckCreation.medSelection.error.selectMore.label.desc": "Vous devez sélectionner au moins 2 médicaments",
    "mediCheckCreation.dialogQuit.label.title": "Annuler le MediCheck",
    "mediCheckCreation.dialogQuit.label.desc": "Vous êtes sûr de ne pas vouloir faire faire un Medi-Check ?",
    "mediCheckCreation.dialogQuit.button.continue": "Non, continuez",
    "mediCheckCreation.dialogQuit.button.cancel": "Oui, abandonnez",
    "mediCheckCreation.allergieSelection.label.title": "Avez-vous des allergies ?",
    "mediCheckCreation.allergieSelection.label.allergyType.food": "Allergie alimentaire",
    "mediCheckCreation.allergieSelection.label.allergyType.medication": "Allergie aux médicaments",
    "mediCheckCreation.allergieSelection.label.allergyType.other": "Autres allergies",
    "mediCheckCreation.allergieSelection.label.addAllergie": "Ajouter une autre allergie",
    "mediCheckCreation.allergieSelection.label.noAllergiesSoFar": "Vous n'avez pas encore déclaré d'allergies. Si vous avez des allergies, ces informations sont importantes pour qu'elles puissent être prises en compte dans le Medi-Check.",
    "mediCheckCreation.allergieSelection.button.next": "Continuer",
    "mediCheckCreation.allergieSelection.button.nextWithoutAllergies": "Plus loin sans allergies",
    "mediCheckCreation.allergieSelection.error.limitReached": "Vous avez déjà sélectionné le nombre maximum possible d'allergies.",
    "mediCheckCreation.summary.label.title": "Résumé des éléments sélectionnés pour votre Medi-Check",
    "mediCheckCreation.summary.label.allergyType.food": "Allergies alimentaires sélectionnées",
    "mediCheckCreation.summary.label.allergyType.medication": "Allergies à certains médicaments",
    "mediCheckCreation.summary.label.allergyType.other": "Quelques autres allergies",
    "mediCheckCreation.summary.label.meds": "Médicaments sélectionnés",
    "mediCheckCreation.summary.label.editMeds": "Editer les médicaments",
    "mediCheckCreation.summary.label.editAllergies": "Modifier les allergies",
    "mediCheckCreation.summary.label.legalDisclaimer": "J'ai compris que le Medi-Check ne prend en compte que les médicaments et les allergies qui ont été transmis et ne peut remplacer la consultation d'un médecin ou d'un pharmacien.",
    "mediCheckCreation.summary.button.buy": "Acheter",
    "mediCheckCreation.summary.label.paymentInProgress": "Votre dernier achat est encore en cours de validation. L'achat n'a donc pas pu être effectué, veuillez réessayer plus tard.",
    "mediCheckDetails.label.id": "Medi-Check ID",
    "mediCheckDetails.label.buyInfo": "Il y a eu un problème lors de l'achat du Medi-Check, veuillez réessayer en cliquant sur \"Acheter maintenant\". Nous vérifions si le dernier achat a réussi. Si tel est le cas, il sera utilisé et aucune nouvelle procédure d'achat ne sera lancée.",
    "mediCheckDetails.button.buy": "Acheter maintenant",
    "mediCheckDetails.label.pharmacyInquiry": "Il y a des questions de la pharmacie concernant votre Medi-Check. Contactez-les dès que possible.",
    "mediCheckDetails.label.pharmacyInquiry.info": "Tant que la question n'a pas été clarifiée, votre Medi-Check ne peut pas être rempli. Veuillez contacter la pharmacie immédiatement.",
    "mediCheckDetails.label.pharmacyInquiry.question": "QUESTION: \n\"{amount}\" ",
    "mediCheckDetails.label.pharmacyInfo.name": "TopPharm Apotheke Witikon",
    "mediCheckDetails.label.pharmacyInfo.address": "Carl Spitteler-Strasse 2\n8053 Zürich",
    "mediCheckDetails.label.pharmacyInfo.website": "https: //www.apotheke.ch/witikon",
    "mediCheckDetails.label.pharmacyInfo.mail": "info@witikon.apotheke.ch",
    "mediCheckDetails.label.payment.in.progress": "Votre dernier achat est encore en cours de validation. Cela peut prendre quelques minutes avant que vous puissiez faire un nouvel achat.",
    "pharmacy.overview.label.pharmacy": "Farmacia",    
    "allergies.label.navBarTitle": "Mes allergies",
    "allergies.empty.label.title": "Avez-vous une ou plusieurs allergies aux médicaments, aux aliments ou autres ?",
    "allergies.empty.button.add": "Ajouter une allergie",
    "allergies.label.title": "Vos allergies précédentes : ",
    "allergies.button.add": "Ajouter une allergie",
    "allergies.button.save": "Sauvegarder",
    "allergies.label.allergyType.food": "Allergie alimentaire",
    "allergies.label.allergyType.medication": "Allergie aux médicaments",
    "allergies.label.allergyType.other": "Autres allergies",
    "allergies.addDialog.label.title": "Ajouter une allergie",
    "allergies.search.label.title": "Veuillez indiquer le nom de vos allergies : ",
    "allergies.textInput.label.title": "Veuillez indiquer le nom de vos allergies : ",
    "allergies.search.label.medHint": "Allergies aux médicaments et aux ingrédients",
    "allergies.search.label.searchFieldHint": "Recherche sur les allergies",
    "allergies.textInput.label.foodHint": "Allergie alimentaire",
    "allergies.textInput.label.otherHint": "Autres allergies",
    "allergies.textInput.label.textFieldHint": "Entrez allergie",
    "allergies.textInput.button.confirm": "Valider",
    "premium.navBar.title": "Premium",
    "premium.label.tag": "Premium",
    "premium.label.tagActive": "Premium activé",
    "premium.label.title": "Pourrait-il être un peu plus ? Avec TOM Premium-Features profitez de nombreux avantages: ",
    "premium.label.alarmSettings.title": "Paramètres d'alarme",
    "premium.label.alarmSettings.desc": "Sélectionnez l’alarme parmi les options suivantes :  pile à l’heure, avant, après, ou les trois.",
    "premium.label.ringtoneChoice.title": "Choix de sonnerie",
    "premium.label.ringtoneChoice.desc": "En plus du son standard, jusqu’à 20 sons différents sont disponibles au choix.",
    "premium.label.design.title": "Design",
    "premium.label.design.desc": "Vous préférez un design plus sombre avec le Dark Mode ou plutôt de la couleur, avec le Color Mode ? Faites votre choix parmi trois designs.",
    "premium.label.cabinet.title": "Armoire",
    "premium.label.cabinet.desc": "Trop de médicaments dans l’armoire ? Avec le nouvel aperçu sous forme de liste, les choses seront plus claires.",
    "premium.label.sorting.title": "Trier",
    "premium.label.sorting.desc": "Désormais, triez également votre armoire à pharmacie selon les stocks ou la date d’expiration.",
    "premium.label.subscriptionModels": "Modèles d'abonnements",
    "premium.label.monthly.title": "Abonnement mensuel",
    "premium.label.monthly.desc": "Annulable à tout moment à la fin du mois!",
    "premium.label.yearly.title": "Abonnement annuel",
    "premium.label.yearly.desc": "Notre Bestseller:  économisez 25%!",
    "premium.button.subscribeNow": "Abonnez-vous maintenant",
    "premium.label.withClickOnButton": "En cliquant sur le bouton, vous serez redirigé vers le fabricant",
    "premium.label.manufacturer": "fabricant",
    "premium.label.automaticRenew": "L'abonnement se renouvelle automatiquement dans les 24 heures précédant L'expiration de l'abonnement actuel",
    "premium.label.within24Hours": "dans les 24 heures",
    "premium.label.privacyAndTerms": "En souscrivant un abonnement, vous acceptez nos <a href=\"{amount}\">Condizioni di utilizzo</a> e <a href=\"{amount}\">l’Informativa sulla privacy</a>",
    "premium.label.termsOfUse": "Conditions D'utilisation",
    "premium.label.privacyPolicy": "Politique de confidentialité",    
    "pushSettings.label.navBarTitle": "Personnalisation des rappels",
    "pushSettings.label.standard": "Paramètres par défaut",
    "pushSettings.label.premiumSettings": "Paramètres premium",
    "pushSettings.label.title": "Vous pouvez définir ici le type de rappel que vous souhaitez.",
    "pushSettings.label.nowBecomeMember": "Devenez membre Premium maintenant et profitez de nombreux avantages!",
    "pushSettings.label.sendReminders.title": "Envoyer des rappels",
    "pushSettings.label.sendNoReminders.desc.inactive": "Si vous ne souhaitez plus recevoir de rappels de TOM, activez cette fonction.",
    "pushSettings.label.sendNoReminders.desc.active": "Si vous souhaitez toujours recevoir des rappels de TOM, désactivez cette fonction.",
    "pushSettings.label.hideMedicamentName.title": "Masquer le nom des médicaments",
    "pushSettings.label.hideMedicamentName.desc.active": "Si vous souhaitez voir les noms des médicaments sur vos notifications Push, désactivez cette fonction.",
    "pushSettings.label.hideMedicamentName.desc.inactive": "Si vous souhaitez éviter les regards curieux sur vos notifications Push, activez cette fonction.",
    "pushSettings.label.notificationWithoutSound.title": "Notification avec vibreur",
    "pushSettings.label.notificationWithoutSound.desc.inactive": "Si vous souhaitez recevoir des rappels sans son, activez cette fonction.",
    "pushSettings.label.notificationWithoutSound.desc.active": "Si vous souhaitez toujours recevoir des rappels avec le son, désactivez cette fonction. Veillez à ce que votre téléphone ne soit pas en mode silencieux.",
    "pushSettings.label.changeTone.title": "Modifier le son de notification",
    "pushSettings.label.changeTone.desc.active": "Sélectionner une sonnerie de rappel personnelle",
    "pushSettings.label.changeTone.desc.inactive": "Sélectionner une sonnerie de rappel personnelle",
    "pushSettings.label.intensity.title": "Régler l’intensité de la notification",
    "pushSettings.label.intensity.desc.active": "Vous pouvez définir vous-même l’intensité du rappel",
    "pushSettings.label.intensity.desc.inactive": "Vous pouvez définir vous-même l’intensité du rappel",
    "pushSettings.label.premium": "Premium",
    "pushSettings.label.more": "Plus",
    "pushSettings.label.premiumActive": "Premium activé",
    "pushSettings.label.onSpecifiedTime.title": "À l’heure définie",
    "pushSettings.label.onSpecifiedTime.desc.active": "Le rappel a lieu à l’heure définie",
    "pushSettings.label.onSpecifiedTime.desc.inactive": "Aucun rappel n’aura lieu à l’heure de rappel indiquée.",
    "pushSettings.label.before.title": "Avant",
    "pushSettings.label.before.desc.active": "Un rappel supplémentaire sera envoyé 10 minutes avant l’heure précise de prise.",
    "pushSettings.label.before.desc.inactive": "Aucun rappel supplémentaire ne sera envoyé avant l’heure précise de prise.",
    "pushSettings.label.before.placeholder": "Avant",
    "pushSettings.label.after.title": "Après",
    "pushSettings.dialog.timeSelection.label.title": "Sélectionner l’heure",
    "pushSettings.label.after.desc.active": "Un rappel supplémentaire sera envoyé 10 minutes après l’heure précise de prise.",
    "pushSettings.label.after.desc.inactive": "Aucun rappel supplémentaire ne sera envoyé après l’heure précise de prise.",
    "pushSettings.label.after.placeholder": "Après",
    "pushSettings.label.pushSound": "Sonnerie de rappel",
    "pushSettings.dialog.pushSound.label.title": "Sélectionnez votre sonnerie",
    "pushSettings.label.soundFile.gooseWalk": "La marche des oies",
    "pushSettings.label.soundFile.majorBlues.140": "Blues",
    "pushSettings.label.soundFile.majorBlues.90": "Je suis cool",
    "pushSettings.label.soundFile.bell": "Petites cloches",
    "pushSettings.label.soundFile.ringing": "Clochette nerveuse",
    "pushSettings.label.soundFile.dark.ringing": "Taaa!",
    "pushSettings.label.soundFile.bright.ringing": "Clin d'œil",
    "pushSettings.label.soundFile.cowbells": "La cloche d'arrêt",
    "pushSettings.label.soundFile.techno": "Techno",
    "pushSettings.label.soundFile.jazzTrumpets": "Trompettes de jazz",
    "pushSettings.label.soundFile.mandolin": "Mandoline",
    "pushSettings.label.soundFile.orchestraString": "Beethoven",
    "pushSettings.label.soundFile.birds": "Chant des oiseaux",
    "pushSettings.label.soundFile.orchestraTrumpets": "Trompettes d'orchestre",
    "pushSettings.label.soundFile.pianoSurprise": "Piano comme ça",
    "pushSettings.label.soundFile.pianoFastAsLightning": "Le piano d'une autre façon",
    "pushSettings.label.soundFile.retro": "Ondes rétro",
    "pushSettings.label.soundFile.scienceFiction": "Science Fiction",
    "pushSettings.label.soundFile.digitalPeep": "Tout numérique",
    "pushSettings.label.soundFile.trumpet": "Trompette de style libre",    
    "customTone.navBar.title": "Sonneries de rappel",    
    "design.label.navBarTitle": "Modifier le design",
    "design.label.title": "Envie de nouveauté ? Vous pouvez également modifier l’apparence de l’application.",
    "design.label.modeDark": "Mode sombre",
    "design.label.modeLight": "Mode clair",
    "design.label.modeColor": "Mode coloré",
    "design.selection.light.label.title": "Mode clair (standard)",
    "design.selection.light.label.desc": "Notre mode clair de base – chic, intemporel et gratuit pour tous les utilisateurs de TOM !",
    "design.selection.dark.label.title": "Mode sombre",
    "design.selection.dark.label.desc": "Économiser de la batterie tout en ménageant les yeux ? C’est possible grâce au mode sombre de TOM.",
    "design.selection.color.label.title": "Mode coloré",
    "design.selection.color.label.desc": "Pour celles et ceux qui veulent mettre des couleurs dans leur vie, TOM est disponible en version multicolore.",
    "design.label.premiumTag": "Premium",
    "design.label.getPremium": "Devenez membre premium pour profiter de nombreux avantages !",
    "design.button.getPremium": "Plus",
    "design.button.save": "Sauvegarder",    
    "restoreIAP.label.title": "Si vous venez de réinstaller l’application, vous pouvez restaurer vos achats ici.",
    "restoreIAP.button.restore": "Restaurer les achats",
    "restoreIAP.error.label.title": "Échec de la restauration",
    "restoreIAP.error.label.desc": "Nous ne sommes pas parvenus à restaurer vos achats, veuillez réessayer ultérieurement.",
    "restoreIAP.label.nothingToRestore": "Il n’y a aucun achat à restaurer.",
    "restoreIAP.label.restoreSuccess": "Restauration réussie.",    
    "qrCodeMed.label.navBarTitel": "Ajouter un médicament",
    "qrCodeMed.label.title": "Vous pouvez désormais scanner simplement le code QR de votre traitement que vous avez créé sur tommedications.com.",
    "qrCodeMed.label.desc": "Vous n’avez pas encore créé de traitement ? Rendez-vous sur www.tommedications.com et cliquez sur « Créer un traitement ».",
    "qrCodeMed.label.descHighlight": "www.tommedications.com",
    "qrCodeMed.button.scan": "Scannez maintenant votre code QR.",
    "qrCodeMed.scan.label.title": "Maintenant, pointez votre smartphone vers le QR Code du site web tommedications.com",
    "qrCodeMed.successDialog.label.title": "Médicament enregistré.",
    "qrCodeMed.successDialog.label.desc": "Votre médicament a bien été enregistré.",
    "qrCodeMed.errorDialog.label.title": "Erreur lors de l’enregistrement.",
    "qrCodeMed.errorDialog.label.desc": "Votre médicament n’a pas pu être enregistré."
}
export default fr
    