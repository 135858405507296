export enum TypeKey {
    SET_COMMON_LOADING = 'SET_COMMON_LOADING',
    SET_COMMON_MESSAGE = 'SET_COMMON_MESSAGE',
    UNSET_COMMON_MESSAGE = 'UNSET_COMMON_MESSAGE'
}

export interface SetCommonLoadingAction {
    type: TypeKey.SET_COMMON_LOADING
    loading: boolean
}

export interface SetCommonMessageAction {
    type: TypeKey.SET_COMMON_MESSAGE
    id: string
    title?: string
    description?: string
    timeout?: number
    data?: any
}

export interface UnsetCommonMessageAction {
    type: TypeKey.UNSET_COMMON_MESSAGE
    id: string
}

export type CommonActionTypes = SetCommonLoadingAction | SetCommonMessageAction | UnsetCommonMessageAction

export const setLoading = (loading: boolean): SetCommonLoadingAction => ({
    type: TypeKey.SET_COMMON_LOADING,
    loading
})

export const setMessage = (id: string, title: string, description?: string, timeout?: number, data?: any): SetCommonMessageAction => ({
    type: TypeKey.SET_COMMON_MESSAGE,
    id,
    title,
    description,
    timeout,
    data
})

export const unsetMessage = (id: string): UnsetCommonMessageAction => ({
    type: TypeKey.UNSET_COMMON_MESSAGE,
    id
})

export const CommonActions = {
    setLoading,
    setMessage,
    unsetMessage,
}