/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { FormType, Weekday, FormValuesState, AppState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage } from 'react-intl'
import { Button, Typography, makeStyles, Theme, Grid, FormGroup, FormControlLabel, Checkbox, CardContent, Card } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { goBack } from 'connected-react-router'
import { useLocation } from 'react-router-dom'

const useStyles =  makeStyles((theme: Theme) => ({ 
    card: {
        alignItems: 'center'
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    }
}))

const weekdayIntervalForm: Form = {
    formType: FormType.WEEKDAY_INTERVAL_FORM,
    title: () => <FormattedMessage id='create.weekdayReminder.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)

        let defaultValues = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
        }
        const checkedWeekdays = formValuesState && formValuesState.weekdays && 
            formValuesState.weekdays.reduce((acc, currVal) => (
                { ...acc, [currVal]: true }
            ), {})

        defaultValues = checkedWeekdays ? {...defaultValues, ...checkedWeekdays} : defaultValues

        const [weekdays, setWeekday] = React.useState(defaultValues)

        const handleSetWeekday = (event: React.ChangeEvent<HTMLInputElement>) => {
            setWeekday({ 
                ...weekdays, 
                [event.target.name]: event.target.checked 
            })
        }

        const handleForwardAction = () => {
            const selectedWeekdays = weekdaysModel.reduce((acc: Weekday[], currVal) => {
                if (weekdays[`${currVal.name}`]) {
                    acc.push(currVal.name)
                }
                return acc
            }, [])
 
            dispatch(FormValuesActions.setWeekdays(selectedWeekdays))

            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        let weekdaysModel: Array<{
            name: Weekday
            label: React.ReactElement
        }> = []

        if (Weekday.MONDAY) {
            weekdaysModel.push({
                name: Weekday.MONDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.monday.full' />
            })
        }
        if (Weekday.TUESDAY) {
            weekdaysModel.push({
                name: Weekday.TUESDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.tuesday.full' />
            })
        }
        if (Weekday.WEDNESDAY) {
            weekdaysModel.push({
                name: Weekday.WEDNESDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.wednesday.full' />
            })
        }
        if (Weekday.THURSDAY) {
            weekdaysModel.push({
                name: Weekday.THURSDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.thursday.full' />
            })
        }
        if (Weekday.FRIDAY) {
            weekdaysModel.push({
                name: Weekday.FRIDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.friday.full' />
            })
        }
        if (Weekday.SATURDAY) {
            weekdaysModel.push({
                name: Weekday.SATURDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.saturday.full' />
            })
        }
        if (Weekday.SUNDAY) {
            weekdaysModel.push({
                name: Weekday.SUNDAY,
                label: <FormattedMessage id='create.weekdayReminder.label.sunday.full' />
            })
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            > 
                <Grid 
                    container 
                    item 
                    spacing={2}
                    justify="center"
                >
                    <Card className={classes.card}>                                
                        <CardContent> 
                            <FormGroup>
                                {weekdaysModel.map(weekday => (
                                    <Grid item key={weekday.name}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={weekdays[`${weekday.name}`]} 
                                                    onChange={handleSetWeekday} 
                                                    disableRipple
                                                    name={weekday.name}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography variant="h4" color="primary">
                                                    {weekday.label}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                ))}                                
                            </FormGroup>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>                    
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleForwardAction}
                        disabled={!Object.values(weekdays).some(day => day === true)}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.weekdayReminder.button.next' />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default weekdayIntervalForm