import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import './index.css'
import App from './App'
import { history, initStore, getStore } from './state/store'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import deLocale from 'date-fns/locale/de'
import itLocale from 'date-fns/locale/it'
import esLocale from 'date-fns/locale/es'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/core'
import theme from './theme'

import de from './locale/de'
import en from './locale/en'
import fr from './locale/fr'
import it from './locale/it'
import es from './locale/es'

const browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language
const languageWithoutRegionCode = browserLanguage.toLowerCase().split(/[_-]+/)[0]
const messages: any = {de, en, fr, it, es}

initStore()
const store = getStore()

const localeMap = {
    de: deLocale,
    en: enLocale,
    fr: frLocale,
    it: itLocale,
    es: esLocale
}

const useQuery = () => {
    return new URLSearchParams(history.location.search)
}
const query = useQuery()
const langParameter = query.get('lang')
const language = langParameter ? langParameter : languageWithoutRegionCode

ReactDOM.render(
    <IntlProvider locale={language} messages={messages[language] || messages.de}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[language]}>
                    <App history={history}/>
                </MuiPickersUtilsProvider>
            </ThemeProvider>                
        </Provider>
    </IntlProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
