import { FormType } from '../state/definition'
import reminderSelectionForm from '../components/pages/formPage/forms/reminderSelectionForm'
import dateTimeQuantityUnitForm from '../components/pages/formPage/forms/dateTimeQuantityUnitForm'
import searchForm from '../components/pages/formPage/forms/searchForm'
import timeReminderOverviewForm from '../components/pages/formPage/forms/timeReminderOverviewForm'
import weekdayIntervalForm from '../components/pages/formPage/forms/weekdayIntervalForm'
import dayIntervalForm from '../components/pages/formPage/forms/dayIntervalForm'
import hourIntervalForm from '../components/pages/formPage/forms/hourIntervalForm'
import therapyDurationForm from '../components/pages/formPage/forms/therapyDurationForm'
import amountForm from '../components/pages/formPage/forms/amountForm'
import intakeRecommendationForm from '../components/pages/formPage/forms/intakeRecommendationForm'
import overviewForm from '../components/pages/formPage/forms/overviewForm'
import qrCodeForm from '../components/pages/formPage/forms/qrCodeForm'
export interface Form {
    formType: FormType
    title?: string | React.FunctionComponent
    formContent?: React.FunctionComponent
    controlBar?: React.FunctionComponent
}

const formsCollection: { [id: string]: Form } = {
    [FormType.SEARCH_FORM]: searchForm,
    [FormType.REMINDER_SELECTION_FORM]: reminderSelectionForm,
    [FormType.DATE_TIME_QUANTITY_UNIT_FORM]: dateTimeQuantityUnitForm,
    [FormType.TIME_REMINDER_OVERVIEW_FORM]: timeReminderOverviewForm,
    [FormType.WEEKDAY_INTERVAL_FORM]: weekdayIntervalForm,
    [FormType.DAY_INTERVAL_FORM]: dayIntervalForm,
    [FormType.HOUR_INTERVAL_FORM]: hourIntervalForm,
    [FormType.THERAPY_DURATION_FORM]: therapyDurationForm,
    [FormType.AMOUNT_FORM]: amountForm,
    [FormType.INTAKE_RECOMMENDATION_FORM]: intakeRecommendationForm,
    [FormType.OVERVIEW]: overviewForm,
    [FormType.QR_CODE]: qrCodeForm
}

export default formsCollection