import React, { FunctionComponent } from 'react'
import { History } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import BaseLayout from './components/layout'

const App: FunctionComponent<{ history: History }> = (props) => {
    return (
        <ConnectedRouter history={props.history}>
            <BaseLayout />
        </ConnectedRouter>
    )
}

export default App
