/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { FormType, FormValuesState, AppState, MedicineArticle, UnitState, ReminderType, IntakeRecommendationType, TherapyDurationType, Weekday } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import { makeStyles, Theme, Grid, Card, CardContent, Typography, Button, Box, Dialog, DialogTitle, DialogActions, DialogContent, FilledInput, FormControl, InputLabel, CardMedia } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import liquids from '../../../../svg/liquids.svg'
import pills from '../../../../svg/pills.svg'
import { format, getHours, getMinutes } from 'date-fns'
import { FormValuesActions } from '../../../../state/actions'

const useStyles =  makeStyles((theme: Theme) => ({
    card: {
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            margin: '4px 0',
            '& span': {
                color: theme.colors.purpleLight4,
                fontSize: '8px',
                margin: '0 5px'
            }
        }
    }, 
    cardContent: {
        width: '100%'
    },
    mediaWrapper: {
        padding: '10px',
        backgroundColor: theme.colors.white,
    },
    media: {
        width: '55px',
        objectFit: 'contain'
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    iconWrapper: {
        backgroundColor: theme.colors.purpleLight5,
        color: theme.colors.purpleLight3,
        borderRadius: '7px',
        marginRight: '15px',
        padding: '4px',
        cursor: 'pointer'
    },
    dialog: {
        '& h2': {
            textTransform: 'uppercase',
            color: theme.colors.purple,
            textAlign: 'center'
        },
        '& h5': {
            fontSize: '20px'
        }
    },
    dialogContent: {
        boxShadow: '0px 0px',
        borderRadius: '4px',
        backgroundColor: theme.colors.purpleLight5,
        '&::before': {
            borderBottom: 'none'
        },
        '&:hover': {
            backgroundColor: theme.colors.purpleLight5,
        },
        '&.Mui-focused': {
            backgroundColor: theme.colors.purpleLight5,
        }    
    }
}))

const overviewForm: Form = {
    formType: FormType.OVERVIEW,
    title: () => <FormattedMessage id='create.summary.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const intl = useIntl()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const unitState: UnitState = useSelector((state: AppState) => state.unit)

        const defaultNote = !!formValuesState.note ? formValuesState.note : ''
        const [openDialog, setOpenDialog] = React.useState<boolean>(false)
        const [openFinishDialog, setOpenFinishDialog] = React.useState<boolean>(false)
        const [note, setNote] = React.useState<string>(defaultNote)

        const locale = intl.locale.split('-')[0]
        let articleName: string

        const article: MedicineArticle = formValuesState.article as MedicineArticle
        if (article instanceof Object) {
            articleName = article.name[`${locale}`] || article.name['de']
        } else {
            articleName = article
        }

        const articleUnitName = (!!article && article.unit && article.unit?.unitType && 
            (unitState && unitState.unitList.length > 0 && unitState.unitList.find(item => item.unitType === article.unit?.unitType)?.name)) || null
        const articleUnitType = formValuesState.dateTimeQuantityUnits && formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.dateTimeQuantityUnits[0].unit

        const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
            setNote(event.target.value)
        }

        const handleDialogConfirm = () => {
            dispatch(FormValuesActions.setNote(note))
            setOpenDialog(false)
        }

        const handleDialogClose = () => {
            setOpenDialog(false)
            if (formValuesState.note && formValuesState.note !== note) {
                setNote(formValuesState.note)
            }
            if (!formValuesState.note) {
                setNote('')
            }           
        }

        const renderArticleOverview = () => {
            
            const renderFallbackImage = () => {
                return (
                    <Box className={classes.mediaWrapper}>
                        {articleUnitType === 'ML' ? (
                            <CardMedia
                                component="img"
                                height="55"
                                className={classes.media}
                                image={liquids}  
                                title='liquidsImg'                          
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                height="55"
                                className={classes.media}
                                image={pills}
                                title='pillsImg'
                            />
                        )}
                    </Box>
                )
            }

            return (
                <Grid item>
                    <Card
                        className={classes.card}
                        onClick = {() => {  
                            dispatch(push({ pathname: `${FormType.SEARCH_FORM}`, state: { fromOverview: location } }))              
                        }}
                    >      
                        {article.imageTypes && article.imageURLTemplate ?
                            (                                      
                                <Box className={classes.mediaWrapper}>      
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        height="55"
                                        image={article.imageURLTemplate}  
                                        title='defaultImg'                          
                                    />                           
                                </Box>
                            ) : 
                            renderFallbackImage()
                        }                           
                        <CardContent
                            classes={{ 
                                root: classes.cardContent
                            }}
                        >                                
                            <Typography variant="h5">
                                {articleName}
                            </Typography>
                            {article && (article.quantity || articleUnitName) && 
                                <Typography variant="h6">
                                    {`${article.quantity} ${articleUnitName ? articleUnitName[`${locale}`] : ''}`}
                                </Typography>
                            }                   
                        </CardContent>
                        <EditRoundedIcon 
                            fontSize="small" 
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                </Grid>
            )
        }

        const renderReminderTypeOverview = () => {
            return (
                <Grid item>
                    <Card style={{ cursor: 'initial' }}>                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.summary.label.interval' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {formValuesState.reminderType === ReminderType.DAILY &&
                                    <FormattedMessage id='create.intervalSelection.label.daily.title' />
                                }
                                {formValuesState.reminderType === ReminderType.WEEKDAY &&
                                    <FormattedMessage id='create.intervalSelection.label.weekday.title' />
                                }
                                {formValuesState.reminderType === ReminderType.DAILY_INTERVAL &&
                                    <FormattedMessage id='create.intervalSelection.label.days.title' />
                                }
                                {formValuesState.reminderType === ReminderType.HOUR_INTERVAL &&
                                    <FormattedMessage id='create.intervalSelection.label.hours.title' />
                                } 
                                {formValuesState.reminderType === ReminderType.ONCE &&
                                    <FormattedMessage id='create.intervalSelection.label.once.title' />
                                }                                                    
                            </Typography>              
                        </CardContent>
                    </Card>
                </Grid>
            )
        }

        const renderWeekOrDayInterval = (reminderType: ReminderType) => {
            const weekdays = {
                [Weekday.MONDAY]: 'create.weekdayReminder.label.monday.short',
                [Weekday.TUESDAY]: 'create.weekdayReminder.label.tuesday.short',
                [Weekday.WEDNESDAY]: 'create.weekdayReminder.label.wednesday.short',
                [Weekday.THURSDAY]: 'create.weekdayReminder.label.thursday.short',
                [Weekday.FRIDAY]: 'create.weekdayReminder.label.friday.short',
                [Weekday.SATURDAY]: 'create.weekdayReminder.label.saturday.short',
                [Weekday.SUNDAY]: 'create.weekdayReminder.label.sunday.short'
            }

            const selectedWeekdays = formValuesState.weekdays && formValuesState.weekdays.length > 0 && 
                formValuesState.weekdays.reduce((acc: string[], currVal) => {
                    if (Object.keys(weekdays).some(key => key as Weekday === currVal)) {
                        acc.push(weekdays[`${currVal}`])
                    }
                    return acc
                }, [])

            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => {
                            if (reminderType === ReminderType.WEEKDAY) {
                                dispatch(push({ pathname: `/${FormType.WEEKDAY_INTERVAL_FORM}`, state: { fromOverview: location } }))
                            } 
                            if (reminderType === ReminderType.DAILY_INTERVAL) {
                                dispatch(push({ pathname: `/${FormType.DAY_INTERVAL_FORM}`, state: { fromOverview: location } }))
                            }                                         
                        }}
                    >                                   
                        <CardContent>   
                            <Typography variant="h6">
                                {reminderType === ReminderType.DAILY_INTERVAL ? 
                                    <FormattedMessage id='create.summary.label.reminder.dayInterval.title' /> :
                                    <FormattedMessage id='create.summary.label.reminder.weekdays' /> 
                                }
                            </Typography>                                  
                            {reminderType === ReminderType.DAILY_INTERVAL && (
                                <Typography variant="h5">
                                    <FormattedMessage id='create.summary.label.reminder.dayInterval.desc' values={{ amount: formValuesState.dayInteval }} /> 
                                </Typography>
                            )}
                            {reminderType === ReminderType.WEEKDAY && (
                                <Box
                                    style={{ display: 'flex' }}
                                >
                                    {selectedWeekdays.map((day, i) => {
                                        return (
                                            <Typography variant="h5" key={i}>
                                                <FormattedMessage id={day} key={i}/>{`${selectedWeekdays.length > 0 && selectedWeekdays.length - 1 !== i ? ',' : ''}`}
                                            </Typography>
                                        )
                                    })}
                                </Box>
                            )}              
                        </CardContent>
                        <EditRoundedIcon 
                            fontSize="small" 
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                </Grid>
            ) 
        }

        const renderTimeReminderOverview = (reminderType: ReminderType) => {
            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => {  
                            if (reminderType === ReminderType.HOUR_INTERVAL) {
                                dispatch(push({ pathname: `/${FormType.HOUR_INTERVAL_FORM}`, state: { fromOverview: location } }))
                            } else if (reminderType === ReminderType.ONCE) {
                                dispatch(push({ pathname: `/${FormType.DATE_TIME_QUANTITY_UNIT_FORM}`, state: { fromOverview: location } })) 
                            } else {
                                dispatch(push({ pathname: `/${FormType.TIME_REMINDER_OVERVIEW_FORM}`, state: { fromOverview: location } }))
                            }                                         
                        }}
                    >   
                        <CardContent>
                            {formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.dateTimeQuantityUnits
                                .sort((a, b) => getMinutes(a.timeOfDayStart) - getMinutes(b.timeOfDayStart))
                                .sort((a, b) => getHours(a.timeOfDayStart) - getHours(b.timeOfDayStart))
                                .map((reminder, i) => {
                                const unitName = reminder.unit && unitState.unitList.find(unit => unit.unitType === reminder.unit)?.name

                                return (
                                    <Box key={reminder.id} style={{ margin: '10px 0' }}>
                                        {reminderType === ReminderType.HOUR_INTERVAL ? 
                                            (
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="h6">
                                                            <FormattedMessage id="create.summary.label.reminder.hourInterval.title" />
                                                        </Typography>                                                                            
                                                        <Typography variant="h5">
                                                            <FormattedMessage id="create.summary.label.reminder.hourInterval.desc" values={{ amount: reminder.hourInterval }} />
                                                        </Typography> 
                                                    </Grid> 
                                                    <Grid item>
                                                        <Typography variant="h6">
                                                            <FormattedMessage id="create.summary.label.reminder.hourPeriod.title" />
                                                        </Typography>                                  
                                                        <Typography variant="h5">
                                                            <FormattedMessage 
                                                                id="create.summary.label.reminder.hourPeriod.desc" 
                                                                values={{ 
                                                                    amount: reminder.timeOfDayStart && format(reminder.timeOfDayStart, "HH:mm"), 
                                                                    total: reminder.timeOfDayEnd && format(reminder.timeOfDayEnd, "HH:mm")
                                                                }} 
                                                            />
                                                        </Typography> 
                                                    </Grid>                                             
                                                    <Grid item>
                                                        <Typography variant="h6">
                                                            <FormattedMessage id="create.summary.label.dosage" />
                                                        </Typography>                                  
                                                        <Typography variant="h5">
                                                            {` ${reminder.quantity} ${unitName ? unitName[`${locale}`] : ''} `}
                                                        </Typography> 
                                                    </Grid>                                            
                                                </Grid> 
                                            ) : (reminderType === ReminderType.ONCE ? 
                                                (
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                <FormattedMessage id="create.summary.label.intervalOnce.date" />
                                                            </Typography>                                  
                                                            <Typography variant="h5">
                                                                {reminder.date && format(reminder.date, 'EEEE, d. MMMM yyyy')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                <FormattedMessage id="create.summary.label.intervalOnce.time" />
                                                            </Typography>                                  
                                                            <Typography variant="h5">
                                                                <FormattedMessage id="general.label.timePostfix" values={{ amount: reminder.timeOfDayStart && format(reminder.timeOfDayStart, 'HH:mm') }}/> 
                                                            </Typography>
                                                        </Grid>                                                
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                <FormattedMessage id="create.summary.label.dosage" />
                                                            </Typography>                                  
                                                            <Typography variant="h5">
                                                                {`${reminder.quantity} ${unitName ? unitName[`${locale}`] : ''}`}
                                                            </Typography> 
                                                        </Grid>                                                
                                                    </Grid>
                                                ) : (
                                                    <Grid item>
                                                        <Typography variant="h6">
                                                            <FormattedMessage id="create.summary.label.reminder" values={{ amount: i + 1 }} />
                                                        </Typography>                                  
                                                        <Typography variant="h5">
                                                            <FormattedMessage id="general.label.timePostfix" values={{ amount: reminder.timeOfDayStart && format(reminder.timeOfDayStart, 'HH:mm') }}/>
                                                            <span> &bull; </span>
                                                            {`${reminder.quantity} ${unitName ? unitName[`${locale}`] : ''}`}
                                                        </Typography> 
                                                    </Grid> 
                                                )
                                            )
                                        }  
                                    </Box>                                          
                                )
                            })} 
                        </CardContent>
                        <EditRoundedIcon
                            fontSize="small"  
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card> 
                </Grid>
            )
        }

        const renderTherapyDurationOverview = () => {
            const therapyStart: Date = formValuesState.therapyDuration && formValuesState.therapyDuration.startDate
            const therapyEnd: Date = formValuesState.therapyDuration && formValuesState.therapyDuration.endDate

            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => {  
                            dispatch(push({ pathname: `/${FormType.THERAPY_DURATION_FORM}`, state: { fromOverview: location } }))              
                        }}
                    >                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.summary.label.therapyPeriod.title' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {!!formValuesState.therapyDuration.durationType && formValuesState.therapyDuration.durationType === TherapyDurationType.CONTINUOUS ?
                                    (therapyStart &&
                                        <FormattedMessage 
                                            id='create.summary.label.therapyPeriod.continuous.desc' 
                                            values={{ amount: format(therapyStart, "d. MMMM yyyy") }} 
                                        />
                                    ) :
                                    (therapyStart && therapyEnd &&
                                        `${format(therapyStart, "d. MMMM yyyy")} - ${format(therapyEnd, "d. MMMM yyyy")}`
                                    )
                                }
                            </Typography>              
                        </CardContent>
                        <EditRoundedIcon 
                            fontSize="small" 
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                </Grid>
            )
        }

        const renderAmountOverview = () => {
            if (formValuesState.dateTimeQuantityUnits.length > 0) {
                let selectedUnit = formValuesState.dateTimeQuantityUnits[0] && formValuesState.dateTimeQuantityUnits[0].unit
                const isUnitTypeDifferent = formValuesState.dateTimeQuantityUnits.some(element => 
                    element.unit !== selectedUnit
                )
                if (isUnitTypeDifferent) {
                    return 
                }                
            } 

            const unitName = formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.dateTimeQuantityUnits[0].unit &&
                unitState.unitList.find(unit => unit.unitType === formValuesState.dateTimeQuantityUnits[0].unit)?.name

            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => {  
                            dispatch(push({ pathname: `/${FormType.AMOUNT_FORM}`, state: { fromOverview: location } }))              
                        }}
                    >                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.summary.label.stock' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {formValuesState.amount ? 
                                    `${formValuesState.amount} ${unitName ? unitName[`${locale}`] : ''} ` :
                                    <FormattedMessage id='general.label.notSpecified' />
                                }
                            </Typography>              
                        </CardContent>
                        <EditRoundedIcon 
                            fontSize="small" 
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                </Grid>
            ) 
        }

        const renderIntakeRecommendationOverview = () => {
            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => {  
                            dispatch(push({ pathname: `/${FormType.INTAKE_RECOMMENDATION_FORM}`, state: { fromOverview: location } }))              
                        }}
                    >                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.summary.label.intakeInfo' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {formValuesState.intakeRecommendation !== IntakeRecommendationType.NONE ? 
                                    (
                                        <>
                                            {formValuesState.intakeRecommendation === IntakeRecommendationType.BEFORE_MEAL &&
                                                <FormattedMessage id='create.intakeInfo.label.intakeOptions.preMeal' />
                                            }
                                            {formValuesState.intakeRecommendation === IntakeRecommendationType.DURING_MEAL &&
                                                <FormattedMessage id='create.intakeInfo.label.intakeOptions.meal' />
                                            }
                                            {formValuesState.intakeRecommendation === IntakeRecommendationType.AFTER_MEAL &&
                                                <FormattedMessage id='create.intakeInfo.label.intakeOptions.postMeal' />
                                            }
                                        </>
                                    ) : <FormattedMessage id='general.label.notSpecified' />                           
                                }
                            </Typography>              
                        </CardContent>
                        <EditRoundedIcon
                            fontSize="small"  
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                </Grid>
            )
        }

        const renderNote = () => {

            const renderDialog = () => {
                return (
                    <Dialog 
                        aria-labelledby="dialog-title"
                        className={classes.dialog} 
                        onClose={handleDialogClose} 
                        open={openDialog}
                    >
                        <DialogTitle id="dialog-title">
                            <FormattedMessage id='create.summary.dialog.notes.label.title' /> 
                        </DialogTitle>
                        <DialogContent 
                            dividers
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <FormControl variant="filled">
                                {note && (
                                    <InputLabel htmlFor="note">
                                        <FormattedMessage id={`create.summary.dialog.notes.placeholder.active`} /> 
                                    </InputLabel>
                                )}
                                <FilledInput
                                    id="note"
                                    classes={{ root: classes.dialogContent }}
                                    placeholder={!note ? intl.formatMessage({ id: 'create.summary.dialog.notes.placeholder.inactive' }) : ''}
                                    fullWidth
                                    multiline
                                    rows={6}
                                    margin="dense"
                                    color="primary"
                                    disableUnderline
                                    value={note}
                                    onChange={handleNote}
                                />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                <Typography variant="h5">
                                    <FormattedMessage id='general.label.cancel' />
                                </Typography>
                            </Button>
                            <Button 
                                disabled={!formValuesState.note && !note}
                                onClick={handleDialogConfirm} 
                                color="primary"
                                variant="contained"
                                disableRipple
                            >
                                <Typography variant="body2">
                                    <FormattedMessage id='general.label.confirm' />
                                </Typography>
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }

            return (
                <Grid item>
                    <Card 
                        className={classes.card}
                        onClick = {() => setOpenDialog(true)}
                    >                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.summary.label.notes' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {formValuesState.note ? 
                                    formValuesState.note :
                                    <FormattedMessage id='general.label.notSpecified' />
                                }
                            </Typography>              
                        </CardContent>
                        <EditRoundedIcon 
                            fontSize="small" 
                            classes={{ root: classes.iconWrapper }}
                        />
                    </Card>
                    {renderDialog()}
                </Grid>
            )
        }

        const renderFinishDialog = () => {
            return (
                <Dialog 
                    aria-labelledby="finish-dialog-title"
                    className={classes.dialog} 
                    open={openFinishDialog}
                >
                    <DialogTitle id="finish-dialog-title">
                        <FormattedMessage id='create.web.summary.label.title' /> 
                    </DialogTitle>
                    <DialogContent 
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box m={4}>
                            <Typography 
                                variant="h5" 
                                align="center"
                            >
                                <FormattedMessage id='create.web.summary.label.desc' /> 
                            </Typography> 
                        </Box>                                                   
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => {
                                dispatch(replace({ pathname: `${FormType.SEARCH_FORM}`, state: { restart: location } }))
                                dispatch(FormValuesActions.confirmValues(false))
                                setOpenFinishDialog(false)
                            }} 
                            color="primary"
                        >
                            <Typography variant="h5">
                                <FormattedMessage id='create.web.summary.button.cancel' />
                            </Typography>
                        </Button>
                        <Button 
                            onClick={() => {
                                dispatch(FormValuesActions.confirmValues(true))
                                setOpenFinishDialog(false)
                            }} 
                            color="primary"
                            variant="contained"
                            disableRipple
                        >
                            <Typography variant="body2">
                                <FormattedMessage id='create.web.summary.button.add' />
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                {renderArticleOverview()}
                {renderReminderTypeOverview()}   
                {(formValuesState.reminderType === ReminderType.WEEKDAY || formValuesState.reminderType === ReminderType.DAILY_INTERVAL) && 
                    renderWeekOrDayInterval(formValuesState.reminderType)
                }                   
                {renderTimeReminderOverview(formValuesState.reminderType)}                       
                {formValuesState.reminderType !== ReminderType.ONCE && 
                    renderTherapyDurationOverview()
                }
                {renderAmountOverview()}
                {renderIntakeRecommendationOverview()}
                {renderNote()}
                {renderFinishDialog()}
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setOpenFinishDialog(true)} 
                    >
                        <DoneRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.summary.button.ok' />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default overviewForm