const en =
{
    "general.label.ok": "OK",
    "general.label.yes": "Yes",
    "general.label.no": "No",
    "general.label.startOver": "Start Over",
    "general.label.startOver.title": "Do you want to start over?",
    "general.label.startOver.desc": "This will delete your entries and start over.",
    "general.label.confirm": "Apply",
    "general.label.skip": "Skip",
    "general.button.close": "Close",
    "general.label.cancel": "Cancel",
    "general.label.success": "Success",
    "general.label.notSpecified": "Not specified",
    "general.error.label.title": "Error",
    "general.error.label.desc": "Oh dear, an error has occurred. Please try again later.",
    "general.error.button.reload": "Reload",
    "general.error.session.desc": "Session expired",
    "general.label.timePostfix": "{amount}",
    "general.error.noQuantity": " ",
    "tabBar.label.today": "Today",
    "tabBar.label.cabinet": "Cabinet",
    "tabBar.label.more": "More",
    "appInfo.label.title": "Stress-free medication management",
    "appInfo.button.go": "Let’s go",
    "appInfo.button.recovery": "Restore data",
    "appInfo.label.dsgvo": "By continuing, you confirm that you accept our <a href=\"{amount}\">terms of use</a> and <a href=\"{total}\">privacy policy</a>.",
    "acceptTerms.label.title": "New terms and conditions are available. Please accept to continue.",
    "acceptTerms.label.dsgvo": "By continuing, you confirm that you accept our <a href=\"{amount}\">terms of use</a> and <a href=\"{total}\">privacy policy</a>.",
    "acceptTerms.button.accept": "Let’s go",
    "migrate.label.title": "Welcome! We have completely redesigned the app. I wish you good health and all the best for the future. TOM.",
    "migrate.button.go": "Let’s go",
    "migrate.label.dsgvo": "By continuing, you confirm that you accept our <a href=\"{amount}\">terms of use</a> and <a href=\"{total}\">privacy policy</a>.",
    "welcome.label.navBarTitle": "Welcome",
    "welcome.label.textSegment.i0": "Hello! I’m TOM.",
    "welcome.label.textSegment.i1": "I can help you to take your medications.",
    "welcome.label.textSegment.i2": "Let’s get started!",
    "welcome.web.label.textSegment.i1": "I'll help you create a reminder for your medication",
    "welcome.web.label.textSegment.i2": "First look for the name of your drug.",
    "firstMed.label.navBarTitle": "Welcome",
    "firstMed.label.title": "You medicine cabinet is empty.\nAdd your first medication now.",
    "firstMed.button.add": "Add medication",
    "firstMed.button.later": "Not now, later",
    "create.label.navBarTitle.medicament.create": "Add medication",
    "create.label.navBarTitle.medicament.edit": "Change medication",
    "create.label.navBarTitle.reminder.create": "Add reminder",
    "create.dialog.quit.label.title": "Cancel process",
    "create.dialog.quit.label.medicamentDesc": "Your medication has not been saved. Do you really want to cancel?",
    "create.dialog.quit.label.reminderDesc": "Your reminder has not been saved. Do you really want to cancel?",
    "create.dialog.quit.button.continue": "No, continue",
    "create.dialog.quit.button.cancel": "Yes, cancel",
    "create.selection.label.title": "You can scan the bar code on the packaging or search for the name of the medication.",
    "create.selection.button.camera": "Scan code",
    "create.selection.button.search": "Search manually",
    "create.scan.label.title": "Hold your smartphone over the bar code or QR code.",
    "create.scan.button.cameraNotAvailable": "Scanning using the camera is not currently available. Check your settings or switch to manual entry mode.",
    "create.scan.alert.label.fail.title": "Scanning failed",
    "create.scan.alert.label.fail.text": "Please try the following: \r\n- Make sure there is sufficient light. \r\n- Avoid shadows",
    "create.scan.alert.button.fail.search": "Search manually",
    "create.scan.alert.button.fail.ok": "OK",
    "create.scan.error.label.title": "Error during scanning",
    "create.scan.error.label.notFound": "The desired medication was not found",
    "create.scan.error.button.search": "Search manually",
    "create.scan.error.button.retry": "Try again",
    "create.search.label.text": "Please enter the name of your medication:",
    "create.search.label.desc": "Here you can easily enter your medication with a reminder and at the end transfer it to the app. The process is simple and easy. First you search for your medication in our database or enter it yourself.",
    "create.search.label.placeholder.normal": "Enter medication name",
    "create.search.label.placeholder.active": "Medication name",
    "create.search.label.yourInput": "Your entry",
    "create.search.button.switchDatabase": "Change country",
    "create.search.label.searchResults": "Search results:",
    "create.alreadyInCabinet.label.title": "Medicine cabinet",
    "create.alreadyInCabinet.label.desc": "The medication \"{amount}\" is already in your medicine cabinet. Do you still want to add it again?",
    "create.alreadyInCabinet.button.accept": "Add",
    "create.alreadyInCabinet.button.decline": "Cancel",
    "create.reminderQuery.label.text": "Do you want to set up a reminder or simply take it as needed and place it in the cabinet?",
    "create.reminderQuery.button.reminder": "Yes, set up reminder",
    "create.reminderQuery.button.dashboard": "No, in the cabinet",
    "create.push.label.text": "I still need your permission to remind you to take your medication.",
    "create.push.button.next": "Next",
    "create.medInfo.label.title": "Should I note the expiry date so that I can remind you of this ahead of time?",
    "create.medInfo.label.expirationDate": "Expiry date",
    "create.medInfo.label.expirationDateNotificationTime": "Reminder before expiry",
    "create.medInfo.label.expirationDateNotificationChangeInfo": "You can change your settings later",
    "create.medInfo.button.finish": "Next",
    "create.medInfo.button.skipInfo": "Close without expiry date",
    "create.medInfo.dialog.exparationDate.label.title": "Enter expiry date",
    "create.intervalSelection.label.title": "How often should I remind you to take your medication?",
    "create.intervalSelection.label.daily.title": "Daily",
    "create.intervalSelection.label.daily.desc": "Once a day",
    "create.intervalSelection.label.weekday.title": "On particular days of the week",
    "create.intervalSelection.label.weekday.desc": "Several times a week",
    "create.intervalSelection.label.days.title": "Every x days",
    "create.intervalSelection.label.days.desc": "Interval in days",
    "create.intervalSelection.label.hours.title": "Every x hours",
    "create.intervalSelection.label.hours.desc": "Interval in hours",
    "create.intervalSelection.label.once.title": "Once",
    "create.intervalSelection.label.once.desc": "Take once as needed",
    "create.dailyReminder.label.createFirst.title": "At what time should I remind you?",
    "create.dailyReminder.label.createAdditional.title": "At what other time should I remind you?",
    "create.dailyReminder.label.edit.title": "How would you like to change your reminder?",
    "create.dailyReminder.button.next": "Next",
    "create.dailyReminder.button.cancel": "Cancel",
    "create.dailyReminder.dialog.time.title": "Set time",
    "create.dailyReminder.dialog.unit.title": "Change unit",
    "create.dailyReminder.dialog.quantitiy.title": "Set quantity",
    "create.dailyReminder.dialog.sameTime.title": "Reminder times are the same",
    "create.dailyReminder.dialog.sameTime.desc": "You cannot set two reminders for the same time and same medication. Please change your selected time.",
    "create.dailyReminder.time.title": "Time",
    "create.dailyReminder.unit.title": "Unit",
    "create.dailyReminder.quantitiy.title": "Quantity",
    "create.dailyReminderList.label.createFirst.title": "You have entered your first time. Do you want to add another?",
    "create.dailyReminderList.label.createAdditional.title": "Do you want to add another time?",
    "create.dailyReminderList.label.addTime": "Add another time",
    "create.dailyReminderList.button.next": "Next",
    "create.dailyReminderList.dialog.delete.label.title": "Delete time?",
    "create.dailyReminderList.dialog.delete.label.desc": "Do you really want to delete your medication dosage at {amount}?",
    "create.dailyReminderList.dialog.delete.button.delete": "Yes, delete",
    "create.dailyReminderList.dialog.delete.button.cancel": "Cancel",
    "create.dailyReminderList.dialog.differentUnit.label.title": "Units different",
    "create.dailyReminderList.dialog.differentUnit.label.desc": "You have selected different units to take. We will not be able to calculate/monitor your supply of medication. Do you still want to continue?",
    "create.dailyReminderList.dialog.stockUnit.label.title": "Note",
    "create.dailyReminderList.dialog.stockUnit.label.desc": "You have selected a different unit of medication. Please correct your stock quantity as well.",
    "create.dailyReminderList.dialog.stockUnit.button.change": "Continue to stock",
    "create.dailyReminder.label.time.postfix": " ",
    "create.dailyReminderList.label.reminder.postfix": "Reminder",
    "create.weekdayReminder.label.title": "Which days of the week should I remind you?",
    "create.weekdayReminder.label.monday.full": "Monday",
    "create.weekdayReminder.label.monday.short": "Mon",
    "create.weekdayReminder.label.tuesday.full": "Tuesday",
    "create.weekdayReminder.label.tuesday.short": "Tue",
    "create.weekdayReminder.label.wednesday.full": "Wednesday",
    "create.weekdayReminder.label.wednesday.short": "Wed",
    "create.weekdayReminder.label.thursday.full": "Thursday",
    "create.weekdayReminder.label.thursday.short": "Thu",
    "create.weekdayReminder.label.friday.full": "Friday",
    "create.weekdayReminder.label.friday.short": "Fri",
    "create.weekdayReminder.label.saturday.full": "Saturday",
    "create.weekdayReminder.label.saturday.short": "Sat",
    "create.weekdayReminder.label.sunday.full": "Sunday",
    "create.weekdayReminder.label.sunday.short": "Sun",
    "create.weekdayReminder.button.next": "Next",
    "create.daysReminder.label.title": "How often should I remind you? (in days)",
    "create.daysReminder.button.days.title": "Interval in days",
    "create.daysReminder.button.days.msg": "Every {amount} days",
    "create.daysReminder.button.next": "Next",
    "create.daysReminder.dialog.days.title": "Select interval in days",
    "create.hoursReminder.label.title": "How often should I remind you? (in hours)",
    "create.hoursReminder.dialog.hours.title": "Select interval in hours",
    "create.hoursReminder.button.hours.title": "Interval in hours",
    "create.hoursReminder.button.hours.msg": "Every {amount} hours",
    "create.hoursReminder.button.timeStart.title": "Interval start",
    "create.hoursReminder.button.timeEnd.title": "Interval end",
    "create.hoursReminder.dialog.timeStart.title": "Select interval start",
    "create.hoursReminder.dialog.timeEnd.title": "Select interval end",
    "create.hoursReminder.button.unit.title": "Unit",
    "create.hoursReminder.button.quantitiy.title": "Quantity",
    "create.hoursReminder.label.time.postfix": " ",
    "create.hoursReminder.dialog.unit.title": "Change unit",
    "create.hoursReminder.dialog.quantitiy.title": "Set quantity",
    "create.hoursReminder.errorDialog.endDateInvalid.title": "Invalid interval end",
    "create.hoursReminder.errorDialog.endDateInvalid.desc": "The interval end cannot be before the interval start.",
    "create.hoursReminder.errorDialog.endDateInvalid.interval.desc": "Das Intervall-Ende kann nicht vor dem ersten Einnahmezeitpunkt liegen.",
    "create.hoursReminder.errorDialog.startDateInvalid.title": "Invalid interval start",
    "create.hoursReminder.errorDialog.startDateInvalid.desc": "The interval start cannot be after the interval end.",
    "create.hoursReminder.button.next": "Next",
    "create.onceReminder.label.title": "When should I remind you to take your medication?",
    "create.onceReminder.dialog.date.title": "Select date",
    "create.onceReminder.button.date.title": "Date",
    "create.onceReminder.button.time.title": "Time",
    "create.onceReminder.button.unit.title": "Unit",
    "create.onceReminder.button.quantitiy.title": "Quantity",
    "create.onceReminder.dialog.time.title": "Set time",
    "create.onceReminder.dialog.unit.title": "Change unit",
    "create.onceReminder.dialog.quantitiy.title": "Set quantity",
    "create.onceReminder.label.time.postfix": " ",
    "create.onceReminder.button.next": "Next",
    "create.period.label.title": "How long will your treatment continue?",
    "create.period.label.startTime": "Treatment start",
    "create.period.label.periodType": "Treatment duration",
    "create.period.label.periodType.continuous": "Ongoing",
    "create.period.label.periodType.endDate": "Until end date",
    "create.period.label.endDate": "Treatment end",
    "create.period.dialog.startDate.title": "Select treatment start",
    "create.period.dialog.endDate.title": "Select treatment end",
    "create.period.label.days": "Number of days",
    "create.period.dialog.days.title": "Select number of days",
    "create.period.button.next": "Next",
    "create.stock.label.title": "Keep an overview of your stock!",
    "create.stock.label.hint.stockInactive": "Enter current supply quantity",
    "create.stock.label.hint.stockActive": "Supply quantity",
    "create.stock.label.hint.unit": "Unit",
    "create.stock.label.expirationDateNotificationTime": "Stock control",
    "create.stock.label.expirationDateNotificationChangeInfo": "TOM updates the number of tablets in your stock each time you take them and informs you when you should reorder.",
    "create.stock.button.next": "Next",
    "create.stock.dialog.label.stock.title": "Current supply quantity",
    "create.stock.dialog.label.unit.title": "Change unit",
    "create.intakeInfo.label.title": "How should the medication be taken?",
    "create.intakeInfo.label.intakeOptions.title": "Notes on administering",
    "create.intakeInfo.label.intakeOptions.preMeal": "Before food",
    "create.intakeInfo.label.intakeOptions.meal": "With food",
    "create.intakeInfo.label.intakeOptions.postMeal": "After food",
    "create.intakeInfo.label.intakeOptions.none": "No information",
    "create.intakeInfo.label.changeInfo": "You can change the information at any time",
    "create.intakeInfo.button.finish": "Next",
    "create.summary.label.title": "Is all of the information in this entry correct?",
    "create.summary.label.interval": "Interval",
    "create.summary.label.reminder": "{amount}. Reminder",
    "create.summary.label.emptyReminder": "Reminder",
    "create.summary.label.therapyPeriod.title": "Treatment duration",
    "create.summary.label.therapyPeriod.continuous.desc": "from {amount}",
    "create.summary.label.stock": "Supply quantity",
    "create.summary.label.expiryDate": "Expiry date",
    "create.summary.label.intakeInfo": "Notes on administration",
    "create.summary.label.notes": "Note",
    "create.summary.dialog.notes.label.title": "Add note",
    "create.summary.dialog.notes.placeholder.active": "Note",
    "create.summary.dialog.notes.placeholder.inactive": "Purpose, specifics, doctor’s instructions, etc.",
    "create.summary.label.reminder.weekdays": "Days of the week",
    "create.summary.label.reminder.dayInterval.title": "Interval in days",
    "create.summary.label.reminder.dayInterval.desc": "Every {amount} days",
    "create.summary.label.reminder.hourInterval.title": "Interval in hours",
    "create.summary.label.reminder.hourInterval.desc": "Every {amount} hours",
    "create.summary.label.reminder.hourPeriod.title": "Interval period",
    "create.summary.label.reminder.hourPeriod.desc": "from {amount} to {total}",
    "create.summary.label.intervalOnce.date": "Date",
    "create.summary.label.intervalOnce.time": "Time",
    "create.summary.label.dosage": "Quantity",
    "create.summary.button.ok": "Yes, everything is correct",
    "create.web.summary.label.title": "It's that easy in TOM.",
    "create.web.summary.label.desc": "If you want, I can transfer your therapy directly to the free app.",
    "create.web.summary.button.add": "Yes, gladly",
    "create.web.summary.button.cancel": "No, not necessary",
    "qr.code.title": "With this QR code you can manage your medication directly in the app. To do this, scan the code with the TOM mobile app: ",
    "qr.code.desc": "Don't you have the TOM app yet? Then install it for free using the links below, then you can scan the code in the app.",
    "create.short.summary.label.title": "Do you want to add the following medicine to your cabinet?",
    "create.short.summary.button.add": "Yes, add",
    "cabinet.label.navBarTitle": "Medicine cabinet",
    "cabinet.label.stock": "Quantity",
    "cabinet.label.expiry": "Expiry",
    "cabinet.label.notSpecified": "N/A",
    "cabinet.filter.label.title": "Filter",
    "cabinet.filter.label.all": "Show all",
    "cabinet.filter.label.withReminder": "Reminder set",
    "cabinet.filter.label.withoutReminder": "No reminder",
    "cabinet.filter.label.withPrescription": "Prescription",
    "cabinet.filter.label.withoutPrescription": "Non-prescription",
    "detail.label.navBarTitle": "Overview of medicines",
    "detail.label.title": "Your information:",
    "detail.label.editTitle": "You can now make any changes to the entry:",
    "detail.button.save": "Save",
    "detail.button.addReminder": "Add reminder",
    "detail.dialog.deleteSelection.label.title": "Delete",
    "detail.dialog.deleteSelection.label.desc": "Do you want to delete the whole medication or just the reminder?",
    "detail.dialog.deleteSelection.button.med": "Medication",
    "detail.dialog.deleteSelection.button.reminder": "Reminder",
    "detail.dialog.deleteConfirm.label.title": "Really delete?",
    "detail.dialog.deleteConfirm.label.reminderDesc": "You really want to delete the reminder?",
    "detail.dialog.deleteConfirm.label.medDesc": "Do you really want to delete the medication?",
    "detail.dialog.deleteConfirm.button.yes": "Yes, delete.",
    "detail.dialog.deleteConfirm.button.no": "No, cancel.",
    "detail.success.label.text": "The entries were successfully updated.",
    "today.label.next.title": "Next due in",
    "today.label.next.at": "at",
    "today.label.taken.title": "Taken\nsuccessfully",
    "today.label.taken.at": "on {amount} at",
    "today.label.skipped.title": "Not\ntaken",
    "today.label.skipped.at": "on {amount} at",
    "today.label.overdue.title": "Overdue\nsince",
    "today.label.overdue.at": "Today at",
    "today.label.planned.title": "Planned\ndosage in",
    "today.label.planned.day": "1 day",
    "today.label.planned.days": "{amount} days",
    "today.label.planned.at": "on {amount} at",
    "today.button.take": "Take now",
    "today.button.later": "Postpone",
    "today.button.skip": "Skip",
    "today.emptyState.label.text": "You do not need to take any medication today",
    "today.label.clock": "{amount}",
    "today.postponeDialog.label.title": "How long would you like to postpone taking your medication?",
    "today.postponeDialog.label.content": "{amount} minutes",
    "today.postponeDialog.button.later": "Postpone",
    "today.postponeConfirmDialog.label.title": "Postpone medication",
    "today.postponeConfirmDialog.label.desc": "Are you sure that you want to postpone taking your medication by {amount} minutes?",
    "today.postponeConfirmDialog.button.later": "Postpone",
    "today.label.selectDay": "Select day",
    "today.intakeInfo.label.confirmed": "Taken at {amount}",
    "today.intakeInfo.label.postponed": "Postponed to {amount}",
    "today.intakeInfo.label.overdue": "Overdue since {amount}",
    "today.intakeInfo.label.skipped": "Skipped.\n{amount}",
    "today.intakeInfo.label.skippedNoReason": "Skipped.",
    "today.actionSheet.label.takeNow": "Take now",
    "today.actionSheet.label.takeAllNow": "Take all now",
    "today.actionSheet.label.takeOnPoint": "Taken on time at {amount}",
    "today.actionSheet.label.takeAllOnPoint": "All taken on time at {amount}",
    "today.actionSheet.label.takeOtherTime": "Taken at a different time",
    "today.actionSheet.label.takeAllOtherTime": "All taken at a different time",
    "today.actionSheet.label.skip": "Skip",
    "today.actionSheet.label.skipAll": "Skip all",
    "today.actionSheet.label.postpone": "Postpone",
    "today.actionSheet.label.postponeAll": "Postpone all",
    "today.actionSheet.label.titleAll": "All medicines at {amount}",
    "today.actionSheet.label.reset": "Reset taking status",
    "today.timePicker.label.title": "Set time",
    "today.listBottomButtons.button.takeAllNow": "Take all now",
    "today.listBottomButtons.button.takeAllOnPoint": "All taken on time",
    "today.listBottomButtons.button.skipAll": "Skip all",
    "today.listBottomButtons.button.takeAllOnOtherPoint": "All taken at a different time",
    "today.error.batchIntakes": "We are sorry, not all intakes could be updated, please try again later.",
    "actionSheet.label.new": "New",
    "addEntry.label.title": "Add entry",
    "addEntry.label.med": "Add medication",
    "addEntry.label.mediCheck": "Medi-Check",
    "invalidCredentials.label.title": "Something went wrong during the authentication process.",
    "invalidCredentials.label.text": "Please try again. Or write us at support(at)innovation6.ch and we will try to help!",
    "invalidCredentials.button.retry": "Try again",
    "invalidCredentials.button.logout": "Log out",
    "invalidCredentials.logoutConfirm.label.title": "Log out",
    "invalidCredentials.logoutConfirm.label.text": "This action will delete your date from this device.",
    "invalidCredentials.logoutConfirm.button.accept": "Continue",
    "invalidCredentials.logoutConfirm.button.cancel": "Cancel",
    "loadingCurrentUserFailed.label.title": "Oh dear! Unfortunately something went wrong while loading your data.",
    "loadingCurrentUserFailed.label.text": "Please try again. Or write us at support(at)innovation6.ch and we will try to help!",
    "loadingCurrentUserFailed.button.retry": "Try again",
    "loadingGTCFailed.label.title": "Oh dear! Something went wrong while loading the General Terms and Conditions.",
    "loadingGTCFailed.label.text": "Please try again. Or write us at support(at)innovation6.ch and we will try to help!",
    "loadingGTCFailed.button.retry": "Try again",
    "defaultError.label.title": "Something went wrong.",
    "defaultError.label.text": "Please try again. Or write us at support(at)innovation6.ch and we will try to help!",
    "defaultError.button.retry": "Try again",
    "confetti.label.title": "Congratulations!\nYou have successfully set up your first medication.",
    "confetti.button.next": "Next",
    "more.label.navBarTitle": "More",
    "more.profile.label.title": "Personal Data",
    "more.profile.label.allergies": "Allergies",
    "more.medicheck.label.title": "Medi-Check",
    "more.medicheck.label.desc": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.",
    "more.legal.label.title": "Legal",
    "more.legal.label.agb": "Terms of use",
    "more.legal.label.dsg": "Privacy policy",
    "more.legal.label.delete": "Delete data",
    "more.legal.label.imprint": "Legal notice",
    "more.label.messageSupport": "Message to Support",
    "more.label.updateNews": "Preview 2020",
    "more.label.version": "Version information {amount}",
    "imprint.label.navBarTitle": "Legal notice",
    "imprint.label.cooperation": "Innovation 6 AG",
    "imprint.label.address": "Pfäffikerstrasse 109,\n8615 Wermatswil,\nSwitzerland",
    "imprint.label.uid": "UID no.: CHE-263.807.653",
    "imprint.label.ceo": "Managing Director: Sven Beichler",
    "imprint.label.mail": "Email: support@innovation6.ch",
    "imprint.label.web": "Website: www.tommedications.com",
    "revokeData.label.navBarTitle": "Delete data",
    "revokeData.label.title": "Are you sure that you want to delete all of your data? This action cannot be undone!",
    "revokeData.button.revoke": "Yes, delete data",
    "revokeData.button.keep": "No, don’t delete",
    "revokeData.confirmDialog.label.title": "Permanently delete data?",
    "revokeData.confirmDialog.label.desc": "Are you completely sure that you want to delete your data now?",
    "revokeData.confirmDialog.button.delete": "Yes, delete permanently",
    "revokeData.closeDialog.label.title": "Data deleted",
    "revokeData.closeDialog.label.desc": "Your data has been irrevocably deleted. We hope to see you again soon!",
    "revokeData.closeDialog.button.bye": "Goodbye!",
    "ommitReason.label.navBarTitle": "Skip medication",
    "ommitReason.label.title": "Is there any particular reason why you are skipping the medication?",
    "ommitReason.button.noReason": "Nothing to add",
    "ommitReason.label.storageHint": "Your information about a skipped medication will be noted in your statistics and can provide important information to your doctor.",
    "ommitReason.label.reasonHint": "Select reason",
    "updateDialog.label.title": "Update Available",
    "updateDialog.label.message": "A new version of {amount} is available.",
    "updateDialog.button.update": "Update",
    "mediCheck.teaser.screen1.label.title": "The medi-check",
    "mediCheck.teaser.screen1.label.desc": "The Medi-Check conveniently and safely checks your medication for interactions and much more from home.",
    "mediCheck.teaser.screen1.label.hint": "Personally carried out by the pharmacist FPH Anja Bauert from the toppharm Apotheke Witikon / ZH.",
    "mediCheck.teaser.screen1.button.close": "Close",
    "mediCheck.teaser.screen1.button.learnMore": "Continue",
    "mediCheck.teaser.screen2.label.title": "Drug analysis",
    "mediCheck.teaser.screen2.label.desc.full": "The medi-check includes:\n\n- interactions\n- double medication\n- Instructions for use\n- Advice on allergies\n\nSend your medication for review directly from TOM!",
    "mediCheck.teaser.screen2.label.desc.highlighted": "- interactions\n- double medication\n- Instructions for use\n- Advice on allergies",
    "mediCheck.teaser.screen2.button.close": "Close",
    "mediCheck.teaser.screen2.button.continue": "Create now",
    "mediCheckList.label.navBarTitle": " ",
    "mediCheckList.switch.label.open": " ",
    "mediCheckList.openSection.label.paymentNotCompleted": " ",
    "mediCheckList.openSection.label.inProgress": " ",
    "mediCheckList.openSection.label.inquiry": " ",
    "mediCheckList.switch.label.finished": " ",
    "mediCheckList.button.startMediCheck": " ",
    "mediCheckList.empty.label.open": " ",
    "mediCheckList.empty.label.finished": " ",
    "mediCheckList.empty.label.general": " ",
    "mediCheckList.label.transmitted": "Transmitted on {amount}",
    "mediCheckList.label.recieved": "Recieved on {amount}",
    "mediCheckCreation.label.navBarTitle": " ",
    "mediCheckCreation.medSelection.label.title": " ",
    "mediCheckCreation.medSelection.label.hintCabinet": " ",
    "mediCheckCreation.medSelection.label.addMed": " ",
    "mediCheckCreation.medSelection.button.next": " ",
    "mediCheckCreation.medSelection.error.limitReached": " ",
    "mediCheckCreation.medSelection.error.selectMore.label.title": "Too little medication selected",
    "mediCheckCreation.medSelection.error.selectMore.label.desc": "You have to choose at least 2 medicaments",
    "mediCheckCreation.dialogQuit.label.title": " ",
    "mediCheckCreation.dialogQuit.label.desc": " ",
    "mediCheckCreation.dialogQuit.button.continue": " ",
    "mediCheckCreation.dialogQuit.button.cancel": " ",
    "mediCheckCreation.allergieSelection.label.title": " ",
    "mediCheckCreation.allergieSelection.label.allergyType.food": "Food allergy",
    "mediCheckCreation.allergieSelection.label.allergyType.medication": "Medication allergy",
    "mediCheckCreation.allergieSelection.label.allergyType.other": "Other allergy",
    "mediCheckCreation.allergieSelection.label.addAllergie": " ",
    "mediCheckCreation.allergieSelection.label.noAllergiesSoFar": " ",
    "mediCheckCreation.allergieSelection.button.next": " ",
    "mediCheckCreation.allergieSelection.button.nextWithoutAllergies": " ",
    "mediCheckCreation.allergieSelection.error.limitReached": " ",
    "mediCheckCreation.summary.label.title": " ",
    "mediCheckCreation.summary.label.allergyType.food": "Selected Food allergies",
    "mediCheckCreation.summary.label.allergyType.medication": "Selected Medication allergies",
    "mediCheckCreation.summary.label.allergyType.other": "Selected other allergies",
    "mediCheckCreation.summary.label.meds": " ",
    "mediCheckCreation.summary.label.editMeds": " ",
    "mediCheckCreation.summary.label.editAllergies": " ",
    "mediCheckCreation.summary.label.legalDisclaimer": " ",
    "mediCheckCreation.summary.button.buy": " ",
    "mediCheckCreation.summary.label.payment.in.progress": " ",
    "mediCheckDetails.label.id": "Medi-Check ID",
    "mediCheckDetails.label.buyInfo": " ",
    "mediCheckDetails.label.redeemInfo": " ",
    "mediCheckDetails.button.buy": " ",
    "mediCheckDetails.button.redeem": " ",
    "mediCheckDetails.label.pharmacyInquiry": "There are questions from the pharmacy about your Medi-Check. Contact them as soon as possible.",
    "mediCheckDetails.label.pharmacyInfo.name": "TopPharm Apotheke Witikon",
    "mediCheckDetails.label.pharmacyInfo.address": "Carl Spitteler-Strasse 2\n8053 Zürich",
    "mediCheckDetails.label.pharmacyInfo.phone": "044 380 00 26",
    "mediCheckDetails.label.pharmacyInfo.mail": "info@witikon.apotheke.ch",
    "mediCheckDetails.label.payment.in.progress": " ",
    "pharmacy.overview.label.pharmacy": "Pharmacy",
    "allergies.label.navBarTitle": "My allergies",
    "allergies.empty.label.title": "Do you have one or more medication, food or other allergies?",
    "allergies.empty.button.add": "Add allergy",
    "allergies.label.title": "Your previously specified allergies:",
    "allergies.button.add": "Add allergy",
    "allergies.button.save": "Save",
    "allergies.label.allergyType.food": "Food allergy",
    "allergies.label.allergyType.medication": "Medication allergy",
    "allergies.label.allergyType.other": "Other allergy",
    "allergies.addDialog.label.title": "Add allergy",
    "allergies.search.label.title": " ",
    "allergies.textInput.label.title": " ",
    "allergies.search.label.medHint": " ",
    "allergies.search.label.searchFieldHint": " ",
    "allergies.textInput.label.foodHint": " ",
    "allergies.textInput.label.otherHint": " ",
    "allergies.textInput.label.textFieldHint": "Enter allergy",
    "allergies.textInput.button.confirm": "Apply"
}
export default en