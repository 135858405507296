import axios, { AxiosInstance } from 'axios'
import { PaginatedResponse, MedicineArticle, MedicineUnit } from '../state/definition'
import env from '../conf/env'

export interface Headers {
    [index: string]: string
}

export class NetworkClient {
    private client: AxiosInstance
    constructor() {
        this.client = axios.create({
            baseURL: env.API_ENDPOINT_BASE,
            timeout: 15000,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
            }
        })
    }

    article = {
        getAll: async (name: string, language: string, page?: number, limit?: number): Promise<PaginatedResponse<MedicineArticle>> => {
            const response = await this.client.get('web/article/list', { params: { name, language, page, limit } })
            return response.data
        }
    }

    articleUnit = {
        getAll: async (language?: string): Promise<Array<MedicineUnit>> => {
            const response = await this.client.get('app/articleunit/all', { params: { language } })
            return response.data
        }
    }
}

export default new NetworkClient()
