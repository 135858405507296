import React, { FunctionComponent, useState } from 'react'
import { Theme, Box, Divider, CardMedia, Tooltip, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core'
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded'
import { makeStyles } from '@material-ui/styles'
import logo from '../../svg/logo.svg'
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import { FormWizardActions, FormValuesActions } from '../../state/actions'
import { useDispatch } from 'react-redux'
import { goBack, replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { FormType } from '../../state/definition'
import { FormattedMessage } from 'react-intl'

const useStyles =  makeStyles((theme: Theme) => ({
    headerWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '70px'
    },
    homeLogo: {
        position: 'absolute',
        maxWidth: '140px',
        top: '15px',
        left: '35px',
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '120px',
        }
    },
    reloadIcon: {
        position: 'absolute',
        top: '15px',
        right: '50px',
        cursor: 'pointer'
    },
    divider: {
        position: 'absolute',
        width: '100%',
        top: '35px',
        backgroundColor: theme.colors.blueGrayDark1,
        opacity: 0.4,
    },
    logo: {
        position: 'absolute',
        zIndex: 1,
        height: '70px',
        width: '55px',
        background: theme.colors.white
    },
    arrowLink: {
        position: 'absolute',
        cursor: 'pointer',
        top: '100%',
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.2s linear',
        '&:focus': {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s 0.2s, opacity 0.2s linear'
        }
    },
    dialog: {
        '& h2': {
            textTransform: 'uppercase',
            color: theme.colors.purple,
            textAlign: 'center'
        },
        '& h5': {
            fontSize: '20px'
        }
    },
}))

const Header: FunctionComponent = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const isFirstOrLastPage = location.pathname === FormType.SEARCH_FORM || location.pathname.split('/')[1] === FormType.QR_CODE

    const renderDialog = () => {
        return (
            <Dialog
                aria-labelledby="restart-dialog-title"
                className={classes.dialog}
                open={dialogOpen}
            >
                <DialogTitle id="restart-dialog-title">
                    <FormattedMessage id='general.label.startOver.title' />
                </DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Box m={4}>
                        <Typography
                            variant="h5"
                            align="center"
                        >
                            <FormattedMessage id='general.label.startOver.desc' />
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { setDialogOpen(false) }}
                        color="primary"
                    >
                        <Typography variant="h5">
                            <FormattedMessage id='general.label.cancel' />
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(replace({ pathname: `${FormType.SEARCH_FORM}`, state: { restart: location } }))
                            dispatch(FormValuesActions.confirmValues(false))
                            setDialogOpen(false)
                        }}
                        color="primary"
                        variant="contained"
                        disableRipple
                    >
                        <Typography variant="body2">
                            <FormattedMessage id='general.label.startOver' />
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Box className={classes.homeLogo}>
                <a
                    href={`https://www.tommedications.com/`}
                    target="blank"
                >
                    <CardMedia
                        image={`https://www.tommedications.com/wp-content/uploads/2022/04/TOM-Logo.svg`}
                        component="img"
                        alt='TOM-Logo'
                    />
                </a>
            </Box>
            <Box marginTop={3} className={classes.headerWrapper}>
                <Divider variant="fullWidth" className={classes.divider} />
                <img
                    src={logo}
                    alt="logo"
                    className={classes.logo}
                />
                <Box marginTop={2}
                    className={classes.arrowLink}
                    style={{
                        visibility: isFirstOrLastPage ? 'hidden' : 'visible'
                    }}
                    onClick={
                        () => {
                            if (location.state && (location.state.fromReminderOverview || location.state.fromOverview)) {
                                dispatch(goBack())
                            } else {
                                dispatch(FormWizardActions.routeBack())
                            }
                        }
                    }
                >
                    <KeyboardArrowUpRoundedIcon
                        color="secondary"
                        fontSize="large"
                    />
                </Box>
            </Box>
            <Box
                className={classes.reloadIcon}
                onClick={() => setDialogOpen(true)}
            >
                <Tooltip
                    title={<FormattedMessage id='general.label.startOver' />}
                >
                    <ReplayRoundedIcon color="primary" />
                </Tooltip>
            </Box>
            {renderDialog()}
        </>
    )
}

export default Header