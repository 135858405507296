export enum TypeKey {
    ROUTE_FORWARD = 'ROUTE_FORWARD',
    ROUTE_BACK = 'ROUTE_BACK'
}

export interface RouteForwardTypeAction {
    type: TypeKey.ROUTE_FORWARD
}

export interface RouteBackTypeAction {
    type: TypeKey.ROUTE_BACK
}

export type FormWizardActionTypes = RouteForwardTypeAction | RouteBackTypeAction

export const routeForward = (): RouteForwardTypeAction => ({
    type: TypeKey.ROUTE_FORWARD,
})

export const routeBack = (): RouteBackTypeAction => ({
    type: TypeKey.ROUTE_BACK
})

export const FormWizardActions = {
    routeForward,
    routeBack
}