/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { FormType, AppState, FormValuesState, UnitState, DateTimeQuantityUnit, MedicineArticle, ReminderType } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Typography, makeStyles, Theme, FormControl, InputLabel, InputAdornment, Select, MenuItem, FilledInput, Dialog, DialogActions, Box, DialogContent, DialogTitle } from '@material-ui/core'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded'
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded'
import { FormValuesActions, FormWizardActions, CommonActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import { set, isSameHour, isSameMinute } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { goBack, push } from 'connected-react-router'

const useStyles =  makeStyles((theme: Theme) => ({
    formControl: {
        [theme.breakpoints.down('sm')]: {
           //  width: '300px'
        },
        '.svg': {
            margin: 5, 
            fill: theme.colors.lightBlue
        },
        '& input[type=number]': {
            '&::-webkit-inner-spin-button': {
                opacity: 1,
                height: '35px',
                width: '35px',
                marginRight: '5px',
                background: theme.colors.purpleLight5
            }
        }
    },
    cardIcon: {
        backgroundColor: theme.colors.purpleLight5,
        borderRadius: '7px',
        marginRight: '12px'
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    dialog: {
        '& h2': {
            textTransform: 'uppercase',
            color: theme.colors.purple,
            textAlign: 'center'
        }, 
        '& h5': {
            fontSize: '20px'
        }
    }
}))

const dateTimeQuantityUnitForm: Form = {
    formType: FormType.DATE_TIME_QUANTITY_UNIT_FORM,
    title: () => <FormattedMessage id='create.dailyReminder.label.createFirst.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const intl = useIntl()
        const location = useLocation() 
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const unitState: UnitState = useSelector((state: AppState) => state.unit)

        const locale = intl.locale.split('-')[0]
        let selectedArticleUnitType: string 
        const article: MedicineArticle = formValuesState.article as MedicineArticle
        if (article instanceof Object && article.unit) {
            selectedArticleUnitType = article.unit.unitType
        } else {
            selectedArticleUnitType = unitState.unitList && unitState.unitList[0].unitType
        }

        let updateReminder: DateTimeQuantityUnit 
        let defaultId = formValuesState.idCounter
        let defaultDate = formValuesState.reminderType === ReminderType.ONCE ? new Date() : undefined
        let defaultStartTime = set(new Date(), { hours: 8, minutes: 0, seconds: 0 })
        let defaultUnit = selectedArticleUnitType
        let defaultQuantity = 1

        if (location.state && location.state.reminderId) {
            updateReminder = formValuesState.dateTimeQuantityUnits.find(reminder => reminder.id === location.state.reminderId)
            defaultId = updateReminder.id
            defaultStartTime = updateReminder.timeOfDayStart
            defaultUnit = updateReminder.unit
            defaultQuantity = updateReminder.quantity
        }
        if (formValuesState.reminderType === ReminderType.ONCE && formValuesState.dateTimeQuantityUnits.length !== 0) {
            const updateReminder: DateTimeQuantityUnit = formValuesState.dateTimeQuantityUnits[0]
            defaultDate = updateReminder.date
            defaultId = updateReminder.id
            defaultStartTime = updateReminder.timeOfDayStart
            defaultUnit = updateReminder.unit
            defaultQuantity = updateReminder.quantity
        }

        const [selectedDate, setSelectedDate] = React.useState<Date | null>(defaultDate)
        const [selectedStartTime, setSelectedStartTime] = React.useState<Date | null>(defaultStartTime)
        const [unit, setUnit] = React.useState<string>(defaultUnit)
        const [quantity, setQuantity] = React.useState<number>(defaultQuantity)
        const [openDialog, setOpenDialog] = React.useState<boolean>(false)

        const dateTimeQuantityUnit: DateTimeQuantityUnit = {
            id: defaultId,
            date: selectedDate, 
            timeOfDayStart: selectedStartTime,
            timeOfDayEnd: undefined,
            hourInterval: undefined,
            unit: unit,
            quantity: quantity
        }

        const unitMinValue = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.minValue) || 1
        const unitMaxValue = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.maxValue) || 999
        const unitStep = (unit && unitState && unitState.unitList.find(item => item.unitType === unit)?.step) || 1 

        const handleUnitSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (unit !== event.target.value) {
                setQuantity(1)
            }
            setUnit(event.target.value)
        }

        const handleQuantitySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault()
            if (Number(event.target.value) < unitMinValue ) {
                setQuantity(unitMinValue)
            } else if (Number(event.target.value) > unitMaxValue ) {
                setQuantity(unitMaxValue)
            } else {
                setQuantity(Number(event.target.value))
            }
        }

        const handleForwardAction = () => {
            const duplicateTimeofDayStart = formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.dateTimeQuantityUnits
                .filter(reminder => reminder.id !== dateTimeQuantityUnit.id)
                .find(element =>
                    isSameHour(element.timeOfDayStart, dateTimeQuantityUnit.timeOfDayStart) && isSameMinute(element.timeOfDayStart, dateTimeQuantityUnit.timeOfDayStart) 
                )

            if (duplicateTimeofDayStart) {
                dispatch(CommonActions.setMessage('ALERT', 'create.dailyReminder.dialog.sameTime.title', 'create.dailyReminder.dialog.sameTime.desc', 4000, { type: 'warning' }))
                return 
            }

            if ((location.state && location.state.reminderId) || 
                (formValuesState.reminderType === ReminderType.ONCE && formValuesState.dateTimeQuantityUnits.length > 0 && !!formValuesState.dateTimeQuantityUnits[0].date)
            ) {
                dispatch(FormValuesActions.updateReminderTime(dateTimeQuantityUnit, dateTimeQuantityUnit.id))
            } else {
                dispatch(FormValuesActions.addReminderTime(dateTimeQuantityUnit)) 
            }

            if (formValuesState.reminderType === ReminderType.ONCE && formValuesState.dateTimeQuantityUnits.length > 0 && location.state && location.state.fromOverview) {
                if (formValuesState.dateTimeQuantityUnits[0].unit !== dateTimeQuantityUnit.unit) {
                    setOpenDialog(true)
                    return
                }
            }
            
            if (location.state && (location.state.fromReminderOverview || location.state.fromOverview)) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            }
        }

        const renderAlertDialog = () => {
            return (
                <Dialog
                    aria-labelledby="alert-dialog-dateTimeQuantity"
                    className={classes.dialog} 
                    open={openDialog}
                >
                    <DialogTitle id="alert-dialog-dateTimeQuantity">
                        <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.label.title' /> 
                    </DialogTitle>
                    <DialogContent 
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box m={4}>
                            <Typography variant="h5" align="center">
                                <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.label.desc' />
                            </Typography> 
                        </Box>                                                   
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button 
                            onClick={() => {
                                dispatch(push({ pathname: `/${FormType.AMOUNT_FORM}`, state: { fromReminderOverview: location } }))
                                dispatch(FormValuesActions.setAmount(null)) 
                                setOpenDialog(false)
                            }} 
                            color="primary"
                            variant="contained"
                            disableRipple
                        >
                            <Typography variant="body2">
                                <FormattedMessage id='create.dailyReminderList.dialog.stockUnit.button.change' />
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >                
                {formValuesState.reminderType === ReminderType.ONCE &&
                    <Grid item>
                        <DatePicker
                            label={<FormattedMessage id="create.onceReminder.button.date.title" />}
                            cancelLabel={<FormattedMessage id="general.label.cancel" />}
                            okLabel={<FormattedMessage id="general.label.confirm" />}
                            format="EEEE, d. MMMM yyyy"
                            inputVariant="filled"
                            orientation="portrait"
                            openTo="date"
                            value={selectedDate}
                            onChange={setSelectedDate}
                            disablePast
                            InputProps={{
                                disableUnderline: true,
                                fullWidth: true,
                                endAdornment: (
                                    <InputAdornment position="end"> 
                                        <CalendarTodayRoundedIcon
                                            fontSize="small"  
                                            classes={{ root: classes.cardIcon }}
                                            style={{ 
                                                marginRight: '0px', 
                                                padding: '3px'
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                }
                <Grid item>
                    <TimePicker
                        className={classes.formControl}
                        label={<FormattedMessage id="create.dailyReminder.time.title" />}
                        ampm={false}
                        openTo="hours"
                        format="HH:mm"
                        cancelLabel={<FormattedMessage id="general.label.cancel" />}
                        okLabel={<FormattedMessage id="general.label.confirm" />}
                        value={selectedStartTime}
                        inputVariant="filled"
                        orientation="portrait"
                        onChange={setSelectedStartTime}
                        InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            endAdornment: (
                                <InputAdornment position="end"> 
                                    <ScheduleRoundedIcon
                                        fontSize="small"  
                                        classes={{ root: classes.cardIcon }}
                                        style={{ 
                                            marginRight: '0px', 
                                            padding: '3px'
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>  
                <Grid item>
                    <FormControl 
                        variant="filled" 
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="select-unit_label">
                            <FormattedMessage id={`create.dailyReminder.unit.title`} /> 
                        </InputLabel>
                        <Select
                            labelId="select-unit-label"
                            id="select-unit"
                            fullWidth
                            disableUnderline
                            value={unit || ''}
                            onChange={handleUnitSelect}
                        >
                            {unitState && unitState.unitList && 
                                unitState.unitList.map(unit => (
                                    <MenuItem 
                                        key={unit.id} 
                                        value={unit.unitType || ''}
                                    >
                                        {unit.name[`${locale}`]}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>  
                <Grid item>
                    <FormControl 
                        variant="filled" 
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="select-quantity-label">
                            <FormattedMessage id={`create.dailyReminder.quantitiy.title`} /> 
                        </InputLabel>
                        <FilledInput
                            id="select-quantity-label"
                            name="select-quantity"
                            fullWidth
                            disableUnderline
                            type="number"
                            inputProps={{
                                min: unitMinValue,
                                max: unitMaxValue,
                                step: unitStep
                            }} 
                            value={quantity}
                            onChange={handleQuantitySelect}
                        />
                    </FormControl>
                </Grid>  
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleForwardAction}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.dailyReminder.button.next' />
                        </Typography>
                    </Button>
                </Grid> 
                {location.state && location.state.fromReminderOverview && (
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => { 
                                dispatch(goBack())
                            }}
                        >
                            <Typography variant="h5">
                                <FormattedMessage id='create.dailyReminder.button.cancel' />
                            </Typography>                   
                        </Button>
                    </Grid>
                )}
                {renderAlertDialog()}              
            </Grid>
        )
    }
}

export default dateTimeQuantityUnitForm