import { SearchResult } from '../definition'

export enum TypeKey {
    PERFORM_SEARCH = 'PERFORM_SEARCH',
    SET_RESULT = 'SET_RESULT',
    SET_QUERY = 'SET_QUERY'
}

export interface PerformSearchAction {
    type: TypeKey.PERFORM_SEARCH
    locale: string
    pageNumber: number | null
}

export interface SetResultAction {
    type: TypeKey.SET_RESULT
    result: SearchResult | undefined
}

export interface SetQueryAction {
    type: TypeKey.SET_QUERY
    query: string
}

export type SearchActionTypes = PerformSearchAction | SetResultAction | SetQueryAction

export const performSearch = (locale: string, pageNumber: number | null = null): PerformSearchAction => ({
    type: TypeKey.PERFORM_SEARCH,
    locale,
    pageNumber
})

export const setResult = (result: SearchResult | undefined): SetResultAction => ({
    type: TypeKey.SET_RESULT,
    result
})

export const setQuery = (query: string): SetQueryAction => ({
    type: TypeKey.SET_QUERY,
    query
})

export const SearchActions = {
    performSearch,
    setResult,
    setQuery
}