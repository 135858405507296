import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import formValues from './formValues'
import search from './search'
import unit from './unit'
import common from './common'

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    formValues,
    search,
    unit,
    common
})

export default rootReducer