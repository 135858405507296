import { FormValuesState, DateTimeQuantityUnit } from "../definition"
import { TypeKey, FormValuesActionTypes } from "../actions/formValues"
import defaultState from '../defaultState'

const formValues = (state: FormValuesState | null = null, action: FormValuesActionTypes) => {    
    switch (action.type) {
        case TypeKey.SET_ARTICLE: {
            const { article } = action
            return { ...state, article: article}
        } 
        case TypeKey.SET_REMINDER_TYPE: {
            const { reminderType } = action
            return { ...state, reminderType: reminderType}
        } 
        case TypeKey.SET_WEEKDAYS: {
            const { weekdays } = action
            return { ...state, weekdays: weekdays}
        } 
        case TypeKey.SET_AMOUNT: {
            const { amount } = action
            return { ...state, amount: amount}
        }
        case TypeKey.SET_DAY_INTERVAL: {
            const { dayInterval } = action
            return { ...state, dayInteval: dayInterval}
        }
        case TypeKey.SET_INTAKE_RECOMMENDATION: {
            const { intakeRecommendation } = action
            return { ...state, intakeRecommendation: intakeRecommendation}
        }
        case TypeKey.SET_THERAPY_DURATION: {
            const { therapyDuration } = action
            return { ...state, therapyDuration: therapyDuration}
        }
        case TypeKey.SET_NOTE: {
            const { note } = action
            return { ...state, note: note}
        }
        case TypeKey.SET_QR_VALUES: {
            const { values } = action
            return { ...state, qrCodeValues: values}
        }
        case TypeKey.ADD_REMINDER_TIME: {
            const { dateTimeQuantityUnit } = action
           
            return { 
                ...state,
                idCounter: state.idCounter + 1,
                dateTimeQuantityUnits: [ 
                    ...state.dateTimeQuantityUnits, dateTimeQuantityUnit 
                ]
            }
        } 
        case TypeKey.UPDATE_REMINDER_TIME: {
            const { dateTimeQuantityUnit, id } = action
            const dateTimeQuantityUnitToUpdate: DateTimeQuantityUnit[] = state.dateTimeQuantityUnits.filter(reminder => reminder.id !== id)

            return { 
                ...state, 
                dateTimeQuantityUnits: [ 
                    ...dateTimeQuantityUnitToUpdate, dateTimeQuantityUnit 
                ] 
            }
        }
        case TypeKey.REMOVE_REMINDER_TIME: {
            const { id } = action
            const reminderListToUpdate: DateTimeQuantityUnit[] = state.dateTimeQuantityUnits.filter(reminder => reminder.id !== id)
            return { ...state, dateTimeQuantityUnits: reminderListToUpdate }
        }       
        case TypeKey.RESET: {
            return {
                ...state,
                reminderType: defaultState.formValues.reminderType,
                dayInteval: defaultState.formValues.dayInteval,
                weekdays: defaultState.formValues.weekdays, 
                idCounter: defaultState.formValues.idCounter,
                dateTimeQuantityUnits: defaultState.formValues.dateTimeQuantityUnits,
                therapyDuration: defaultState.formValues.therapyDuration,
                amount: defaultState.formValues.amount,
                intakeRecommendation: defaultState.formValues.intakeRecommendation,
                note: defaultState.formValues.note,
                qrCodeValues: defaultState.formValues.qrCodeValues
            }
        }
        default:
            return state
    }
}

export default formValues