/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormType, SearchState, AppState, CommonState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, InputLabel, FilledInput, InputAdornment, makeStyles, Theme, Typography, Grid, Divider, Card, CardContent, CardMedia, Box, CircularProgress } from '@material-ui/core'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import InfiniteScroll from "react-infinite-scroll-component"
import { SearchActions, FormValuesActions, FormWizardActions, CommonActions } from '../../../../state/actions'
import cardLogo from '../../../../svg/cardLogo.svg'
import liquids from '../../../../svg/liquids.svg'
import pills from '../../../../svg/pills.svg'
import { useLocation } from 'react-router-dom'
import { goBack } from 'connected-react-router'

const useStyles =  makeStyles((theme: Theme) => ({
    subtitle: {
        color: theme.colors.purple,
        marginBottom: '40px'
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            // width: '300px'
        }
    },
    card: {
        boxShadow: '0px 0px',
        padding: '5px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
           // width: '300px'
        }
    },
    cardContent: {
        width: '100%'
    },
    mediaWrapper: {
        padding: '10px',
        backgroundColor: theme.colors.white,
    },
    media: {
        width: '55px',
        objectFit: 'contain'
    },
    resultTitle: {
        textAlign: 'left'
    }
}))

const searchForm: Form = {
    formType: FormType.SEARCH_FORM,
    title: () => <FormattedMessage id='welcome.label.textSegment.i0' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const intl = useIntl()
        const location = useLocation()
        const searchState: SearchState = useSelector((state: AppState) => state.search)
        const commonState: CommonState = useSelector((state: AppState) => state.common)

        React.useEffect(() => {
            if (location.state && location.state.restart) {
                dispatch(SearchActions.setQuery(''))
                dispatch(SearchActions.setResult(undefined))
                dispatch(FormValuesActions.reset())
                setNextPage(1)
            }
        }, [dispatch, location.state])

        const locale = intl.locale.split('-')[0]
        const { query, result } = searchState

        const [nextPage, setNextPage] = React.useState<number>(1)

        const handleQueryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(SearchActions.setQuery(event.target.value))

            if (event.target.value.length === 0 || event.target.value !== query) {
                dispatch(SearchActions.setResult(undefined))
                setNextPage(1)
            }
            if (event.target.value.length >= 3) {
                dispatch(SearchActions.performSearch(locale, 0))
            }
        }

        const handleClearQuery = () => {
            dispatch(SearchActions.setQuery(''))
            dispatch(SearchActions.setResult(undefined))
            dispatch(CommonActions.setLoading(false))
            setNextPage(1)
        }

        const fetchMoreData = () => {
            dispatch(SearchActions.performSearch(locale, nextPage))
            nextPage < result.totalPages && setNextPage(nextPage + 1)
        }

        const renderFallbackImage = (unitType?: string) => {
            return (
                <Box className={classes.mediaWrapper}>
                    {unitType === 'ML' ? (
                        <CardMedia
                            component="img"
                            height="55"
                            className={classes.media}
                            image={liquids}
                            title='liquidsImg'
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            height="55"
                            className={classes.media}
                            image={pills}
                            title='pillsImg'
                        />
                    )}
                </Box>
            )
        }

        return (
            <>
                <Typography align="center" variant="h1" className={classes.subtitle}>
                    <FormattedMessage id='welcome.web.label.textSegment.i1' />
                </Typography>
                <Typography align="center" variant="h2" className={classes.subtitle}>
                    <FormattedMessage id='welcome.web.label.textSegment.i2' />
                </Typography>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid item>
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                        >
                            <InputLabel htmlFor="search">
                                <FormattedMessage id={`create.search.label.placeholder.normal`} />
                            </InputLabel>
                            <FilledInput
                                id="search"
                                fullWidth
                                disableUnderline
                                value={query}
                                onChange={handleQueryInput}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ClearRoundedIcon
                                            fontSize="small"
                                            style={{
                                                cursor: 'pointer',
                                                display: query && query.length > 0 ? 'flex' : 'none'
                                            }}
                                            onClick={handleClearQuery}
                                        />
                                        <SearchRoundedIcon
                                            style={{
                                                display: query && query.length > 0 ? 'none' : 'flex'
                                            }}
                                        />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    {query &&
                        <Card
                            className={classes.card}
                            style={{ backgroundColor: "#E2EFF4" }}
                            onClick = {() => {
                                dispatch(FormValuesActions.setArticle(query))
                                if (location.state && location.state.fromOverview) {
                                    dispatch(goBack())
                                } else {
                                    dispatch(FormWizardActions.routeForward())
                                }
                            }}
                        >
                            <Box className={classes.mediaWrapper}>
                                <CardMedia
                                    className={classes.media}
                                    component="img"
                                    height="55"
                                    image={cardLogo}
                                    title='defaultImg'
                                />
                            </Box>
                            <CardContent
                                classes={{
                                    root: classes.cardContent
                                }}
                            >
                                <Typography variant="h5">
                                    {query}
                                </Typography>
                                <Typography variant="h6">
                                    <FormattedMessage id='create.search.label.yourInput' />
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                    {result && result.articles && result.articles.length > 0 && (
                        <Grid item>
                            <Typography
                                className={classes.resultTitle}
                                variant="subtitle1"
                                gutterBottom
                            >
                                <FormattedMessage id='create.search.label.searchResults' />
                            </Typography>
                            <Divider variant="fullWidth" />
                            <InfiniteScroll
                                dataLength={result && result.articles && result.articles.length}
                                next={fetchMoreData}
                                hasMore={result && result.articles && result.articles.length < result.totalElements}
                                loader={
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                }
                            >
                                {result.articles.map(article => (
                                    <div key={article.id}>
                                        <Card
                                            className={classes.card}
                                            onClick = {() => {
                                                dispatch(FormValuesActions.setArticle(article))
                                                if (location.state && location.state.fromOverview) {
                                                    dispatch(goBack())
                                                } else {
                                                    dispatch(FormWizardActions.routeForward())
                                                }
                                            }}
                                        >
                                            {article.imageTypes && article.imageURLTemplate ?
                                                (

                                                    <Box className={classes.mediaWrapper}>
                                                        <CardMedia
                                                            component="img"
                                                            height="55"
                                                            className={classes.media}
                                                            image={article.imageURLTemplate}
                                                            title='articleImg'
                                                        />
                                                    </Box>
                                                ) :
                                                renderFallbackImage(article.unit?.unitType)
                                            }
                                            <CardContent
                                                classes={{
                                                    root: classes.cardContent
                                                }}
                                            >
                                                {article.product?.name &&
                                                    <Typography variant="h5">
                                                        {article.product?.name[`${locale}`] ?
                                                            article.product?.name[`${locale}`] :
                                                            article.product?.name['de']
                                                        }
                                                    </Typography>
                                                }
                                                {article.quantity && article.unit?.name && (
                                                    <Typography variant="h6">
                                                        {`${article.quantity} ${article.unit?.name[`${locale}`]}`}
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </Card>
                                        <Divider variant="fullWidth" />
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </Grid>
                    )}
                    {commonState.loading && (
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </>
        )
    }
}

export default searchForm