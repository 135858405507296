import { createStore, applyMiddleware, compose, Store} from 'redux'
import createSagaMiddleware, { SagaMiddleware, Task } from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './reducers'
import saga from './sagas'
import { AppState } from './definition'
import defaultState from './defaultState'

export const history = createBrowserHistory()

let store: any
let sagaTask: Task
/**
 * Init store with partial initial state.
 * @param {AppState} initialState
 *
 */
export const initStore = (initialState: AppState | {} = {}): Store<any> => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const sagaMiddleware: SagaMiddleware<any> = createSagaMiddleware()

    const state: AppState = { ...defaultState, ...initialState }

    store = createStore(
        rootReducer(history),
        state,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                sagaMiddleware
            )
        )
    )

    store.runSagaTask = () => {
        store.sagaTask = sagaMiddleware.run(saga)
        sagaTask = store.sagaTask
    }

    store.runSagaTask()

    return store
}

export const getStore = (): Store<AppState> => store
export const getRunningSagaMiddleWare = () => sagaTask