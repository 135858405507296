/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { FormType, ReminderType } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage } from 'react-intl'
import { Typography, Card, CardContent, Grid, makeStyles, Theme } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { FormValuesActions } from '../../../../state/actions'

const useStyles =  makeStyles((theme: Theme) => ({
    card: {
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
           //  width: '300px'
        }
    },
    iconWrapper: {
        backgroundColor: theme.colors.purpleLight5,
        color: theme.colors.purpleLight3,
        borderRadius: '7px',
        marginRight: '16px'
    }
}))

const reminderSelectionForm: Form = {
    formType: FormType.REMINDER_SELECTION_FORM,
    title: () => <FormattedMessage id='create.intervalSelection.label.title' />,
    formContent: (props) => {
        const classes = useStyles()
        const dispatch = useDispatch()

        let reminderTypes: Array<{
            id: ReminderType
            type: React.ReactElement
            description: React.ReactElement
        }> = []

        if (ReminderType.DAILY) {
            reminderTypes.push({
                id: ReminderType.DAILY,
                type: <FormattedMessage id='create.intervalSelection.label.daily.title' />,
                description: <FormattedMessage id='create.intervalSelection.label.daily.desc' />
            })
        }
        if (ReminderType.WEEKDAY) {
            reminderTypes.push({
                id: ReminderType.WEEKDAY,
                type: <FormattedMessage id='create.intervalSelection.label.weekday.title' />,
                description: <FormattedMessage id='create.intervalSelection.label.weekday.desc' />
            })
        }
        if (ReminderType.DAILY_INTERVAL) {
            reminderTypes.push({
                id: ReminderType.DAILY_INTERVAL,
                type: <FormattedMessage id='create.intervalSelection.label.days.title' />,
                description: <FormattedMessage id='create.intervalSelection.label.days.desc' />
            })
        }
        if (ReminderType.HOUR_INTERVAL) {
            reminderTypes.push({
                id: ReminderType.HOUR_INTERVAL,
                type: <FormattedMessage id='create.intervalSelection.label.hours.title' />,
                description: <FormattedMessage id='create.intervalSelection.label.hours.desc' />
            })
        }
        if (ReminderType.ONCE) {
            reminderTypes.push({
                id: ReminderType.ONCE,
                type: <FormattedMessage id='create.intervalSelection.label.once.title' />,
                description: <FormattedMessage id='create.intervalSelection.label.once.desc' />
            })
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                { reminderTypes.map((info, i) => (
                    <Grid 
                        key={info.id}
                        item
                    >
                        <Card
                            className={classes.card}
                            onClick = {() => {  
                                dispatch(FormValuesActions.setReminderType(info.id))              
                            }}
                        >                                
                            <CardContent>                                
                                <Typography variant="h5">
                                    {info.type}
                                </Typography>
                                <Typography variant="h6">
                                    {info.description}
                                </Typography>                   
                            </CardContent>
                            <ChevronRightRoundedIcon 
                                classes={{ root: classes.iconWrapper }}
                            />
                        </Card>
                    </Grid>
                )) }                
            </Grid>
        )
    }
}

export default reminderSelectionForm