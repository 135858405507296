/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { FormType, FormValuesState, AppState, TherapyDurationType } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles, Theme, Button, Typography, Grid, FormControlLabel, RadioGroup, FormLabel, FormControl, Box, Radio, InputAdornment, Collapse, FilledInput, InputLabel } from '@material-ui/core'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import { isAfter, differenceInCalendarDays, addDays, isBefore } from 'date-fns'

const useStyles =  makeStyles((theme: Theme) => ({ 
    formControl: {
        '& input[type=number]': {
            '&::-webkit-inner-spin-button': {
                opacity: 1,
                height: '35px',
                width: '35px',
                marginRight: '5px',
                background: theme.colors.purpleLight5
            }
        }
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    cardIcon: {
        backgroundColor: theme.colors.purpleLight5,
        borderRadius: '7px',
        marginRight: '12px'
    },
    radioIcon: {
        color: theme.colors.purpleLight5,
        '&.Mui-checked': {
            color: theme.colors.purple 
        }
    },
    datePickerPlaceholder: {
        '& input': {
            color: theme.colors.purpleLight3,
            fontSize: '16px',
            fontWeight: 500 
        }        
    },
    datePickerInput: {
        '& input': {
            color: theme.colors.purple,
            fontSize: '16px' 
        }        
    }
}))

const therapyDurationForm: Form = {
    formType: FormType.THERAPY_DURATION_FORM,
    title: () => <FormattedMessage id='create.period.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const intl = useIntl()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const selectedTharapyDurationValues = formValuesState && formValuesState.therapyDuration

        let defaultDurationType = selectedTharapyDurationValues.durationType ? selectedTharapyDurationValues.durationType : TherapyDurationType.CONTINUOUS
        let defaultStartDate = selectedTharapyDurationValues.startDate ? selectedTharapyDurationValues.startDate : new Date()
        let defaultEndDate = selectedTharapyDurationValues.endDate ? selectedTharapyDurationValues.endDate : null
        let defaultDurationInDays = selectedTharapyDurationValues.durationInDays ? selectedTharapyDurationValues.durationInDays : undefined

        const [durationType, setDurationType] = useState<string>(defaultDurationType)
        const [startDate, setStartDate] = useState<Date | null>(defaultStartDate)
        const [endDate, setEndDate] = useState<Date | null>(defaultEndDate)
        const [durationInDays, setDurationInDays] = useState<number | undefined>(defaultDurationInDays)

        let therapyDurationValues = {
            durationType: durationType,
            startDate: startDate,
            endDate: endDate,
            durationInDays: durationInDays
        }

        const handleForwardAction = () => { 
            dispatch(FormValuesActions.setTherapyDuration(therapyDurationValues)) 
            
            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        const handleDurationTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            setDurationType(event.target.value as TherapyDurationType)
            if (event.target.value === TherapyDurationType.CONTINUOUS) {
                setEndDate(null)
                setDurationInDays(undefined)
            }
        }

        const handleDurationInDays = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (Number(event.target.value) < 1 ) {
                setDurationInDays(1)
                setEndDate(addDays(startDate, 0))
            } else {
                setDurationInDays(Number(event.target.value))
                setEndDate(addDays(startDate, Number(event.target.value) - 1))
            }
        }

        const handleStartDateSelect = (startDate: Date) => {
            setStartDate(startDate)
            setDurationInDays(differenceInCalendarDays(endDate, startDate) + 1)
        }

        const handleEndDateSelect = (endDate: Date) => {
            setEndDate(endDate)
            setDurationInDays(differenceInCalendarDays(endDate, startDate) + 1)
        }
        
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <DatePicker
                        label={<FormattedMessage id="create.period.label.startTime" />}
                        cancelLabel={<FormattedMessage id="general.label.cancel" />}
                        okLabel={<FormattedMessage id="general.label.confirm" />}
                        emptyLabel={intl.formatMessage({ id: 'create.period.dialog.startDate.title' })}
                        className={!startDate ? classes.datePickerPlaceholder : classes.datePickerInput}
                        format="EEEE, d. MMMM yyyy"
                        inputVariant="filled"
                        orientation="portrait"
                        openTo="date"
                        value={startDate}
                        onChange={(startDate) => handleStartDateSelect(startDate)}
                        maxDate={endDate ? endDate : new Date(2100, 1, 1)}
                        disableFuture={endDate ? isAfter(startDate, endDate) : false}
                        InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            endAdornment: (
                                <InputAdornment position="end"> 
                                    <CalendarTodayRoundedIcon
                                        fontSize="small"  
                                        classes={{ root: classes.cardIcon }}
                                        style={{ 
                                            marginRight: '0px', 
                                            padding: '3px'
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControl 
                        component="fieldset"
                        style={{ 
                            boxShadow: '0px 5px 22px -1px rgba(96, 167, 195, 0.52)'
                        }}
                    >
                        <Box style={{ padding: '5px 15px' }}>
                            <FormLabel 
                                component="h6"
                                style={{ margin: '10px 0px' }}
                            >
                                <FormattedMessage id={`create.period.label.periodType`} /> 
                            </FormLabel>
                            <RadioGroup
                                name="therapy-duration"
                                value={durationType}
                                onChange={handleDurationTypeSelect}
                            >
                                <FormControlLabel
                                    value={TherapyDurationType.CONTINUOUS} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    }
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.period.label.periodType.continuous' />
                                        </Typography>
                                    } 
                                />
                                <FormControlLabel 
                                    value={TherapyDurationType.END_DATE} 
                                    control={
                                        <Radio className={classes.radioIcon} />
                                    } 
                                    label={
                                        <Typography variant="h5">
                                            <FormattedMessage id='create.period.label.periodType.endDate' />
                                        </Typography>                                    
                                    } 
                                />
                            </RadioGroup>
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Collapse
                        in={durationType === TherapyDurationType.END_DATE} 
                        timeout={300}
                    >
                        <FormControl 
                            variant="filled"
                            className={classes.formControl}
                        >
                            <InputLabel htmlFor="durationInDays-label">
                                <FormattedMessage id="create.period.label.days" />
                            </InputLabel>
                            <FilledInput
                                id="durationInDays-label"
                                name="durationInDays"
                                fullWidth
                                disableUnderline
                                type="number"
                                inputProps={{
                                    min: 1,
                                    step: 1
                                }} 
                                value={durationInDays || ''}
                                onChange={handleDurationInDays}
                            />
                        </FormControl>
                    </Collapse>  
                </Grid>  
                <Grid item>
                    <Collapse
                        in={durationType === TherapyDurationType.END_DATE} 
                        timeout={300}
                    >
                        <DatePicker
                            label={endDate ? <FormattedMessage id="create.period.label.endDate" /> : ''}
                            cancelLabel={<FormattedMessage id="general.label.cancel" />}
                            okLabel={<FormattedMessage id="general.label.confirm" />}
                            emptyLabel={intl.formatMessage({ id: 'create.period.dialog.endDate.title' })}
                            className={!endDate ? classes.datePickerPlaceholder : classes.datePickerInput}
                            format="EEEE, d. MMMM yyyy"
                            inputVariant="filled"
                            orientation="portrait"
                            openTo="date"
                            value={endDate}
                            onChange={(endDate) => handleEndDateSelect(endDate)}
                            minDate={startDate}
                            disablePast={startDate ? isBefore(endDate, startDate) : false}
                            InputProps={{
                                disableUnderline: true,
                                fullWidth: true,
                                endAdornment: (
                                    <InputAdornment position="end"> 
                                        <CalendarTodayRoundedIcon
                                            fontSize="small"  
                                            classes={{ root: classes.cardIcon }}
                                            style={{ 
                                                marginRight: '0px', 
                                                padding: '3px'
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Collapse>
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={durationType === TherapyDurationType.END_DATE && (!endDate || !durationInDays)}
                        onClick={handleForwardAction}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.period.button.next' />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default therapyDurationForm