import { ReminderType, MedicineArticle, DateTimeQuantityUnit, Weekday, IntakeRecommendationType } from '../definition'

export enum TypeKey {
    SET_ARTICLE = 'SET_ARTICLE',
    SET_REMINDER_TYPE = 'SET_REMINDER_TYPE',
    SET_DAY_INTERVAL = 'SET_DAY_INTERVAL',
    SET_WEEKDAYS = 'SET_WEEKDAYS',
    SET_AMOUNT = 'SET_AMOUNT',
    SET_INTAKE_RECOMMENDATION = 'SET_INTAKE_RECOMMENDATION',
    SET_THERAPY_DURATION = 'SET_THERAPY_DURATION',
    SET_NOTE = 'SET_NOTE',
    ADD_REMINDER_TIME = 'ADD_REMINDER_TIME',
    UPDATE_REMINDER_TIME = 'UPDATE_REMINDER_TIME',
    REMOVE_REMINDER_TIME = 'REMOVE_REMINDER_TIME',
    RESET = 'RESET',
    CONFIRM = 'CONFIRM',
    SET_QR_VALUES='SET_QR_VALUES'
}

export interface SetArticleAction {
    type: TypeKey.SET_ARTICLE
    article: MedicineArticle | string | undefined
}

export interface SetReminderTypeAction {
    type: TypeKey.SET_REMINDER_TYPE
    reminderType: ReminderType | undefined
}

export interface SetWeekdaysAction {
    type: TypeKey.SET_WEEKDAYS
    weekdays: Weekday[]
}

export interface SetDayIntervalAction {
    type: TypeKey.SET_DAY_INTERVAL
    dayInterval: number
}

export interface SetAmountAction {
    type: TypeKey.SET_AMOUNT
    amount: number | null
}

export interface SetIntakeRecommendationAction {
    type: TypeKey.SET_INTAKE_RECOMMENDATION
    intakeRecommendation: IntakeRecommendationType | null
}

export interface SetTherapyDurationAction {
    type: TypeKey.SET_THERAPY_DURATION
    therapyDuration: any
}

export interface SetNoteAction {
    type: TypeKey.SET_NOTE
    note: string | null
}

export interface AddReminderTimeAction {
    type: TypeKey.ADD_REMINDER_TIME
    dateTimeQuantityUnit: DateTimeQuantityUnit
}

export interface UpdateReminderTimeAction {
    type: TypeKey.UPDATE_REMINDER_TIME
    dateTimeQuantityUnit: DateTimeQuantityUnit
    id: number
}

export interface RemoveReminderTimeAction {
    type: TypeKey.REMOVE_REMINDER_TIME
    id: number
}

export interface ConfirmFormValueAction {
    type: TypeKey.CONFIRM
    confirm: boolean
}

export interface ResetFormValueAction {
    type: TypeKey.RESET
}

export interface SetQRValuesAction {
    type: TypeKey.SET_QR_VALUES
    values: string
}

export type FormValuesActionTypes = 
    SetReminderTypeAction | 
    SetArticleAction | 
    SetWeekdaysAction |
    SetAmountAction | 
    SetIntakeRecommendationAction |
    SetTherapyDurationAction |
    SetNoteAction |
    SetDayIntervalAction |
    AddReminderTimeAction |
    UpdateReminderTimeAction | 
    RemoveReminderTimeAction |
    ResetFormValueAction | 
    ConfirmFormValueAction |
    SetQRValuesAction

export const setArticle = (article: MedicineArticle | string | undefined): SetArticleAction => ({
    type: TypeKey.SET_ARTICLE,
    article
})

export const setReminderType = (reminderType: ReminderType | undefined): SetReminderTypeAction => ({
    type: TypeKey.SET_REMINDER_TYPE,
    reminderType
})

export const setWeekdays = (weekdays: Weekday[]): SetWeekdaysAction => ({
    type: TypeKey.SET_WEEKDAYS,
    weekdays
})

export const setAmount = (amount: number | null): SetAmountAction => ({
    type: TypeKey.SET_AMOUNT,
    amount
})

export const setIntakeRecommendation = (intakeRecommendation: IntakeRecommendationType | null): SetIntakeRecommendationAction => ({
    type: TypeKey.SET_INTAKE_RECOMMENDATION,
    intakeRecommendation
})

export const setDayInterval = (dayInterval: number): SetDayIntervalAction => ({
    type: TypeKey.SET_DAY_INTERVAL,
    dayInterval
})

export const setNote = (note: string | null): SetNoteAction => ({
    type: TypeKey.SET_NOTE,
    note
})

export const addReminderTime = (dateTimeQuantityUnit: DateTimeQuantityUnit): AddReminderTimeAction => ({
    type: TypeKey.ADD_REMINDER_TIME,
    dateTimeQuantityUnit
})

export const setTherapyDuration = (therapyDuration: any): SetTherapyDurationAction => ({
    type: TypeKey.SET_THERAPY_DURATION,
    therapyDuration
})

export const updateReminderTime = (dateTimeQuantityUnit: DateTimeQuantityUnit, id: number): UpdateReminderTimeAction => ({
    type: TypeKey.UPDATE_REMINDER_TIME,
    dateTimeQuantityUnit,
    id
})

export const removeReminderTime = (id: number): RemoveReminderTimeAction => ({
    type: TypeKey.REMOVE_REMINDER_TIME,
    id
})

export const reset = (): ResetFormValueAction => ({
    type: TypeKey.RESET
})

export const confirmValues = (confirm: boolean): ConfirmFormValueAction => ({
    type: TypeKey.CONFIRM,
    confirm
})

export const setQRValues = (values: string): SetQRValuesAction => ({
    type: TypeKey.SET_QR_VALUES,
    values
})

export const FormValuesActions = {
    setReminderType,
    setArticle,
    setWeekdays,
    setAmount,
    setIntakeRecommendation,
    setTherapyDuration,
    setDayInterval,
    setNote,
    addReminderTime,
    updateReminderTime,
    removeReminderTime,
    reset,
    confirmValues,
    setQRValues
}