import { createMuiTheme, PaletteColorOptions } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { PaletteColor } from '@material-ui/core/styles/createPalette'

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        colors: {
            [name: string]: string
        },
        gradients: {
            [name: string]: string
        }
    }
    interface Palette {
        primaryOther: PaletteColor
    }
    interface PaletteOptions {
        primaryOther?: PaletteColorOptions
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        colors: {
            [name: string]: string
        },
        gradients: {
            [name: string]: string
        }
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface PaletteOptions {
        primaryOther?: PaletteColorOptions
    }
    interface Palette {
        primaryOther: PaletteColor
    }
}

const breakpoints = createBreakpoints({})

const colors = {
    purple: '#493288',
    purpleLight3: '#9680d0',
    purpleLight4: '#d4cbec',
    purpleLight5: '#ece9f7',
    purpleDark1: '#342461',
    purpleGrayDark2: '#27252d',
    lightBlue: '#61A9C2',
    blueLight2: '#abd1de',
    blueLight4: '#E2EFF4',
    blueDark2: '#357388',
    blueGrayDark1: '#6e7d81',
    white: '#ffffff'
}

const gradients = {
    half: `linear-gradient(to top, ${colors.lightBlue}, rgba(0, 167, 157, 0))`,
}

// Create a theme instance.
const theme = createMuiTheme({
    colors,
    gradients,
    palette: {
        text: {
            primary: colors.purpleGrayDark2
        },
        primary: {
            main: colors.purple,
            light: colors.purpleLight3,
            dark: colors.purpleDark1
        },
        secondary: {
            main: colors.lightBlue,
            light: colors.blueLight2,
            dark: colors.blueDark2
        },
        error: {
            main: '#ee3333',
        },
        background: {
            default: colors.white,
        },
    },
    typography: {
        fontFamily: [
            '"Nunito"',
            '"Segoe UI"',
            '-apple-system',
            'BlinkMacSystemFont',
            'sans-serif'
        ].join(','),
        fontSize: 16,
        h1: {
            fontSize: '36px',
            fontWeight: 'bold',
            lineHeight: 1.2,
            color: colors.purple
        },
        h2: {
            fontSize: '26px',
            fontWeight: 'bold',
            lineHeight: 1.23,
            color: colors.purple // colors.purpleGrayDark2
        },
        h3: {
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: 1.2,
        },
        h4: {
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: 1.2,
        },
        h5: {
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: 1.2,
            color: colors.purple
        },
        h6: {
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: 1.2,
            color: colors.purpleLight3
        },
        body1: {
            lineHeight: 1.5,
            fontSize: '16px',
            fontWeight: 500,
            color: colors.purpleLight3
        },
        body2: {
            lineHeight: 1.5,
            fontSize: '16px',
            fontWeight: 500,
            color: colors.purpleLight5
        },
        subtitle1: {
            fontSize: '13px',
            color: colors.blueGrayDark1,
            textAlign: 'center',
            fontWeight: 400
        },

    },
    overrides: {
        MuiChip: {
            outlined: {
                padding: '4px'
            }
        },
        MuiCard: {
            root: {
                cursor: "pointer",
                boxShadow: '0px 5px 22px -1px rgba(96, 167, 195, 0.52)',
                display: 'flex',
                width: '400px',
                [breakpoints.down('xs')]: {
                    width: '300px',
                }
            }
        },
        MuiCardContent: {
            root: {
                display: 'flex',
                flexDirection: 'column',
            }
        },
        MuiInputAdornment: {
            root: {
                color: colors.purpleLight3
            }
        },
        MuiFilledInput: {
            root: {
                boxShadow: '0px 5px 22px -1px rgba(96, 167, 195, 0.52)',
                backgroundColor: colors.white,
                '&:hover': {
                    backgroundColor: colors.white,
                },
                '&.Mui-focused': {
                    backgroundColor: colors.white,
                },
            }
        },
        MuiInputLabel: {
            filled: {
                color: colors.purpleLight3,
                '&.Mui-focused': {
                    color: colors.purpleLight3,
                },
            },
            shrink: {
                transform: 'translate(12px, 10px) scale(0.75)',
                color: colors.purpleLight3
            }
        },
        MuiInput: {
            formControl: {
                padding: '12px',
            }
        },
        MuiInputBase: {
            input: {
                color: colors.purple,
                fontSize: '20px',
                fontWeight: 'bold',
            }
        },
        MuiFormLabel: {
            root: {
                color: colors.purpleLight3,
                '&.Mui-focused': {
                    color: colors.purpleLight3,
                },
            }
        },
        MuiFormControl: {
            root: {
                width: '400px',
                [breakpoints.down('xs')]: {
                    width: '300px',
                }
            }
        },
        MuiTextField: {
            root: {
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: colors.blueLight4,
                height: '1pt'
            }
        },
        MuiCheckbox: {
            root: {
                color: colors.purpleLight4
            }
        },
        MuiButton: {
            root: {
                height: '50px',
                textTransform: 'none',
                width: '400px',
                [breakpoints.down('xs')]: {
                    width: '300px',
                }
            },
            contained: {
                borderRadius: '16px',
                boxShadow: '0px 5px 22px -1px rgba(73, 50, 136, 0.5)',
                '&.Mui-disabled': {
                    color: colors.white,
                    backgroundColor: colors.blueGrayDark1,
                    boxShadow: '0px 5px 22px -1px rgba(73, 50, 136, 0.5)',
                }
            },
        },
        MuiSelect: {
            icon: {
                backgroundColor: colors.purpleLight5,
                color: colors.purpleLight3,
                borderRadius: '7px',
                marginRight: '6px'
            }
        },
        MuiMenuItem: {
            root: {
                color: colors.purple,
                fontWeight: 'bold',
                fontSize: '16px',
            }
        },
        MuiMenu: {
            paper: {
                maxHeight: '40%'
            }
        },
        MuiDialogContent: {
            root: {
                '& > .MuiPickersBasePicker': {
                    '&-container': {
                        alignItems: 'center',
                        overflow: 'hidden',
                        '& > .MuiPickersToolbar-toolbar': {
                            '& > .MuiPickersTimePickerToolbar': {
                                '&-hourMinuteLabel': {
                                    alignItems: 'center',
                                    '& > .MuiPickersToolbarButton-toolbarBtn': {
                                        width: '220px'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiDialogActions: {
            spacing: {
                '& > .MuiButtonBase-root:not(:first-child)': {
                    backgroundColor: colors.purple,
                    color: colors.purpleLight5,
                    borderRadius: '10px'
                }
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '450px'
            }
        },
        MuiCssBaseline: {
            '@global': {
                body: {
                    color: colors.purpleGrayDark2,
                    fontFamily: 'Nunito'
                }
            }
        },
    }
})

export default theme