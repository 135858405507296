import { ReminderType, FormType } from '../state/definition'

export interface FormStep {
    formId: string
    options?: { [option: string]: any}
    backStep?: string
}

export interface FormWizard {
    steps: Array<FormStep>
}

export class ReminderFormWizard implements FormWizard {

    static instance: ReminderFormWizard

    static getInstance(): ReminderFormWizard {
        if (!this.instance) {
            this.instance = new ReminderFormWizard()
        }
        return this.instance
    }

    steps: Array<FormStep> = [
        { formId: FormType.SEARCH_FORM }, 
        { formId: FormType.REMINDER_SELECTION_FORM }
    ]

    reminderFormWizards: { [key: string]: FormWizard } = {
        [ReminderType.DAILY]: {
            steps: [
                { formId: FormType.DATE_TIME_QUANTITY_UNIT_FORM },
                { formId: FormType.TIME_REMINDER_OVERVIEW_FORM, backStep: FormType.REMINDER_SELECTION_FORM },
                { formId: FormType.THERAPY_DURATION_FORM },
                { formId: FormType.AMOUNT_FORM },
                { formId: FormType.INTAKE_RECOMMENDATION_FORM },
                { formId: FormType.OVERVIEW },
                { formId: FormType.QR_CODE }
            ]
        },
        [ReminderType.WEEKDAY]: {
            steps: [
                { formId: FormType.WEEKDAY_INTERVAL_FORM },
                { formId: FormType.DATE_TIME_QUANTITY_UNIT_FORM },
                { formId: FormType.TIME_REMINDER_OVERVIEW_FORM, backStep: FormType.WEEKDAY_INTERVAL_FORM },
                { formId: FormType.THERAPY_DURATION_FORM },
                { formId: FormType.AMOUNT_FORM },
                { formId: FormType.INTAKE_RECOMMENDATION_FORM },
                { formId: FormType.OVERVIEW },
                { formId: FormType.QR_CODE }
            ]
        },
        [ReminderType.DAILY_INTERVAL]: {
            steps: [
                { formId: FormType.DAY_INTERVAL_FORM },
                { formId: FormType.DATE_TIME_QUANTITY_UNIT_FORM },
                { formId: FormType.TIME_REMINDER_OVERVIEW_FORM, backStep: FormType.DAY_INTERVAL_FORM },
                { formId: FormType.THERAPY_DURATION_FORM },
                { formId: FormType.AMOUNT_FORM },
                { formId: FormType.INTAKE_RECOMMENDATION_FORM },
                { formId: FormType.OVERVIEW },
                { formId: FormType.QR_CODE }
            ]
        },
        [ReminderType.HOUR_INTERVAL]: {
            steps: [
                { formId: FormType.HOUR_INTERVAL_FORM },
                { formId: FormType.THERAPY_DURATION_FORM },
                { formId: FormType.AMOUNT_FORM },
                { formId: FormType.INTAKE_RECOMMENDATION_FORM },
                { formId: FormType.OVERVIEW },
                { formId: FormType.QR_CODE }
            ]
        },
        [ReminderType.ONCE]: {
            steps: [
                { formId: FormType.DATE_TIME_QUANTITY_UNIT_FORM },
                { formId: FormType.AMOUNT_FORM },
                { formId: FormType.INTAKE_RECOMMENDATION_FORM },
                { formId: FormType.OVERVIEW },
                { formId: FormType.QR_CODE }
            ]
        }
    }

    activeMainFormStepIndex?: number = 0
    activeReminderType?: ReminderType
    activeReminderFormStepIndex?: number = 0

    getCurrentStep(): FormStep | undefined {
        if (this.activeReminderType) {
            const activeReminderFormStepIndex = this.activeReminderFormStepIndex !== undefined ? this.activeReminderFormStepIndex : 0
            return this.reminderFormWizards[this.activeReminderType].steps[activeReminderFormStepIndex]
        }    
        return this.steps[this.activeMainFormStepIndex || 0]
    }

    getNextStep(): FormStep | undefined {
        if (this.activateNextStep()) {
            return this.getCurrentStep()
        } 
        return
    }

    activateBackStep(): boolean {
        if (this.activeReminderType) {
            const previousActiveReminderFormStepIndex = this.activeReminderFormStepIndex !== undefined ? this.activeReminderFormStepIndex - 1 : 0
            if (previousActiveReminderFormStepIndex >= 0) {
                this.activeReminderFormStepIndex = previousActiveReminderFormStepIndex
                return true
            }
            return false
        }

        const previousActiveMainFormStepIndex = this.activeMainFormStepIndex !== undefined ? this.activeMainFormStepIndex - 1 : 0 
        if (previousActiveMainFormStepIndex >= 0) {
            this.activeMainFormStepIndex = previousActiveMainFormStepIndex
            return true
        } 
        return false
    }

    getPreviousStepNumber(): number {
        const currFormId = this.getCurrentStep().formId
        const reminderType = this.activeReminderType

        switch(reminderType) {
            case ReminderType.DAILY: {
                switch(currFormId) {
                    case FormType.TIME_REMINDER_OVERVIEW_FORM: {
                        this.activeReminderFormStepIndex = 0
                        this.activeReminderType = undefined
                        return -2
                    }  
                    case FormType.INTAKE_RECOMMENDATION_FORM: {
                        this.activeReminderFormStepIndex = 2
                        return -2
                    } 
                    default: 
                        return 0
                } 
            } 
            case ReminderType.WEEKDAY:
            case ReminderType.DAILY_INTERVAL: {
                switch(currFormId) {
                    case FormType.TIME_REMINDER_OVERVIEW_FORM: {
                        this.activeReminderFormStepIndex = 0
                        return -2
                    } 
                    case FormType.INTAKE_RECOMMENDATION_FORM: {
                        this.activeReminderFormStepIndex = 3
                        return -2
                    }  
                    default: 
                        return 0
                } 
            } 
        } 
    }

    getNextStepNumber(): number {
        const currFormId = this.getCurrentStep().formId
        const reminderType = this.activeReminderType

        switch(reminderType) {
            case ReminderType.DAILY: {
                switch(currFormId) {
                    case FormType.THERAPY_DURATION_FORM: {
                        this.activeReminderFormStepIndex = 4
                        return 2
                    }  
                    default: 
                        return 0
                }   
            }
            case ReminderType.WEEKDAY: {
                switch(currFormId) {
                    case FormType.WEEKDAY_INTERVAL_FORM: {
                        this.activeReminderFormStepIndex = 2
                        return 2
                    } 
                    case FormType.THERAPY_DURATION_FORM: {
                        this.activeReminderFormStepIndex = 5
                        return 2
                    }  
                    default: 
                        return 0
                }   
            }
            case ReminderType.DAILY_INTERVAL: {
                switch(currFormId) {
                    case FormType.DAY_INTERVAL_FORM: {
                        this.activeReminderFormStepIndex = 2
                        return 2
                    }  
                    case FormType.THERAPY_DURATION_FORM: {
                        this.activeReminderFormStepIndex = 5
                        return 2
                    } 
                    default: 
                        return 0
                } 
            } 
        } 
    }

    activateNextStep(): boolean {
        if (this.activeReminderType) {
            const nextActiveReminderFormStepIndex = this.activeReminderFormStepIndex !== undefined ? this.activeReminderFormStepIndex + 1 : 0
            if (nextActiveReminderFormStepIndex < this.reminderFormWizards[this.activeReminderType].steps.length) {
                this.activeReminderFormStepIndex = nextActiveReminderFormStepIndex
                return true
            }
            return false
        }
        const nextActiveMainFormStepIndex = this.activeMainFormStepIndex !== undefined ? this.activeMainFormStepIndex + 1 : 0
        if (nextActiveMainFormStepIndex < this.steps.length) {
            this.activeMainFormStepIndex = nextActiveMainFormStepIndex
            return true
        }
        return false
    }    
}

