import React, { FunctionComponent } from 'react'
import { Theme, Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FormattedMessage } from 'react-intl'
import formsCollection from '../../../shared/formsCollection'
import { Form } from '../../../shared/formsCollection'
import { useParams } from 'react-router-dom'

const useStyles =  makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    title: theme.typography.h1
}))

interface FormProps {
    path: string
}

const FormPage: FunctionComponent<FormProps> = (props) => {
    const { path } = props
    const classes = useStyles()
    const { formId } = useParams()

    let form: Form
    if (path) {
        form = formsCollection[path]
    } else {
        form = formsCollection[formId]
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={3}
            >
                <Grid item sm={8}>
                    {form.title && (
                        <Box m={2} textAlign="center">
                            <Typography className={classes.title} >
                                {
                                    (typeof form.title === 'function' && <form.title />) ||
                                    (typeof form.title === 'string' && <FormattedMessage id={form.title} />)
                                }
                            </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item sm={8}>
                    {form.formContent && (
                        (typeof form.formContent === 'function' && <form.formContent />) ||
                        (typeof form.formContent === 'string' && <FormattedMessage id={form.formContent} />)
                    )}
                </Grid>
                <Grid item sm={8}>
                    {form.controlBar && (
                        (typeof form.controlBar === 'function' && <form.controlBar />) ||
                        (typeof form.controlBar === 'string' && <FormattedMessage id={form.controlBar} />)
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default FormPage