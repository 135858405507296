/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'
import { Theme, Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FormType, AppState, FormValuesState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import QRCode from 'qrcode.react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles =  makeStyles((theme: Theme) => ({
    mediaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    media: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px',
        height: '150px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
}))

const qrCodeForm: Form = {
    formType: FormType.QR_CODE,
    title: () => <FormattedMessage id='qr.code.title' />,
    formContent: () => {
        const classes = useStyles() 
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const qrCodeValues: string = formValuesState.qrCodeValues

        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={3}
            >
                {qrCodeValues && 
                    <Grid item>
                        <QRCode
                            value={qrCodeValues}
                            size={330}
                            renderAs="svg"
                            includeMargin={true}
                        />
                    </Grid>
                }
                <Grid item>
                    <Typography variant="h5" align="center">
                        <FormattedMessage id='qr.code.desc' />
                    </Typography>
                </Grid>
                <Grid 
                    item
                    container 
                    justify="center"
                    alignItems="center"
                    direction="row"
                >
                    <Grid item xs className={classes.mediaWrapper}>
                        <Box className={classes.media}>
                            <a
                                href={`https://tom-medications.onelink.me/81IO/b3ad2fe0`} 
                                target="blank"
                            >
                                <img 
                                    src={`https://www.tommedications.com/wp-content/uploads/2020/07/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.png`} 
                                    alt='app-store'
                                />
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs className={classes.mediaWrapper}>
                        <Box className={classes.media}>
                            <a
                                href={`https://tom-medications.onelink.me/81IO/b3ad2fe0`} 
                                target="blank"
                            >
                                <img 
                                    src={`https://www.tommedications.com/wp-content/uploads/2020/07/google-play-badge.png`}
                                    alt='google-play'
                                />
                            </a>
                        </Box>                        
                    </Grid>
                </Grid>    
            </Grid>  
        )
    }
}

export default qrCodeForm