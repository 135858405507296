import autoBind from 'auto-bind'

abstract class BaseSaga {
    constructor() {
        // automatically bind class methods to this
        autoBind(this)
    }

    abstract entrySaga(): IterableIterator<any>
}

export { BaseSaga }