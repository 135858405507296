import { AppState, UnitState, MedicineUnit } from '../definition'
import { BaseSaga } from './baseSaga'
import { put, all, takeEvery, call } from 'redux-saga/effects'
import { TypeKey as FormValuesTypeKey } from '../actions/formValues'
import NetworkClient from '../../api/networkClient'
import { UnitActions } from '../actions/unit'

export const getUnitState = (state: AppState): UnitState => state.unit

class UnitSaga extends BaseSaga {
    public *entrySaga(): IterableIterator<any> {
        yield all([
            takeEvery([FormValuesTypeKey.SET_ARTICLE], this.onFetchUnitList),
        ])
    }

    private *onFetchUnitList() { 
        try {
            const response: Array<MedicineUnit> = yield call(NetworkClient.articleUnit.getAll)
            yield put(UnitActions.setUnitList(response))
        } catch(e) {
            console.log(e)
        }      
    }
}

export default new UnitSaga().entrySaga