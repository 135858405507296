const envConfig = {
    production: {
        ENV: 'production',
        API_ENDPOINT_BASE: 'https://tomv2.bitsfabrik.com/api/'
    },
    development: {
        ENV: 'development',
        API_ENDPOINT_BASE: 'https://tomv2.bitsfabrik.com/api/'
    },
    stage: {
        ENV: 'stage',
        API_ENDPOINT_BASE: 'https://tomv2.bitsfabrik.com/api/',
    }
}

const currentEnv = process.env.NODE_CONFIG_ENV || process.env.NODE_ENV || 'development'
module.exports = envConfig[currentEnv]