/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { FormType, FormValuesState, AppState } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import { FormattedMessage } from 'react-intl'
import { Button, Typography, Theme, makeStyles, Grid, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useLocation } from 'react-router-dom'

const useStyles =  makeStyles((theme: Theme) => ({ 
    formControl: {
        '.svg': {
            margin: 5, 
            fill: theme.colors.lightBlue
        }
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    },
    cardIcon: {
        backgroundColor: theme.colors.purpleLight5,
        borderRadius: '7px',
        marginRight: '12px'
    },
}))

const dayIntervalForm: Form = {
    formType: FormType.DAY_INTERVAL_FORM,
    title: () => <FormattedMessage id='create.daysReminder.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)

        let daysIntervalModel = []
        for (let interval = 2; interval <= 30; interval += 1) {
            daysIntervalModel.push(interval) 
        }

        let defaultDayInterval = 2
        if (formValuesState && formValuesState.dayInteval) {
            defaultDayInterval = formValuesState.dayInteval
        }

        const [dayInterval, setDayInterval] = useState<number | undefined>(defaultDayInterval)

        const handleDayIntervalSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            setDayInterval(Number(event.target.value))
        }

        const handleForwardAction = () => {
            if (formValuesState && (!formValuesState.dayInteval || formValuesState.dayInteval !== dayInterval)) {
                dispatch(FormValuesActions.setDayInterval(dayInterval))
            }  
            if (location.state && location.state.fromOverview) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >                
                <Grid item>
                    <FormControl 
                        variant="filled" 
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="select-day-interval-label">
                            <FormattedMessage id={`create.hoursReminder.button.hours.title`} /> 
                        </InputLabel>
                        <Select
                            labelId="select-day-interval-label"
                            id="select-day-interval"
                            fullWidth
                            disableUnderline
                            value={dayInterval || ''}
                            onChange={handleDayIntervalSelect}
                        >
                            {daysIntervalModel.length > 0 && daysIntervalModel.map((inteval, i) => (
                                <MenuItem 
                                    key={i} 
                                    defaultValue=" "
                                    value={inteval}
                                >
                                    {inteval}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!dayInterval}
                        onClick = {handleForwardAction}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.daysReminder.button.next' />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default dayIntervalForm