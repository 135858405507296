/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { FormType, AppState, UnitState, FormValuesState, MedicineArticle } from '../../../../state/definition'
import { Form } from '../../../../shared/formsCollection'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography, Button, makeStyles, Theme, Grid, FormControl, InputLabel, FilledInput, CardContent, Card } from '@material-ui/core'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import { FormWizardActions, FormValuesActions } from '../../../../state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { goBack } from 'connected-react-router'

const useStyles =  makeStyles((theme: Theme) => ({ 
    formControl: {
        '.svg': {
            margin: 5, 
            fill: theme.colors.lightBlue
        },
        '& input[type=number]': {
            '&::-webkit-inner-spin-button': {
                opacity: 1,
                height: '35px',
                width: '35px',
                marginRight: '5px',
                background: theme.colors.purpleLight5
            }
        }
    },
    buttonIcon: {
        margin: 5, 
        fill: theme.colors.lightBlue
    }
}))

const amountForm: Form = {
    formType: FormType.AMOUNT_FORM,
    title: () => <FormattedMessage id='create.stock.label.title' />,
    formContent: () => {
        const classes = useStyles()
        const dispatch = useDispatch()
        const location = useLocation()
        const intl = useIntl()
        const formValuesState: FormValuesState = useSelector((state: AppState) => state.formValues)
        const unitState: UnitState = useSelector((state: AppState) => state.unit)

        const locale = intl.locale.split('-')[0]
        let defaultAmount = undefined

        const article: MedicineArticle = formValuesState.article as MedicineArticle
        if (article instanceof Object && article.quantity) {
            defaultAmount = article.quantity
        } 

        const selectedUnit = formValuesState && formValuesState.dateTimeQuantityUnits.length > 0 && formValuesState.dateTimeQuantityUnits[0].unit
        if ((formValuesState && formValuesState.amount) || (location.state && location.state.fromReminderOverview)) {
            defaultAmount = formValuesState.amount
        }
        
        const [amount, setAmount] = useState<number | undefined>(defaultAmount)

        const unitMinValue = (selectedUnit && unitState && unitState.unitList.find(item => item.unitType === selectedUnit)?.minValue) || 1
        const unitMaxValue = (selectedUnit && unitState && unitState.unitList.find(item => item.unitType === selectedUnit)?.maxValue) || 999
        const unitStep = (selectedUnit && unitState && unitState.unitList.find(item => item.unitType === selectedUnit)?.step) || 1
        const selectedUnitName = (selectedUnit && unitState && unitState.unitList.find(item => item.unitType === selectedUnit)?.name) || 
            (unitState.unitList && unitState.unitList.length > 0 && unitState.unitList[0].name)

        const handleAmountSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault()
            if (Number(event.target.value) < unitMinValue ) {
                setAmount(unitMinValue)
            } else if (Number(event.target.value) > unitMaxValue ) {
                setAmount(unitMaxValue)
            } else {
                setAmount(Number(event.target.value))
            }
        }

        const handleForwardAction = () => { 
            if (formValuesState && (!formValuesState.amount || formValuesState.amount !== amount)) {
                dispatch(FormValuesActions.setAmount(amount)) 
            }
            
            if (location.state && (location.state.fromReminderOverview || location.state.fromOverview)) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        const handleSkipAction = () => {
            dispatch(FormValuesActions.setAmount(null))  
            
            if (location.state && (location.state.fromReminderOverview || location.state.fromOverview)) {
                dispatch(goBack())
            } else {
                dispatch(FormWizardActions.routeForward())
            } 
        }

        return (
            <Grid 
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >       
                <Grid item>
                    <FormControl 
                        variant="filled" 
                        className={classes.formControl}
                    >
                        <InputLabel htmlFor="select-amount-label">
                            <FormattedMessage id={`create.stock.label.hint.stockActive`} /> 
                        </InputLabel>
                        <FilledInput
                            id="select-amount-label"
                            name="select-amount"
                            fullWidth
                            disableUnderline
                            type="number"
                            inputProps={{
                                min: unitMinValue,
                                max: unitMaxValue,
                                step: unitStep
                            }} 
                            value={amount || ''}
                            onChange={handleAmountSelect}
                        />
                    </FormControl> 
                </Grid>
                <Grid item>
                    <Card style={{ cursor: 'initial' }}>                                   
                        <CardContent>   
                            <Typography variant="h6">
                                <FormattedMessage id='create.stock.label.hint.unit' />
                            </Typography>                                  
                            <Typography variant="h5">
                                {selectedUnitName && selectedUnitName[`${locale}`]}
                            </Typography>              
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item style={{ margin: '20px 0px 0px 0px' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!amount}
                        onClick = {handleForwardAction}
                    >
                        <ArrowRightAltRoundedIcon className={classes.buttonIcon} />
                        <Typography variant="body2">
                            <FormattedMessage id='create.stock.button.next' />
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick = {handleSkipAction}
                    >
                        <Typography variant="h5">
                            <FormattedMessage id='general.label.skip' />
                        </Typography>                   
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default amountForm