import { UnitState } from "../definition"
import { TypeKey } from "../actions/unit"
import { UnitActionTypes } from '../actions/unit'

const unit = (state: UnitState | null = null, action: UnitActionTypes) => {    
    switch (action.type) {
        case TypeKey.SET_UNIT_LIST: {
            return { ...state, unitList: action.result }
        }         
        default:
            return state
    }
}

export default unit