import { SearchState } from "../definition"
import { TypeKey, SearchActionTypes } from "../actions/search"
import defaultState from '../defaultState'

const search = (state: SearchState | null = null, action: SearchActionTypes) => {    
    switch (action.type) {
        case TypeKey.SET_QUERY: {
            return { ...state, query: action.query }
        } 
        case TypeKey.SET_RESULT: {
            return action.result ? 
                { ...state, 
                    result: {
                        ...state.result,
                        totalElements: action.result.totalElements,
                        currentPage: action.result.currentPage,
                        totalPages: action.result.totalPages,
                        articles: [
                            ...state.result.articles, 
                            ...action.result.articles 
                        ]
                    }
                } : 
                { ...state, result: defaultState.search.result }
        }        
        default:
            return state
    }
}

export default search